import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { FC } from 'react';

type Props = {
  open: boolean;
};

const Loading: FC<Props> = ({ open }) => {
  return (
    <Backdrop sx={{ color: '#1D2532', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
