import React from 'react';
import {
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Typography,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '224px',
      minWidth: '208px',
      borderRadius: 8,
      marginTop: '8px',
      filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.02)) drop-shadow(0px 12px 25px rgba(0, 0, 0, 0.08))',
      boxShadow: 'none',
      overflow: 'hidden',
      transform: 'translateX(-11px)',
      '& .MuiMenu-list': {
        paddingTop: '0',
        paddingBottom: '0',
        borderRadius: 4,
      },
    },
  },
};

const StyledSelect = styled(Select)({
  padding: '0',
  [`& fieldset`]: {
    border: 'none',
  },
  '& .MuiSelect-select': {
    padding: '0',
  },
});

const StyledMenuItem = styled(MenuItem)({
  mb: '4px',
  padding: '16px 24px',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '1.71',
  color: '#313D53',
  borderBottom: '1px solid #EAECEE',
  '&:last-child': {
    borderBottomColor: 'transparent',
  },
  '&.Mui-selected': {
    backgroundColor: '#EAECEE',
  },
});

type LabelValue = {
  label: string;
  value: string;
};

type CustomDropdownProps = {
  displayValueOff?: boolean;
  helperText?: string;
  label?: string;
  options: LabelValue[] | string[];
  value: string | string[];
  handleChange: (event: string) => void;
};

const PigogoClearDropdown: React.FC<CustomDropdownProps & FormControlProps> = ({
  value,
  options = [],
  displayValueOff,
  helperText,
  label,
  handleChange,
  ...rest
}) => {
  const onChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;
    handleChange(value);
  };

  return (
    <FormControl variant="outlined" {...rest}>
      <StyledSelect
        displayEmpty
        value={value}
        renderValue={(selected: any) => (
          <Box py={0.5} display="flex" alignItems="center" justifyContent="center">
            {!displayValueOff && (
              <Typography display="inline" variant="subtitle1SmallR" color="#313D53">
                {label && `${label}:`}
                <Typography display="inline" variant="captionSB" fontSize="14px" sx={{ paddingLeft: '5px' }}>
                  {typeof options[0] === 'string'
                    ? selected
                    : (options as LabelValue[]).find((option) => option.value === selected)?.label ?? ''}
                </Typography>
              </Typography>
            )}
          </Box>
        )}
        MenuProps={MenuProps}
        onChange={onChange}
        IconComponent={ExpandMore}
      >
        {options.map((option) => {
          const label = typeof option === 'string' ? option : option.label;
          const value = typeof option === 'string' ? option : option.value;
          return (
            <StyledMenuItem key={value} value={value}>
              {label}
            </StyledMenuItem>
          );
        })}
        {helperText}
      </StyledSelect>
      {!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default PigogoClearDropdown;
