import React, { FC, useState } from 'react';
import { Box, Skeleton, styled, Typography, useMediaQuery } from '@mui/material';
import { Fire, HeartBig, HeartBlack, HeartBlackSmall, HeartSmall, TagPinkSmall, TicketPinkSmall } from '../assets';
import PigogoChipSmall from './PigogoChipSmall';
import PigogoDiscount from './PigogoDiscount';
import RichTooltip from './RichTooltip';
import { theme } from '../index';
import { getPlatforms } from '@ionic/react';

const StyleBox = styled(Box)(({ theme }) => ({
  height: '264px',
  // width: '167.5px',
  width: '100%',
  cursor: 'pointer',
  borderRadius: '16px',
  display: 'inline-flex',
  boxSizing: 'border-box',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '16px',
  background: theme.palette.blue.contrastText,
  border: `2px solid ${theme.palette.orange.main}`,
  transition: 'border-color .35s ease',

  [theme.breakpoints.up('md')]: {
    // width: 'calc(33.33% - 11px)',
    height: '352px',
    borderRadius: '24px',
    padding: '24px',
    border: `4px solid ${theme.palette.orange.main}`,
  },
  [theme.breakpoints.up('lg')]: {
    // width: 'calc(25% - 12px)',
  },
  [theme.breakpoints.up('xl')]: {
    height: '352px',
    borderRadius: '24px',
    padding: '24px',
    border: `4px solid ${theme.palette.orange.main}`,
  },
  '&:hover': {
    borderColor: `${theme.palette.orange.dark}`,
  },
  '&:focus': {
    outline: 0,
  },
  '&:focus-visible': {
    outline: `2px dashed ${theme.palette.orange.dark}`,
    outlineOffset: '2px',
  },
}));

const StyleImgBox = styled('img')(({ theme }) => ({
  borderRadius: 8,
  maxWidth: 'unset',
  objectFit: 'cover',

  width: '96px',
  height: '48px',
  [theme.breakpoints.up('md')]: {
    height: '64px',
    width: '128px',
  },
}));

interface Props {
  shop?: any;
  loading?: boolean;
  logout?: boolean;
  handleClick?: (e?: Event) => void;
  setFavourite?: () => void;
}

const PigogoProductCard: FC<Props> = ({ loading, logout, shop, handleClick, setFavourite }) => {
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const platforms = getPlatforms();

  const isDesktop = platforms.includes('desktop');
  const isCapacitor = platforms.includes('capacitor');
  const isMobileWeb = platforms.includes('mobileweb');

  const isDesktopApp = (isDesktop || isMobileWeb) && !isCapacitor;

  const [open, setOpen] = useState(false);

  const [favTooltipState, setFavTooltipState] = useState<boolean>(!!shop?.user_favourite);

  const mouseOver = () => {
    setTimeout(() => {
      setOpen(true);
    }, 200);
  };

  const mouseOut = () => {
    setOpen(false);

    setTimeout(() => {
      setFavTooltipState(!!shop?.user_favourite);
    }, 300);
  };

  const changeFavourite = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (setFavourite) {
      setFavourite();
    }
  };

  const onClickCard = (event: any) => {
    if (handleClick) {
      handleClick(event);
    }
    event.stopPropagation();
  };

  const getFavouriteIcon = () => {
    if (shop?.user_favourite) {
      if (matches) {
        return <HeartBlackSmall />;
      } else {
        return <HeartBlack />;
      }
    } else {
      if (matches) {
        return <HeartSmall />;
      } else {
        return <HeartBig />;
      }
    }
  };

  return (
    <StyleBox onClick={(e) => onClickCard(e)} onMouseOut={mouseOut}>
      <Box display={'flex'} flexDirection={'column'} gap={matches ? '16px' : '20px'}>
        <Box display={'flex'} justifyContent={'space-between'} gap={matches ? '8px' : '20px'}>
          {loading ? (
            <Skeleton
              variant="rectangular"
              animation="wave"
              height="100%"
              style={{ background: '#EAECEE', borderRadius: 32, width: '56px', height: '56px' }}
            />
          ) : (
            <StyleImgBox alt={'logo'} src={shop?.image} />
          )}
          <Box
            onMouseOver={mouseOver}
            onClick={(e) => changeFavourite(e)}
            sx={{ cursor: 'pointer', position: 'relative', zIndex: 3 }}
          >
            {loading && (
              <Skeleton
                height="30%"
                width="100%"
                animation="wave"
                variant="rectangular"
                sx={{ background: '#EAECEE', borderRadius: 16 }}
              />
            )}
            {!loading && (
              <RichTooltip
                open={false}
                color="dark"
                placement="bottom"
                paperProps={{ style: { height: '72px' } }}
                content={
                  <Typography color={'white'} variant={'caption'} component="p">
                    {logout
                      ? 'Κάνε εγγραφή για να μπορείς να προσθέτεις στα αγαπημένα'
                      : favTooltipState
                      ? 'Αφαίρεση από τα αγαπημένα σου καταστήματα'
                      : 'Προσθήκη στα αγαπημένα σου καταστήματα'}
                  </Typography>
                }
                onClose={() => setOpen(false)}
              >
                <Box
                  sx={{
                    padding: 1,
                    lineHeight: 1,
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: matches ? '40px' : '48px',
                    height: matches ? '40px' : '48px',
                    marginTop: matches ? '-8px' : '0',
                  }}
                >
                  <div
                    title={
                      logout
                        ? 'Κάνε εγγραφή για να μπορείς να προσθέτεις στα αγαπημένα'
                        : shop?.user_favourite
                        ? 'Αφαίρεση από τα αγαπημένα σου καταστήματα'
                        : 'Προσθήκη στα αγαπημένα σου καταστήματα'
                    }
                  >
                    {getFavouriteIcon()}
                  </div>
                </Box>
              </RichTooltip>
            )}
          </Box>
        </Box>

        <Box>
          <Typography
            textAlign={'start'}
            variant={matches ? 'subtitle1SmallL' : 'largeXL'}
            component="h3"
            color={'#313D53'}
            sx={{ textDecoration: 'none' }}
          >
            {loading ? (
              <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} />
            ) : (
              shop?.name
            )}
          </Typography>
        </Box>
      </Box>

      <Box display={'flex'} flexDirection={'column'} gap={'24px'}>
        <Box display={'flex'} gap={'8px'}>
          {shop?.savings.offer_count ? (
            <Box sx={{ pointerEvents: 'none' }}>
              <PigogoChipSmall
                canShrink
                color={'orange'}
                startIcon={<TagPinkSmall />}
                label={`${shop.savings.offer_count}`}
              />
            </Box>
          ) : null}
          {shop?.savings.coupon_count ? (
            <Box sx={{ pointerEvents: 'none' }}>
              <PigogoChipSmall
                canShrink
                color={'orange'}
                label={`${shop.savings.coupon_count}`}
                startIcon={<TicketPinkSmall />}
              />
            </Box>
          ) : null}
          {shop?.promos.has_double && !loading && (
            <Box sx={{ pointerEvents: 'none' }}>
              <PigogoChipSmall canShrink color={'primary'} label={`x2`} startIcon={<Fire />} />
            </Box>
          )}
          {loading && (
            <Skeleton
              animation="wave"
              width={'100%'}
              height={'55px'}
              sx={{ background: '#EAECEE', borderRadius: 16 }}
            />
          )}
        </Box>

        <Box display={'flex'} alignItems={'center'} textAlign={'left'} justifyContent={'space-between'} gap={1}>
          {loading && (
            <Skeleton
              animation="wave"
              width={'100%'}
              height={'40px'}
              sx={{ background: '#EAECEE', borderRadius: 16 }}
            />
          )}

          {!loading && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              flexWrap={smDown ? 'wrap' : 'nowrap'}
              gap={smDown ? '4px' : 1}
            >
              {shop?.strikedCashback && (
                <Box>
                  <PigogoDiscount discount={`${shop.strikedCashback}${shop.strikedCurrency}`} small />
                </Box>
              )}
              {shop?.cashback && !matches && (
                <Box>
                  <Typography color={'secondary'} variant={'h6'} component="p" sx={{ textDecoration: 'none' }}>
                    {shop.cashback_categories_count > 1 ? 'έως' : ''} {`${shop.cashback}${shop.currency}`} επιστροφή
                  </Typography>
                </Box>
              )}
              {shop?.cashback && matches && (
                <Box display={'flex'} gap={'2px'} sx={{ whiteSpace: 'nowrap' }}>
                  {shop.cashback_categories_count > 1 ? (
                    <Box>
                      <Typography
                        color={'secondary'}
                        variant={'captionSB'}
                        component="p"
                        sx={{ textDecoration: 'none' }}
                      >
                        {shop.cashback_categories_count > 1 ? 'έως' : ''}
                      </Typography>
                    </Box>
                  ) : null}

                  <Box>
                    <Typography color={'secondary'} variant={'captionSB'} component="p" sx={{ textDecoration: 'none' }}>
                      {`${shop.cashback}${shop.currency}`} επιστροφή
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </StyleBox>
  );
};

export default PigogoProductCard;
