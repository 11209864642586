import { useDispatch } from 'react-redux';
import Pagination from '../Stores/Pagination';
import { Payment } from '../../models/Payment';
import PaymentsFilterModal from './PaymentsFilterModal';
import { Close, InfoOutlined } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { setSnackBar } from '../../redux/slices/layoutSlice';
import PaymentsTableMobile from './Payments/PaymentsTableMobile';
import PaymentsTableDesktop from './Payments/PaymentsTableDesktop';
import { PaymentSort } from '../../redux/api/types/enum/PaymentSort';
import { ReactComponent as Info } from '../../assets/svgs/infoEmail.svg';
import { PigogoButton, PigogoSmallButton, RichTooltip, theme } from 'components';
import { ReactComponent as SearchPositive } from '../../assets/svgs/illustrations/cart.svg';
import { useIonRouter } from '@ionic/react';
import { useGetUserDataQuery, useLazyGetUserPaymentsQuery } from '../../redux/api/queries/userQueries';
import { Box, Button, Chip, Divider, Paper, Skeleton, Typography, useMediaQuery } from '@mui/material';
import WarningMessage from './Overview/WarningMessage';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import UserTablesRefresher from './UserTablesRefresher';
import PaymentsFillPaymentMethodsBox from './Payments/PaymentsFillPaymentMethodsBox';

const Payments = () => {
  const router = useIonRouter();
  const dispatch = useDispatch();

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const tablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const [openTooltip, setOpenTooltip] = useState(false);
  const [filtersModal, setFiltersModal] = useState<boolean>(false);

  const [orderBy, setOrderBy] = useState<PaymentSort>(PaymentSort.date);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');

  const [paymentsList, setPaymentsList] = useState<Payment[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<{ label: string; value: 1 | 3 }[]>([]);

  const [trigger, { data: payments, isFetching, isError: paymentsError }] = useLazyGetUserPaymentsQuery();
  const { data: userData, isLoading: userDataLoading, isError: userDataError, isSuccess } = useGetUserDataQuery();

  const appendData = useRef<boolean>(false);
  const cursorRef = useRef<string | null | undefined>(null);

  const [isLoadingPayments, setIsLoadingPayments] = useState<boolean>(true);
  const [isRefreshingPayments, setIsRefreshingPayments] = useState<boolean>(false);

  useEffect(() => {
    if (paymentsError || userDataError) {
      const params = { value: true, msg: 'Κάτι πήγε στραβά με τις πληρωμές.', severity: 'error' };
      dispatch(setSnackBar(params));
    } else {
      const params = { value: false, msg: '' };
      dispatch(setSnackBar(params));
    }
  }, [paymentsError, userDataError]);

  useEffect(() => {
    if (!isSuccess || isFetching) return;
    if (payments && !isRefreshingPayments) {
      if (appendData.current) {
        setPaymentsList(paymentsList.concat(payments.data));
        appendData.current = false;
      } else {
        setPaymentsList(payments.data);
      }
      setIsLoadingPayments(false);
      cursorRef.current = payments.nextCursor;
    } else if (payments && isRefreshingPayments) {
      setPaymentsList(payments.data);
    }
    setIsLoadingPayments(false);
    setIsRefreshingPayments(false);
  }, [isFetching, payments]);

  useEffect(() => {
    cursorRef.current = null;
    appendData.current = false;
    setIsLoadingPayments(true);
    callTrigger();
  }, [order, orderBy, paymentMethod]);

  const mouseOver = () => {
    setOpenTooltip((previousOpen) => !previousOpen);
  };

  const mouseOut = () => {
    setOpenTooltip(false);
  };

  const callTrigger = async () => {
    await trigger(
      {
        limit: 25,
        order: order,
        sort: orderBy,
        nextCursor: payments ? cursorRef.current : undefined,
        payment_option: paymentMethod.length ? paymentMethod[0].value : undefined,
      },
      true,
    );
  };

  const hasNoNextPage = () => {
    return !!(payments && payments.totalCount && paymentsList.length >= payments.totalCount);
  };

  const loadData = async (ev?: any) => {
    appendData.current = true;
    setIsLoadingPayments(true);
    await callTrigger();
    if (ev) ev.target.complete();
  };

  const handleRemovePaymentMethod = (value: number) => {
    setPaymentMethod(paymentMethod.filter((e) => e.value !== value));
  };

  const getSubTitle = () => {
    if (!mobile) {
      if (isLoadingPayments) {
        return '';
      }
      if (paymentsList.length === 0 && paymentMethod.length > 0) {
        return (
          <Typography variant="subtitle1SmallL" component="p" color="#313D53">
            Δεν βρέθηκαν πληρωμές
          </Typography>
        );
      }
      if (paymentsList.length === 0 && !isLoadingPayments) {
        return (
          <Typography variant="subtitle1SmallL" component="p" color="#313D53">
            Δεν έχεις λάβει ακόμα κάποια πληρωμή.
          </Typography>
        );
      }
    } else {
      if (isLoadingPayments) {
        return '';
      }
      if (paymentsList.length === 0 || paymentMethod.length > 0) {
        return (
          <Box display="flex" justifyContent="center" px={3}>
            <Typography width="279px" variant="body2" color={'#313D53'}>
              {paymentMethod.length > 0 ? 'Δεν βρέθηκαν πληρωμές' : 'Δεν έχεις λάβει ακόμα κάποια πληρωμή.'}
            </Typography>
          </Box>
        );
      }
    }
  };

  const renderChips = (desktop: boolean) => {
    if (paymentMethod.length > 0) {
      return (
        <Box display="flex" flexWrap="wrap" gap={1} pt={0} pb={5} px={desktop ? 3 : 0}>
          {paymentMethod.map((item, index) => (
            <Chip
              key={index}
              label={item?.label}
              deleteIcon={<Close />}
              sx={{
                fontSize: mdDown ? '14px' : '12px',
                fontWeight: 500,
                lineHeight: '1.33',
                backgroundColor: '#EAECEE',
                color: '#313D53',
                padding: mdDown ? '8px 12px 8px 12px' : '4px 8px 4px 12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '4px',
                borderRadius: '32px',
                minHeight: mdDown ? '40px' : '32px',
                '& .MuiChip-deleteIcon': {
                  width: '24px',
                  height: '24px',
                  margin: '0',
                  color: '#313D53',
                  padding: mdDown ? '0' : '3px',
                },
                '& .MuiChip-label': {
                  paddingRight: '0px',
                  paddingLeft: '0px',
                },
              }}
              onDelete={() => handleRemovePaymentMethod(item.value)}
            />
          ))}
        </Box>
      );
    }
  };

  const renderMobileView = () => {
    if (paymentsList.length || isLoadingPayments || isRefreshingPayments) {
      return (
        <Box>
          <Box
            display="flex"
            pt={5}
            pb={2}
            mb={2}
            borderBottom={'1px solid #EAECEE'}
            flexDirection={'column'}
            gap={2}
            alignItems="flex-start"
          >
            <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
              <PigogoSmallButton
                variant={'outlined'}
                text={'Φίλτρα & Ταξινόμηση'}
                onClick={() => setFiltersModal(true)}
                typographyProps={{ variant: 'subtitle1SmallR' }}
                sx={{ fontSize: '14px', fontWeight: 400, height: '42px' }}
              />
              <Box display="flex" flexDirection="column">
                <Box sx={{ height: '42px' }}>
                  <Typography variant="subtitle1SmallL" component="p" color="primary">
                    Έχεις πληρωθεί
                  </Typography>
                  <Box display="flex" gap={1} alignItems="center">
                    <Typography
                      component="h3"
                      fontSize={'20px !important'}
                      fontWeight={'700 !important'}
                      lineHeight={'1.2 !important'}
                      color={'#1D2532 !important'}
                    >
                      {userDataLoading ? <Skeleton sx={{ px: 3 }} /> : userData?.total_paid_cashback_amount}
                    </Typography>
                    <Box
                      height={'24px'}
                      width={'24px'}
                      display={'inline-flex'}
                      onMouseOver={mouseOver}
                      onMouseOut={mouseOut}
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      <RichTooltip
                        color="dark"
                        placement="bottom"
                        open={openTooltip}
                        onClose={mouseOut}
                        content={
                          <Typography variant="caption" component="p">
                            Το συνολικό ποσό που έχεις πληρωθεί από το Pigogo
                          </Typography>
                        }
                      >
                        <Info />
                      </RichTooltip>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            {paymentMethod.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1} pt={0} pb={0} px={0}>
                {paymentMethod.map((shop, index) => (
                  <Chip
                    deleteIcon={<Close />}
                    sx={{
                      fontSize: mdDown ? '14px' : '12px',
                      fontWeight: 500,
                      lineHeight: '1.33',
                      backgroundColor: '#EAECEE',
                      color: '#313D53',
                      padding: mdDown ? '8px 12px 8px 12px' : '4px 8px 4px 12px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '4px',
                      borderRadius: '32px',
                      minHeight: mdDown ? '40px' : '32px',
                      '& .MuiChip-deleteIcon': {
                        width: '24px',
                        height: '24px',
                        margin: '0',
                        color: '#313D53',
                        padding: mdDown ? '0' : '3px',
                      },
                      '& .MuiChip-label': {
                        paddingRight: '0px',
                        paddingLeft: '0px',
                      },
                    }}
                    key={index}
                    label={shop?.label}
                    onDelete={() => handleRemovePaymentMethod(shop.value)}
                  />
                ))}
              </Box>
            )}
          </Box>

          <UserTablesRefresher
            setIsRefreshingData={setIsRefreshingPayments}
            trigger={async () => {
              return await trigger(
                {
                  limit: 25,
                  order: order,
                  sort: orderBy,
                  nextCursor: undefined,
                  payment_option: paymentMethod.length ? paymentMethod[0].value : undefined,
                },
                false,
              );
            }}
          ></UserTablesRefresher>

          {!isLoadingPayments && !isRefreshingPayments && <PaymentsTableMobile payments={paymentsList} />}
          {(isLoadingPayments || isRefreshingPayments) && <PaymentsTableMobile isLoading />}
          <Box mb={3}>
            {!isLoadingPayments && (
              <Pagination
                currentCount={payments ? paymentsList.length : 0}
                total={payments?.totalCount ?? 0}
                nextPage={payments?.nextCursor}
                loadMore={loadData}
              />
            )}
          </Box>
        </Box>
      );
    } else {
      return (
        <Box py={mdDown ? '0 16px' : 5}>
          <UserTablesRefresher
            setIsRefreshingData={setIsRefreshingPayments}
            trigger={async () => {
              return await trigger(
                {
                  limit: 25,
                  order: order,
                  sort: orderBy,
                  nextCursor: undefined,
                  payment_option: paymentMethod.length ? paymentMethod[0].value : undefined,
                },
                false,
              );
            }}
          ></UserTablesRefresher>
          {userData?.user_warning_message && (
            <Box display="flex" flexDirection="column">
              <WarningMessage />
            </Box>
          )}

          {paymentMethod.length > 0 ? (
            <Box display="flex" pt={4} pb={2} justifyContent="space-between">
              <PigogoSmallButton
                variant={'outlined'}
                text={'Φίλτρα & Ταξινόμηση'}
                onClick={() => setFiltersModal(true)}
                typographyProps={{ variant: 'subtitle1SmallR' }}
                sx={{ fontSize: '14px', fontWeight: 400, height: '42px' }}
              />
            </Box>
          ) : (
            ''
          )}

          {renderChips(false)}
          <Box sx={{ textAlign: 'center', paddingTop: 5 }}>
            <SearchPositive />
            {getSubTitle()}
          </Box>

          <PaymentsFillPaymentMethodsBox />
        </Box>
      );
    }
  };

  const renderMobile = () => {
    if (paymentsList.length || isLoadingPayments || isRefreshingPayments) {
      return (
        <Box pt={5}>
          <UserTablesRefresher
            setIsRefreshingData={setIsRefreshingPayments}
            trigger={async () => {
              return await trigger(
                {
                  limit: 25,
                  order: order,
                  sort: orderBy,
                  nextCursor: undefined,
                  payment_option: paymentMethod.length ? paymentMethod[0].value : undefined,
                },
                false,
              );
            }}
          ></UserTablesRefresher>
          <WarningMessage />
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <PigogoSmallButton
              variant={'outlined'}
              text={'Φίλτρα & Ταξινόμηση'}
              onClick={() => setFiltersModal(true)}
              typographyProps={{ variant: 'subtitle1SmallR' }}
              sx={{ fontSize: '14px', fontWeight: 400, height: '42px' }}
            />
            <Box display="flex" flexDirection="column" height="42px">
              <Box>
                <Typography variant="subtitle1SmallL" component="p" color="primary">
                  Έχεις πληρωθεί
                </Typography>
                <Box display="flex" gap={1} alignItems="center">
                  <Typography
                    component="h3"
                    fontSize={'20px !important'}
                    fontWeight={'700 !important'}
                    lineHeight={'1.2 !important'}
                    color={'#1D2532 !important'}
                  >
                    {userDataLoading ? <Skeleton sx={{ px: 3 }} /> : userData?.total_paid_cashback_amount}
                  </Typography>
                  <Box
                    height={'24px'}
                    width={'24px'}
                    display={'inline-flex'}
                    onMouseOver={mouseOver}
                    onMouseOut={mouseOut}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <RichTooltip
                      color="dark"
                      placement="bottom"
                      open={openTooltip}
                      onClose={mouseOut}
                      content={
                        <Typography variant="caption" component="p">
                          Το συνολικό ποσό που έχεις πληρωθεί από το Pigogo
                        </Typography>
                      }
                    >
                      <Info />
                    </RichTooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
            pb={paymentsList.length > 0 || isLoadingPayments || isRefreshingPayments ? 2 : 0}
            mb={paymentsList.length > 0 || isLoadingPayments || isRefreshingPayments ? 2 : 0}
            borderBottom={
              paymentsList.length > 0 || isLoadingPayments || isRefreshingPayments ? '1px solid #EAECEE' : ''
            }
          >
            {paymentMethod.length > 0 && (
              <Box display="flex" flexWrap="wrap" pt={2} gap={1}>
                {paymentMethod.map((method, index) => (
                  <Chip
                    deleteIcon={<Close />}
                    sx={{
                      fontSize: mdDown ? '14px' : '12px',
                      fontWeight: 500,
                      lineHeight: '1.33',
                      backgroundColor: '#EAECEE',
                      color: '#313D53',
                      padding: mdDown ? '8px 12px 8px 12px' : '4px 8px 4px 12px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '4px',
                      borderRadius: '32px',
                      minHeight: mdDown ? '40px' : '32px',
                      '& .MuiChip-deleteIcon': {
                        width: '24px',
                        height: '24px',
                        margin: '0',
                        color: '#313D53',
                        padding: mdDown ? '0' : '3px',
                      },
                      '& .MuiChip-label': {
                        paddingRight: '0px',
                        paddingLeft: '0px',
                      },
                    }}
                    key={index}
                    label={method?.label}
                    onDelete={() => handleRemovePaymentMethod(method.value)}
                  />
                ))}
              </Box>
            )}
          </Box>
          <>
            {!isLoadingPayments && !isRefreshingPayments && <PaymentsTableMobile payments={paymentsList} />}
            {(isLoadingPayments || isRefreshingPayments) && <PaymentsTableMobile isLoading />}
            <Box mb={3}>
              {!isLoadingPayments && (
                <Pagination
                  currentCount={payments ? paymentsList.length : 0}
                  total={payments?.totalCount ?? 0}
                  nextPage={payments?.nextCursor}
                  loadMore={loadData}
                />
              )}
            </Box>
          </>
        </Box>
      );
    } else {
      return (
        <Box textAlign="center" pt={paymentMethod.length > 0 && paymentsList.length > 0 ? 5 : 4} pb={5}>
          <UserTablesRefresher
            setIsRefreshingData={setIsRefreshingPayments}
            trigger={async () => {
              return await trigger(
                {
                  limit: 25,
                  order: order,
                  sort: orderBy,
                  nextCursor: undefined,
                  payment_option: paymentMethod.length ? paymentMethod[0].value : undefined,
                },
                false,
              );
            }}
          ></UserTablesRefresher>
          {paymentMethod.length > 0 && (
            <Box textAlign="left">
              <PigogoSmallButton
                variant={'outlined'}
                text={'Φίλτρα & Ταξινόμηση'}
                onClick={() => setFiltersModal(true)}
                typographyProps={{ variant: 'subtitle1SmallR' }}
                sx={{ fontSize: '14px', fontWeight: 400, height: '42px' }}
              />
            </Box>
          )}
          {paymentMethod.length > 0 && (
            <Box pt={2} pb={5} display="flex" flexWrap="wrap" gap={1}>
              {paymentMethod.map((method, index) => (
                <Chip
                  deleteIcon={<Close />}
                  sx={{
                    fontSize: mdDown ? '14px' : '12px',
                    fontWeight: 500,
                    lineHeight: '1.33',
                    backgroundColor: '#EAECEE',
                    color: '#313D53',
                    padding: mdDown ? '8px 12px 8px 12px' : '4px 8px 4px 12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    borderRadius: '32px',
                    minHeight: mdDown ? '40px' : '32px',
                    '& .MuiChip-deleteIcon': {
                      width: '24px',
                      height: '24px',
                      margin: '0',
                      color: '#313D53',
                      padding: mdDown ? '0' : '3px',
                    },
                    '& .MuiChip-label': {
                      paddingRight: '0px',
                      paddingLeft: '0px',
                    },
                  }}
                  key={index}
                  label={method?.label}
                  onDelete={() => handleRemovePaymentMethod(method.value)}
                />
              ))}
            </Box>
          )}
          <Box pt={5}>
            <SearchPositive />
          </Box>
          {getSubTitle()}

          <PaymentsFillPaymentMethodsBox />
        </Box>
      );
    }
  };

  const renderDesktop = () => {
    return (
      <Box pt={tablet ? 4 : 0} display={'flex'} gap={6} flexDirection={'column'}>
        <Box border="2px solid #EAECEE" borderRadius="24px">
          <Box
            padding={'20px 24px 19px'}
            display="flex"
            justifyContent="space-between"
            borderBottom="2px solid #EAECEE"
          >
            <Box display="flex" flexDirection="column" gap={'4px'}>
              <Typography variant="large900" component="h3" color={'#1D2532'}>
                Πληρωμές
              </Typography>
              {getSubTitle()}
            </Box>
            {paymentsList.length > 0 && (
              <Box display="flex">
                <Divider orientation="vertical" />
                <Box pl={2} pr={1.5} display="flex" flexDirection="column" gap={0.5}>
                  <Typography variant="subtitle1SmallL" component="p" color="primary">
                    Έχεις πληρωθεί
                  </Typography>
                  <Box display="flex" gap={1} alignItems="center">
                    <Typography variant="h5" component="p" fontWeight={700} color={'#1D2532'}>
                      {userDataLoading ? <Skeleton sx={{ paddingX: 3 }} /> : userData?.total_paid_cashback_amount}
                    </Typography>
                    <Box
                      height={'24px'}
                      width={'24px'}
                      display={'inline-flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      onMouseOver={mouseOver}
                      onMouseOut={mouseOut}
                      sx={{ cursor: 'pointer' }}
                    >
                      <RichTooltip
                        color="dark"
                        placement="bottom"
                        open={openTooltip}
                        onClose={mouseOut}
                        content={
                          <Typography variant="caption" component="p">
                            Το συνολικό ποσό που έχεις πληρωθεί από το Pigogo
                          </Typography>
                        }
                      >
                        <Info />
                      </RichTooltip>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          {paymentMethod.length > 0 && (
            <Box p={2} display="flex" flexWrap="wrap" gap={1}>
              {paymentMethod.map((method, index) => (
                <Chip
                  deleteIcon={<Close />}
                  sx={{
                    fontSize: mdDown ? '14px' : '12px',
                    fontWeight: 500,
                    lineHeight: '1.33',
                    backgroundColor: '#EAECEE',
                    color: '#313D53',
                    padding: mdDown ? '8px 12px 8px 12px' : '4px 8px 4px 12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    borderRadius: '32px',
                    minHeight: mdDown ? '40px' : '32px',
                    '& .MuiChip-deleteIcon': {
                      width: '24px',
                      height: '24px',
                      margin: '0',
                      color: '#313D53',
                      padding: mdDown ? '0' : '3px',
                    },
                    '& .MuiChip-label': {
                      paddingRight: '0px',
                      paddingLeft: '0px',
                    },
                  }}
                  key={index}
                  label={method?.label}
                  onDelete={() => handleRemovePaymentMethod(method.value)}
                />
              ))}
            </Box>
          )}
          {isLoadingPayments || (!paymentsList.length && userDataLoading) || paymentsList.length ? (
            <PaymentsTableDesktop
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              isLoading={isLoadingPayments}
              payments={paymentsList}
              setOrderBy={setOrderBy}
            />
          ) : (
            <Box p={3} textAlign="center">
              <SearchPositive />
            </Box>
          )}
        </Box>
        {!isLoadingPayments && paymentsList.length > 0 && payments && payments?.totalCount !== paymentsList.length && (
          <Box mb={2}>
            <Pagination
              currentCount={payments ? paymentsList.length : 0}
              total={payments?.totalCount ?? 0}
              nextPage={payments?.nextCursor}
              loadMore={loadData}
            />
          </Box>
        )}
        <Paper
          elevation={0}
          sx={{ background: '#F7F7F8', borderRadius: '24px', border: '2px solid #EAECEE', mt: mobile ? 4 : 0 }}
        >
          <Box display="flex" flexDirection="column" gap={3} alignItems="center" p="32px 80px">
            <Box display={'flex'} flexDirection={'column'} gap={1} alignItems="center">
              <Typography variant="subtitle1" component="h4" fontWeight={700} color={'#313D53'}>
                Συμπλήρωσες τα στοιχεία της πληρωμής σου;
              </Typography>
              <Typography textAlign="center" variant="subtitle1" component="p" fontWeight={400} color={'#313D53'}>
                Δες τους διαθέσιμους τρόπους πληρωμής και συμπλήρωσε τα στοιχεία σου για να πληρωθείς!
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Link
                className="pigogoButton pigogoButton--outlined"
                to={'/user/payments-options'}
                style={{ width: '327px', maxWidth: '100%' }}
              >
                <span>Στοιχεία πληρωμής</span>
              </Link>
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  };

  return (
    <>
      {location.pathname === '/user/payments' && (
        <Helmet>
          <title>Πληρωμές - Ο λογαριασμός μου | Pigogo</title>
          <meta
            name="description"
            content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
          />
        </Helmet>
      )}
      {userData?.user_warning_message && (
        <Box display="flex" flexDirection="column" mt={mobile || tablet ? 2 : undefined} mb={mdDown ? 0 : 2}>
          <WarningMessage />
        </Box>
      )}
      {!mobile ? renderDesktop() : renderMobileView()}
      <PaymentsFilterModal
        sort={orderBy}
        order={order}
        setOrder={setOrder}
        setSort={setOrderBy}
        isOpen={filtersModal}
        setOpen={setFiltersModal}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
      />
    </>
  );
};

export default Payments;
