import { Box, SelectChangeEvent, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { PigogoClearDropdown, PigogoSmallButton, PigogoValueLabelDropdown, theme } from 'components';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { ShopCategory } from '../../models/ShopCategory';
import { useLazyAllCategoriesQuery, useLazyChildrenCategoryQuery } from '../../redux/api/queries/categoriesQuery';
import { Discount } from '../../redux/api/types/enum/Discount';
import FilterModal from '../FilterModal';
import CategoriesChipList from './CategoriesChipList';
import DiscountChipList from './DiscountChipList';
import MultipleDropdown from './MultipleDropdown';
import { useIonRouter } from '@ionic/react';
import { useHistory } from 'react-router';

const discountOptions = [
  { value: Discount.OFFERS, label: 'Προσφορές' },
  { value: Discount.COUPONS, label: 'Κουπόνια' },
];
const sortOptions = [
  { label: 'Δημοτικότητα', value: 'p' },
  { label: 'Αλφαβητικά', value: 'a' },
  { label: 'Πιο πρόσφατα', value: 'r' },
  { label: 'Υψηλότερη επιστροφή', value: 'hr' },
];

type SelectedCategories = { slugName: string; label: string };

type Props = {
  parentCategoryId?: string;
  totalResults: number;
  offers?: boolean;
  searchKeyword?: string;
  searchCategories?: ShopCategory[];
  selectedCategories: string[];
  selectedDiscounts: string[];
  sort: string;
  setSelectedCategories: (val: string[]) => void;
  setSelectedDiscounts: (val: string[]) => void;
  setSort: (val: string) => void;
  page: number;
  setPage: (val: number) => void;
  setLoading: (value: boolean) => void;
};
const FiltersSection: FC<Props> = ({
  parentCategoryId,
  totalResults,
  offers,
  searchCategories,
  searchKeyword,
  selectedCategories,
  selectedDiscounts,
  sort,
  setSelectedCategories,
  setSelectedDiscounts,
  setSort,
  page,
  setPage,
  setLoading,
}) => {
  const router = useIonRouter();
  const path = router.routeInfo.pathname;

  const history = useHistory();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [getAllCategories, { data: allCategories, isLoading: loadAllCategories }] = useLazyAllCategoriesQuery();
  const [getChildrenCategory, { data: childrenCategory, isLoading: loadCategoryChildren }] =
    useLazyChildrenCategoryQuery();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [fetchPrevious, setFetchPrevious] = useState<boolean>(!!(history.location.state as any)?.fetchPrevious);

  useEffect(() => {
    if (parentCategoryId) {
      getChildrenCategory({ menuCategoryId: parentCategoryId });
    } else {
      getAllCategories({ filter: 'parents' });
    }
  }, [parentCategoryId, getAllCategories, getChildrenCategory]);

  const categoriesChecked = useMemo(() => {
    const categoriesData: ShopCategory[] = [];
    if (parentCategoryId) {
      if (childrenCategory) {
        categoriesData.push(...childrenCategory.data);
      }
    } else {
      if (allCategories) {
        categoriesData.push(...allCategories.data);
      }
    }
    const data = selectedCategories.map((categorySlug) => {
      const category = categoriesData.find((category) => category.slug.name === categorySlug);
      if (category) return { slugName: categorySlug, label: category.label };
    });

    const newData = data.filter((e) => e !== undefined);
    return newData as SelectedCategories[];
  }, [allCategories, childrenCategory, selectedCategories]);

  const categoriesValues = useMemo(() => {
    if (parentCategoryId) {
      if (childrenCategory) {
        return childrenCategory.data;
      }
    } else if (searchCategories) {
      return searchCategories;
    } else if (allCategories) {
      return allCategories.data;
    }
    return [];
  }, [allCategories, childrenCategory, searchCategories, parentCategoryId]);

  const handleRemoveCategory = (categorySlugName: string) => {
    const updatedCategories = selectedCategories.filter((categorySlug) => categorySlug !== categorySlugName);
    setSelectedCategories(updatedCategories);
    setPage(1);

    setLoading(true);
    const params = new URLSearchParams(history.location.search);
    params.delete('page');

    if (updatedCategories.length === 0) {
      params.delete('categories');
    } else {
      params.set('categories', updatedCategories.join(','));
    }
    history.replace({
      search: params.toString(),
    });
  };

  const handleRemoveDiscount = (discountName: string) => {
    const updatedDiscounts = selectedDiscounts.filter((e) => e !== discountName);
    setSelectedDiscounts(updatedDiscounts);
    setPage(1);

    setLoading(true);
    const params = new URLSearchParams(history.location.search);
    params.delete('page');

    if (updatedDiscounts.length === 0) {
      params.delete('discountTypes');
    } else {
      params.set('discountTypes', updatedDiscounts.join(','));
    }
    history.replace({
      search: params.toString(),
    });
  };

  const filters = () => {
    return (
      <Box display={'flex'} flexDirection={'column'} width={'100%'} gap={4}>
        <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} gap={'32px'}>
            {loadCategoryChildren || loadAllCategories ? (
              <Skeleton width="304px" height="56px" sx={{ borderRadius: '30px' }} variant="rectangular" />
            ) : (
              <MultipleDropdown
                offers={offers}
                label="Κατηγορίες"
                options={categoriesValues}
                parentCategory={parentCategoryId}
                categoriesChecked={categoriesChecked}
                searchKeyword={searchKeyword ? searchKeyword : ''}
                setCategoriesChecked={(value: { slugName: string; label: string }[]) => {
                  const params = new URLSearchParams(history.location.search);
                  params.set('categories', value.map((e) => e.slugName).join(','));
                  params.delete('page');
                  setPage(1);
                  setLoading(true);
                  setSelectedCategories(value.map((e) => e.slugName));
                  history.replace({
                    search: params.toString(),
                  });
                }}
              />
            )}
            {loadCategoryChildren || loadAllCategories ? (
              <Skeleton width="304px" height="56px" sx={{ borderRadius: '30px' }} variant="rectangular" />
            ) : (
              <PigogoValueLabelDropdown
                multiple
                size={'medium'}
                label="Είδος έκπτωσης"
                sx={{ width: '304px' }}
                options={discountOptions}
                selected={selectedDiscounts}
                handleChange={(value: SelectChangeEvent<unknown>) => {
                  const params = new URLSearchParams(history.location.search);
                  params.set('discountTypes', (value.target.value as string[]).join(','));
                  params.delete('page');
                  setPage(1);
                  setLoading(true);
                  setSelectedDiscounts(value.target.value as string[]);
                  history.replace({
                    search: params.toString(),
                  });
                }}
              />
            )}
          </Box>
          {loadCategoryChildren || loadAllCategories ? (
            <Skeleton width="208px" height="28px" sx={{ borderRadius: '16px' }} variant="rectangular" />
          ) : (
            <PigogoClearDropdown
              value={sort}
              label="Ταξινόμηση"
              options={sortOptions}
              handleChange={(value: string) => {
                setSort(value);
                setFetchPrevious(false);
                setLoading(true);
                setPage(1);
                const params = new URLSearchParams(history.location.search);
                params.set('sort', value);
                params.delete('page');
                history.replace({
                  search: params.toString(),
                });
              }}
              sx={{ marginRight: '-12px' }}
            />
          )}
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
          <Box display="flex" flexWrap="wrap" gap={2}>
            <CategoriesChipList selectedCategories={categoriesChecked} handleRemoveCategory={handleRemoveCategory} />
            <DiscountChipList
              allDiscounts={discountOptions}
              selectedDiscounts={selectedDiscounts}
              handleRemoveDiscount={handleRemoveDiscount}
            />
          </Box>
          <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} sx={{ minWidth: '160px' }}>
            {loadCategoryChildren || loadAllCategories ? (
              <Skeleton width="154px" height="16px" sx={{ borderRadius: '16px' }} variant="rectangular" />
            ) : (
              <Typography color="#838B98" variant="captionM" component="p" role="alert">
                {`Βρέθηκαν ${totalResults ? totalResults : '0'} αποτελέσματα`}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const filterMobile = () => {
    return (
      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pt={1}>
          <Box>
            <Typography variant="subtitle1SmallR" color="#313D53" component="p" role="alert">
              {`${totalResults ? totalResults : '0'} αποτελέσματα`}
            </Typography>
          </Box>
          <Box>
            <PigogoSmallButton
              variant={'outlined'}
              text={'Φίλτρα & Ταξινόμηση'}
              onClick={() => setOpenModal(true)}
              typographyProps={{ variant: 'subtitle1SmallR' }}
              sx={{
                height: '48px',
                color: '#313D53',
                borderRadius: '88px',
                padding: '14px',
                '&.MuiButton-outlined': { border: '2px solid #ECEFF4' },
              }}
            />
          </Box>
        </Box>
        <Box display="flex" flexWrap="wrap" gap={1} mt={3} sx={{ '&:empty': { display: 'none' } }}>
          <CategoriesChipList selectedCategories={categoriesChecked} handleRemoveCategory={handleRemoveCategory} />
          <DiscountChipList
            allDiscounts={discountOptions}
            selectedDiscounts={selectedDiscounts}
            handleRemoveDiscount={handleRemoveDiscount}
          />
        </Box>
      </Box>
    );
  };

  return (
    <>
      {mdDown ? filterMobile() : filters()}
      <FilterModal
        sort={sort}
        setSort={(value: string) => {
          const params = new URLSearchParams(history.location.search);
          params.set('sort', value);
          params.delete('page');
          setPage(1);
          setLoading(true);
          setSort(value);
          history.replace({
            search: params.toString(),
          });
        }}
        isOpen={openModal}
        setOpen={setOpenModal}
        discount={selectedDiscounts}
        categories={categoriesValues}
        setDiscount={(value: string[]) => {
          const params = new URLSearchParams(history.location.search);
          params.set('discountTypes', value.join(','));
          params.delete('page');
          setPage(1);
          setLoading(true);
          setSelectedDiscounts(value);
          history.replace({
            search: params.toString(),
          });
        }}
        categoriesChecked={categoriesChecked}
        setCategoriesChecked={(value: { slugName: string; label: string }[]) => {
          const data = value.map((e) => e.slugName);
          const params = new URLSearchParams(history.location.search);
          params.set('categories', data.join(','));
          params.delete('page');
          setPage(1);
          setLoading(true);
          setSelectedCategories(data);
          history.replace({
            search: params.toString(),
          });
        }}
      />
    </>
  );
};

export default FiltersSection;
