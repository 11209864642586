import React, { useEffect, useRef, useState } from 'react';
import { useIonRouter, useIonViewDidEnter } from '@ionic/react';
import { PigogoAccordion, PigogoStoreCard, PigogoStorePromo, PigogoStorePromoImg } from 'components';
import { Box, Typography } from '@mui/material';
import InformationStore from '../Stores/InformationStore';
import TipsButton from '../Stores/TipsButton';
import { ReactComponent as Dot } from '../../assets/svgs/dot_black.svg';
import { Promo } from '../../models/Promo';
import { useAppSelector } from '../../redux/hooks';
import { useDeleteFavouriteMutation, usePostFavouriteMutation } from '../../redux/api/mutations/favouritesMutation';
import moment from 'moment';
import useQuery from '../../hooks/useQuery';
import { Browser } from '@capacitor/browser';
import useDebounce from '../../hooks/useDebounce';
import { Shop } from '../../models/Shop';
import { setSnackBar } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import { ReactComponent as InfoOutlined } from '../../assets/svgs/infoBig.svg';
import PigogoReturnMoney from './PigogoReturnMoney';
import PigogoOfferTagCard from './PigogoOfferTagCard';
import PigogoCouponCard from './PigogoCouponCard';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useAllCategoriesQuery } from '../../redux/api/queries/categoriesQuery';
import { isWeb } from '../../utils/device';

type StoreDetailPageProps = {
  shop?: Shop;
  shopSlug?: string;
  shopLoad: boolean;
  setLogin: (value: boolean) => void;
  message: (message: string) => void;
  setRedirectModal: (value: boolean) => void;
  footerMessage: (value: string) => void;
  footerRedirLink?: (value: string) => void;
  contentRef?: any;
};

const StoreMobile: React.FC<StoreDetailPageProps> = ({
  shop,
  shopSlug,
  shopLoad,
  setLogin,
  message,
  setRedirectModal,
  footerMessage,
  footerRedirLink,
  contentRef,
}) => {
  const query = useQuery();
  const router = useIonRouter();
  const dispatch = useDispatch();

  const [postFavourite, { isError: postError, reset: postReset }] = usePostFavouriteMutation();
  const [deleteFavourite, { isError: deleteError, reset: deleteReset }] = useDeleteFavouriteMutation();

  const {
    data: allCategories,
    isLoading: loadingCategories,
    isSuccess,
  } = useAllCategoriesQuery({ filter: 'parents' }, { refetchOnMountOrArgChange: false });

  const [shopState, setShopState] = useState<Shop>();
  const [selectedShop, setSelectedShop] = useState<Shop>();

  const [mutex, setMutex] = useState<boolean>(false);
  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const [postOperation, setPostOperation] = useState<boolean>(false);
  const [deleteOperation, setDeleteOperation] = useState<boolean>(false);

  const [favShop, setFavShop] = useState<boolean>(!!shop?.user_favourite);

  const isCapacitorApp = !isWeb();

  useEffect(() => {
    if (shop) {
      setSelectedShop(shop);
      setFavShop(!!shop.user_favourite);
    }
    if (!loadingCategories && isSuccess) {
      if (
        !shopLoad &&
        !shop &&
        !allCategories?.data.find((category) => category.slug.name === shopSlug) &&
        !!shopSlug
      ) {
        router.push('/404-not-found');
      }
    }
  }, [shop, shopLoad, loadingCategories]);

  useEffect(() => {
    const findId = query.get('offer');
    if (findId && !shopLoad) {
      const element = document.getElementById(findId)?.parentElement?.parentElement?.parentElement;
      setTimeout(() => {
        element?.scrollIntoView({
          behavior: 'smooth',
        });
      }, 1000);
    }
  }, [query, shopLoad]);

  const handleOffer = async () => {
    if (!!accessToken || sessionLoggedIn) {
      setRedirectModal(true);
    } else {
      openModalStore();
    }
  };

  const openModalLogin = () => {
    setLogin(true);
    message('Για να κερδίσεις επιστροφή χρημάτων πρέπει πρώτα να συνδεθείς στο Pigogo.');
    footerMessage('Μεταφορά χωρίς επιστροφή χρημάτων');
  };

  const openModalLoginCoupon = () => {
    setLogin(true);
    message('Για να δεις τον κωδικό του κουπονιού πρέπει πρώτα να συνδεθείς στο Pigogo.');
    footerMessage('Μεταφορά χωρίς επιστροφή χρημάτων');
  };

  const openModalStore = () => {
    setLogin(true);
    message('Για να κερδίσεις επιστροφή χρημάτων πρέπει πρώτα να συνδεθείς στο Pigogo.');
    footerMessage('Μεταφορά χωρίς επιστροφή χρημάτων');
    footerRedirLink && footerRedirLink(shop?.urlWithoutCashback || '');
  };

  const favourite = async () => {
    if (!!accessToken || sessionLoggedIn) {
      await setFavouriteDef();
    }
  };

  const setFavouriteDef = async () => {
    if (!shop) {
      return;
    }

    if (!favShop && !postOperation) {
      setPostOperation(true);
      setFavShop(true);

      const data: any = await postFavourite({ shop_id: shop.id, notifications: 1 });
      if (data?.error) {
        const params = { value: true, msg: 'Ανεπιτυχής προσθήκη αγαπημένου!', severity: 'error' };
        dispatch(setSnackBar(params));

        setFavShop(false);
      }

      setPostOperation(false);
    } else if (favShop && !deleteOperation) {
      setDeleteOperation(true);
      setFavShop(false);

      const data: any = await deleteFavourite(shop.id);
      if (data?.error) {
        const params = { value: true, msg: 'Ανεπιτυχής αφαίρεση αγαπημένου!', severity: 'error' };
        dispatch(setSnackBar(params));

        setFavShop(true);
      }

      setDeleteOperation(false);
    }

    return;
  };

  const permissions = () => {
    return (
      <Box display={'flex'} gap={1}>
        <Box>
          <Typography variant={'body2MediumL'} color={'#313D53'}>
            <>
              <ReactMarkdown
                children={selectedShop?.terms || ''}
                rehypePlugins={[rehypeRaw]}
                components={{
                  ol: ({ node, ...props }) => (
                    <ol
                      style={{
                        paddingLeft: '18px',
                        display: 'flex',
                        gap: '16px',
                        flexDirection: 'column',
                        margin: '0 0 8px 0',
                      }}
                      {...{ ...props, ordered: 'true' }}
                    />
                  ),
                  ul: ({ node, ...props }) => (
                    <ul
                      style={{
                        paddingLeft: '18px',
                        display: 'flex',
                        gap: '16px',
                        flexDirection: 'column',
                        margin: '0 0 8px 0',
                      }}
                      {...{ ...props, ordered: 'true' }}
                    />
                  ),
                  a: ({ node, ...props }) => (
                    <a
                      style={{
                        color: '#313D53',
                        textDecorationColor: '#313D53',
                        fontWeight: '400',
                      }}
                      {...props}
                    />
                  ),
                }}
              />
            </>
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderAppWarning = () => {
    if (selectedShop?.has_mobile_app) {
      return (
        <Box
          p={2}
          gap={2}
          display={'flex'}
          alignItems={'center'}
          sx={{ borderRadius: '8px', background: '#ECEFF4', color: '#313D53' }}
        >
          <Box>
            <InfoOutlined />
          </Box>
          <Typography variant={'captionM'}>{selectedShop?.mobile_app_warning}</Typography>
        </Box>
      );
    }
  };

  const handleClickStore = async (url: string) => {
    if (!!accessToken || sessionLoggedIn) {
      setRedirectModal(true);
      await Browser.open({ url: url });
    } else {
      openModalStore();
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={4}>
      {/*First section*/}
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        {renderAppWarning()}
        <Box id={'#storeCard'}>
          <PigogoStoreCard
            loading={shopLoad}
            logout={!(!!accessToken || sessionLoggedIn)}
            logo={selectedShop?.image}
            favourite={favShop ? true : false}
            cashbackCount={selectedShop ? selectedShop.cashback_categories_count > 1 : false}
            discount={`${selectedShop?.cashback}${selectedShop?.currency}`}
            deleteDisc={
              selectedShop?.strikedCashback ? `${selectedShop?.strikedCashback}${selectedShop?.strikedCurrency}` : ''
            }
            setFavourite={() => favourite()}
            handleClick={async () => {
              if (!!accessToken || sessionLoggedIn) {
                setRedirectModal(true);
                if (isCapacitorApp) {
                  Browser.open({ url: selectedShop?.url || '' });
                } else {
                  window.open(selectedShop?.url, '_blank');
                }
              } else {
                openModalStore();
              }
            }}
            loggedIn={!!accessToken || sessionLoggedIn}
            url={selectedShop?.url}
          />
        </Box>

        {selectedShop &&
          selectedShop.promos &&
          selectedShop.promos.map((promo: Promo, index: number) => {
            return promo.image ? (
              <Box key={index}>
                <PigogoStorePromoImg
                  img={promo.image}
                  title={promo.box_type_copy || ''}
                  subTitle={promo.box_title || ''}
                  hotOffer={promo.is_double ? `x2` : undefined}
                  date={promo.box_subtitle || ''}
                  onClick={() => handleClickStore(promo.url)}
                />
              </Box>
            ) : (
              <Box key={index}>
                <PigogoStorePromo
                  title={promo.box_type_copy || ''}
                  subTitle={promo.box_title || ''}
                  boxProps={{
                    sx: {
                      width: '100%',
                      background: `linear-gradient(180deg, #313D53 0%, #313D5300 100%), ${promo.color || '#313D5300'}`,
                    },
                  }}
                  hotOffer={promo.is_double ? `x2` : undefined}
                  onClick={() => handleClickStore(promo.url)}
                  date={promo.box_subtitle || ''}
                />
              </Box>
            );
          })}
        <Box>
          <InformationStore
            mobile
            loading={shopLoad}
            week={`${selectedShop?.stats_payment_speed}`}
            speedTitle={`${selectedShop?.stats_tracking_speed}`}
            percent={`${selectedShop?.stats_payment_accuracy}`}
          />
        </Box>
        <Box>
          <TipsButton url={'/return-tips'} />
        </Box>
      </Box>

      {/*Second section*/}
      <Box display={'flex'} flexDirection={'column'} gap={3} px={2}>
        <Box className="accordeonP8 accordeon--noBorder">
          <PigogoAccordion
            loading={shopLoad}
            noBorder
            title={'Περιγραφή καταστήματος'}
            titleProps={{ variant: 'largeB', component: 'h3', color: '#1D2532' }}
          >
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              {shopLoad ? '' : selectedShop?.description}
            </Typography>
          </PigogoAccordion>
        </Box>
        {!!selectedShop?.terms && (
          <Box className="accordeonP8 accordeon--noBorder">
            <PigogoAccordion
              noBorder
              title={'Όροι & προϋποθέσεις'}
              titleProps={{ variant: 'largeB', component: 'h3', color: '#1D2532' }}
            >
              {permissions()}
            </PigogoAccordion>
          </Box>
        )}
      </Box>

      {/*Third section*/}
      <Box display={'flex'} flexDirection={'column'} gap={0}>
        <Box>
          <PigogoReturnMoney data={selectedShop ? selectedShop.cashback_categories : []} />
        </Box>
        <Box>
          <PigogoOfferTagCard
            loading={shopLoad}
            title={'Προσφορές καταστήματος'}
            data={selectedShop?.savings?.offers ?? []}
            handleButtonOffer={() => (!!accessToken || sessionLoggedIn ? handleOffer() : openModalLogin())}
            footerRedirLink={footerRedirLink}
          />
        </Box>
        <Box>
          <PigogoCouponCard
            logout={!(!!accessToken || sessionLoggedIn)}
            title={'Κουπόνια καταστήματος'}
            data={selectedShop?.savings?.coupons ?? []}
            setRedirectModal={() => setRedirectModal(true)}
            setLogin={() => openModalLoginCoupon()}
            footerRedirLink={footerRedirLink}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default StoreMobile;
