import React, { FC, useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import { PigogoEntryPoint } from 'components';
import { useIonRouter, useIonViewWillLeave } from '@ionic/react';
import CustomContainer from '../CustomContainer';
import LoginModal from '../Navigation/Modals/LoginModal';
import RegisterModal from '../Navigation/Modals/RegisterModal';
import ForgotModal from '../Navigation/Modals/ForgotModal';
import SuccessPasswordModal from '../Navigation/Modals/SuccessPasswordModal';
import { useAppSelector } from '../../redux/hooks';
import { Link } from 'react-router-dom';

interface Props {
  openLoginModal?: () => void;
}

const SupportSection: FC<Props> = ({ openLoginModal }) => {
  const router = useIonRouter();
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const width = () => {
    if (downLg && upMd) {
      return 'calc(50% - 16px)';
    }
    if (downMd) {
      return '100%';
    }
  };
  return (
    <CustomContainer
      display={'flex'}
      gap={downMd ? 2 : 4}
      flexWrap={downMd ? 'wrap' : 'nowrap'}
      justifyContent={'center'}
      sx={{ padding: downMd ? '0 0 48px' : '0 32px 130px' }}
    >
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: width(), position: 'relative' }}>
        <Link className="absLink" to={'/contact'}></Link>
        <PigogoEntryPoint
          linkText="δες περισσότερα"
          title="Φόρμα επικοινωνίας"
          subTitle="Στείλε μας μήνυμα και η ομάδα μας θα απαντήσει άμεσα!"
          onClick={() => {
            return;
          }}
        />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: width(), position: 'relative' }}>
        <Link
          className="absLink"
          to={'/create-claim'}
          onClick={() => {
            if (openLoginModal && !(!!accessToken || sessionLoggedIn)) {
              openLoginModal();
            } else {
              router.push('/create-claim');
            }
          }}
        ></Link>
        <PigogoEntryPoint
          sx={{ background: '#FDF0F3' }}
          linkText="δες περισσότερα"
          title="Δημιουργία αιτήματος"
          onClick={() => {
            if (openLoginModal && !(!!accessToken || sessionLoggedIn)) {
              openLoginModal();
            } else {
              router.push('/create-claim');
            }
          }}
          subTitle="Ψάχνεις κάτι σχετικό με τις αγορές σου και δεν το βρίσκεις; Μπορείς να δημιουργήσεις ένα αίτημα για
              να το ερευνήσουμε!"
        />
      </Box>
    </CustomContainer>
  );
};

export default SupportSection;
