import { pigogoApi } from '../Api';
import { AllShop } from '../../../models/AllShop';
import { SearchParams } from '../types/SearchParams';
import { GenericRequest } from '../../../models/GenericRequest';
import { GenericResponse } from '../../../models/GenericResponse';
import { extractData } from '../../../utils/extractData';

const promosApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query<GenericRequest<AllShop[]>, SearchParams>({
      query: (args) => {
        const { category, nextCursor, keyword, limit, link, order, sort, discount } = args;
        return {
          url: '/search',
          params: { category, cursor: nextCursor, keyword, limit, link, order, sort, discount },
        };
      },
      providesTags: ['search'],
      async transformResponse(response: GenericResponse<AllShop[]> | AllShop[], meta) {
        const { responseData, nextCursor, categories, totalCount } = extractData<AllShop[]>(response);
        await Promise.all(
          responseData?.map((shop: AllShop) => {
            return new Promise((res) => {
              const shopImage = new Image();
              shopImage.src = shop.image as string;
              res(shopImage);
            });
          }),
        );
        return {
          data: responseData,
          categories: categories,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')) || totalCount,
          nextCursor: nextCursor,
        };
      },
    }),
    searchCountOfCategory: builder.query<
      { totalCount: number },
      { category: string; keyword: string; discount?: string }
    >({
      query: (args) => {
        const { category, keyword, discount } = args;
        return {
          url: '/search?total_count=true',
          method: 'GET',
          params: { category, keyword, discount },
        };
      },
      transformResponse(response, meta) {
        const { totalCount } = extractData<AllShop[]>(response as any);
        const totalCountHeader = Number(meta?.response?.headers.get('X-TOTAL-COUNT'));
        return { totalCount: totalCountHeader || totalCount };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useSearchQuery, useLazySearchQuery, useLazySearchCountOfCategoryQuery } = promosApi;
