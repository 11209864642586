import { useIonRouter } from '@ionic/react';
import { Box, Typography } from '@mui/material';
import { PigogoOfferCard, PigogoPromoCard, PigogoSingleCouponCard, theme } from 'components';
import React, { FC, useEffect } from 'react';
import { Savings } from '../models/Savings';
import { usePromosQuery } from '../redux/api/queries/promosQueries';
import { Promo } from '../models/Promo';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../redux/hooks';
import { useDispatch } from 'react-redux';
import { setSnackBar } from '../redux/slices/layoutSlice';
import { Link, useHistory } from 'react-router-dom';

interface Props {
  loading?: boolean;
  setLoading: (val: boolean) => void;
  loadingMore?: boolean;
  setLoadingMore: (val: boolean) => void;
  savings?: Savings[] | undefined;
  message: (value: string) => void;
  setLogin: (value: boolean) => void;
}

const dummyArray = [1, 1, 1, 1, 1, 1, 1, 1];

const OffersCards: FC<Props> = ({ loading, setLoading, savings, loadingMore, setLoadingMore, message, setLogin }) => {
  const router = useIonRouter();
  const path = router.routeInfo.pathname;

  const dispatch = useDispatch();

  const history = useHistory();

  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const extraLg = useMediaQuery(theme.breakpoints.between('md', 'xl'));

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const { data: promos } = usePromosQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  useEffect(() => {
    setLoadingMore(false);
    setLoading(false);
  }, [savings]);

  const openModalCoupon = () => {
    setLogin(true);
    message('Για να δεις τον κωδικό του κουπονιού πρέπει πρώτα να συνδεθείς στο Pigogo.');
  };

  const loadingMoreSkeleton = () => {
    if (loadingMore) {
      return dummyArray.map((item, index) => (
        <Box
          key={index}
          display={'flex'}
          flexDirection={'column'}
          sx={{
            width: {
              xs: 'calc(50% - 4px)',
              ssm: 'calc(25% - 6px)',
              md: 'calc(25% - 12px)',
              extraLg: 'calc(25% - 18px)',
            },
          }}
        >
          <PigogoOfferCard loading={true} />
        </Box>
      ));
    }
  };

  const onClickCopy = () => {
    const params = { value: true, msg: 'Αντιγράφηκε στο πρόχειρο', severity: 'infoBlue' };
    dispatch(setSnackBar(params));
  };

  const getSpacing = () => {
    if (mdDown) {
      return 1;
    }
    if (extraLg) {
      return 2;
    }

    return 4;
  };

  const offerCards = () => {
    if (loading && !loadingMore) {
      return dummyArray.map((item, index) => (
        <Box
          key={index}
          display="flex"
          justifyContent="center"
          sx={{
            width: {
              xs: 'calc(50% - 4px)',
              ssm: 'calc(25% - 6px)',
              md: 'calc(25% - 12px)',
              extraLg: 'calc(25% - 18px)',
            },
          }}
        >
          <PigogoOfferCard loading={true} />
        </Box>
      ));
    }

    if (savings && !loading) {
      return savings?.map((item, index) => (
        <React.Fragment key={index}>
          <Box
            key={index}
            sx={{
              width: {
                xs: 'calc(50% - 4px)',
                ssm: 'calc(25% - 6px)',
                md: 'calc(25% - 12px)',
                extraLg: 'calc(25% - 18px)',
              },
            }}
          >
            {item.code ? (
              <Box sx={{ width: '100%', position: 'relative' }}>
                <Link
                  className="storeCard storeCard--abs"
                  to={`/${item.shop.slug.name}?offer=${item.id}`}
                  onClick={() => {
                    if (mdDown) {
                      const searchParams = JSON.parse(localStorage.getItem('search-parameters') ?? '{}');
                      searchParams[history.location.pathname] = history.location.search;
                      localStorage.setItem('search-parameters', JSON.stringify(searchParams));

                      const scrollPositions = JSON.parse(localStorage.getItem('scroll-positions') ?? '{}');
                      scrollPositions[history.location.pathname] = (history.location.state as any)?.scrollY ?? 0;
                      localStorage.setItem('scroll-positions', JSON.stringify(scrollPositions));
                    }
                  }}
                ></Link>
                <PigogoSingleCouponCard
                  code={item.code}
                  logout={!(!!accessToken || sessionLoggedIn)}
                  logo={item.shop.image}
                  name={item.shop.name}
                  subTitle={item.description}
                  setLogin={() => openModalCoupon()}
                  cashbackCount={item.shop.cashback_categories_count}
                  onClickCopy={onClickCopy}
                  discount={item.shop.cashback !== null ? `${item.shop.cashback}${item.shop.currency}` : undefined}
                  deleteDisc={
                    item.shop.strikedCashback !== null
                      ? `${item.shop.strikedCashback}${item.shop.strikedCurrency}`
                      : undefined
                  }
                  date={item.end_date ? item.end_date : ''}
                  storeSlug={item.shop.slug.name}
                  id={item.id}
                />
              </Box>
            ) : (
              <Link className="storeCard" to={`/${item.shop.slug.name}?offer=${item.id}`}>
                <PigogoOfferCard
                  logo={item.shop.image}
                  name={item.shop.name}
                  subTitle={item.description}
                  discount={item.shop.cashback !== null ? `${item.shop.cashback}${item.shop.currency}` : undefined}
                  multiCashback={item.shop.cashback_categories_count >= 2}
                  deleteDisc={
                    item.shop.strikedCashback !== null
                      ? `${item.shop.strikedCashback}${item.shop.strikedCurrency}`
                      : undefined
                  }
                  date={item.end_date ? item.end_date : ''}
                  onClick={() => {
                    if (mdDown) {
                      const searchParams = JSON.parse(localStorage.getItem('search-parameters') ?? '{}');
                      searchParams[history.location.pathname] = history.location.search;
                      localStorage.setItem('search-parameters', JSON.stringify(searchParams));

                      const scrollPositions = JSON.parse(localStorage.getItem('scroll-positions') ?? '{}');
                      scrollPositions[history.location.pathname] = (history.location.state as any)?.scrollY ?? 0;
                      localStorage.setItem('scroll-positions', JSON.stringify(scrollPositions));
                    }
                  }}
                />
              </Link>
            )}
          </Box>
          {index === 8 && promos !== undefined && !path.includes('/promo') && (
            <Box
              key={promos.data.length}
              sx={{
                width: {
                  xs: 'calc(50% - 4px)',
                  ssm: 'calc(25% - 6px)',
                  md: 'calc(25% - 12px)',
                  extraLg: 'calc(25% - 18px)',
                },
              }}
            >
              <Link className="storeCard storeCard--offer" to={promos.data[0].url}>
                <PigogoPromoCard
                  lgText
                  logo={promos.data[0].image}
                  color={promos.data[0].color}
                  title={promos.data[0]?.tile_title}
                  call_to_action={!mdDown ? promos.data[0]?.call_to_action : promos.data[0]?.call_to_action_mobile}
                  onClick={() => {
                    if (mdDown) {
                      const searchParams = JSON.parse(localStorage.getItem('search-parameters') ?? '{}');
                      searchParams[history.location.pathname] = history.location.search;
                      localStorage.setItem('search-parameters', JSON.stringify(searchParams));

                      const scrollPositions = JSON.parse(localStorage.getItem('scroll-positions') ?? '{}');
                      scrollPositions[history.location.pathname] = (history.location.state as any)?.scrollY ?? 0;
                      localStorage.setItem('scroll-positions', JSON.stringify(scrollPositions));
                    }
                  }}
                />
              </Link>
            </Box>
          )}
        </React.Fragment>
      ));
    }
  };

  return (
    <Box display={'flex'} flexWrap={'wrap'} sx={{ gap: { xs: 1, md: 2, extraLg: 3 } }} paddingTop={3} paddingBottom={6}>
      {offerCards()}
      {loadingMoreSkeleton()}
    </Box>
  );
};

export default OffersCards;
