import React from 'react';
import { Box, Skeleton, Typography, List, ListItem } from '@mui/material';
import { useAllCategoriesQuery } from '../../../../redux/api/queries/categoriesQuery';
import { IonRouterLink, useIonRouter } from '@ionic/react';
import { Link, useHistory } from 'react-router-dom';

const Recommended = () => {
  const router = useIonRouter();
  const historyElem = useHistory();

  const {
    data: allCategories,
    isLoading: categoriesLoading,
    isFetching: categoriesFetching,
  } = useAllCategoriesQuery({ filter: 'parents' }, { refetchOnMountOrArgChange: false });

  return (
    <Box className={'focus-on-search'} display={'flex'} flexDirection={'column'} gap={2}>
      <Box className={'focus-on-search'}>
        <Typography variant="body2" color={'#313D53'} className={'focus-on-search'}>
          Προτεινόμενες κατηγορίες
        </Typography>
      </Box>
      <Box
        className={'focus-on-search'}
        gap={2}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        mb={-2}
      >
        {(categoriesLoading || categoriesFetching) &&
          !allCategories?.data.length &&
          [1, 1, 1, 1].map((item, index) => (
            <Typography className={'focus-on-search'} key={index} variant="subtitle1SmallR">
              <Skeleton className={'focus-on-search'} sx={{ px: 6 }} />
            </Typography>
          ))}
        <List
          className={'focus-on-search'}
          disablePadding={true}
          sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
        >
          {!(categoriesLoading || categoriesFetching) &&
            allCategories?.data.map(
              (category, index) =>
                index < 5 && (
                  <ListItem key={index} className={'focus-on-search'} sx={{ padding: 0 }}>
                    <IonRouterLink
                      className="btnLink btnLink--result focus-on-search"
                      routerDirection="none"
                      routerLink={`/${category.slug.name}`}
                    >
                      {category.label}
                    </IonRouterLink>
                  </ListItem>
                ),
            )}
        </List>
      </Box>
    </Box>
  );
};

export default Recommended;
