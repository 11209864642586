import { Box, Divider, Skeleton, Typography, useMediaQuery } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useIonRouter, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
import { PigogoSwitch, theme } from 'components';
import { useGetUserDataQuery } from '../../redux/api/queries/userQueries';
import { useUpdateUserMutation } from '../../redux/api/mutations/userMutations';
import { usePatchUserNotificationsUnsubscribeMutation } from '../../redux/api/mutations/unsubscribeMutation';
import useDebounce from '../../hooks/useDebounce';
import { UpdateUserParams } from '../../redux/api/types/UpdateUserParams';
import { setSnackBar, setStateSnackBar } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import WarningMessage from './Overview/WarningMessage';
import useQuery from '../../hooks/useQuery';

interface UserNotifications {
  notification_newsletter: boolean;
  notification_new_payment: boolean;
  notification_new_purchase: boolean;
}

interface Props {
  userNotifications: UserNotifications;
  setUserNotifications: (userNotifications: UserNotifications) => void;
}

const ContactOptionsUnsubscribe: FC<Props> = ({ userNotifications, setUserNotifications }) => {
  const route = useIonRouter();
  const dispatch = useDispatch();

  const params = useQuery();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [leaving, setLeaving] = useState<boolean>(false);

  const [patchUserNotifications, { isLoading: userNotifsLoading, isSuccess: userNotifsSuccess }] =
    usePatchUserNotificationsUnsubscribeMutation();

  useIonViewDidEnter(() => {
    setLeaving(false);
  });

  useIonViewDidLeave(() => {
    setLeaving(true);
  });

  const updateNotification = (value: string, option: boolean) => {
    const updateParams = {
      ...userNotifications,
      [value]: option,
    };

    patchUserNotifications({ ...updateParams, email: params.get('email') || '' });
    setUserNotifications(updateParams);
  };

  const getRow = (title: string, subtitle: string, value: string, switchBoolean: boolean) => {
    return (
      <Box width={'100%'} display={'flex'} gap={3} justifyContent={'space-between'}>
        <Box display={'flex'} flexDirection={'column'} gap={1} alignItems={'flex-start'}>
          <Typography variant={'subtitle1'} component="h4" color={'#313D53'}>
            {title}
          </Typography>
          <Typography color={'#313D53'} variant={'subtitle1SmallR'} component="p" align={'left'}>
            {subtitle}
          </Typography>
        </Box>
        <Box display={'flex'} textAlign={'end'} alignItems={'flex-start'} pt={0.5}>
          <PigogoSwitch
            inputProps={{ color: 'secondary' }}
            value={switchBoolean}
            setValue={() => updateNotification(value, !switchBoolean)}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box pb={mdDown ? '68px' : '264px'} pt={mdDown ? 1 : undefined}>
      <Box border="2px solid #EAECEE" borderRadius="24px">
        <Box textAlign="center">
          <>
            <Box display="flex" p={3} pb={0}>
              <Typography variant="body1" component="h2" textAlign="start" color={'#1D2532'}>
                Ρυθμίσεις επικοινωνίας
              </Typography>
            </Box>
            <Box display="flex" p={3} pt={'4px'}>
              <Typography variant="subtitle1SmallL" component="p" textAlign="start" color={'#313D53'}>
                Παρακάτω μπορείς να διαχειριστείς και τους υπόλοιπους τύπους ενημερώσεων που λαμβάνεις από το Pigogo.
              </Typography>
            </Box>
            <Divider sx={{ borderColor: '#EAECEE' }} />
          </>
          <Box
            display="flex"
            gap={smDown ? 3 : 2}
            flexDirection="column"
            padding={smDown ? '24px 16px 24px 16px' : '32px 56px 32px 24px'}
          >
            <Typography variant="mediumM" component="h3" textAlign="start" color={'#1D2532'}>
              Ενημερώσεις στο email σου
            </Typography>

            {getRow(
              'Newsletter',
              'Θα σου στέλνουμε όταν έχουμε αυξήσεις ποσοστών επιστροφής, νέα καταστήματα ή αποκλειστικά promos.',
              'notification_newsletter',
              userNotifications.notification_newsletter,
            )}
            <Divider sx={{ borderColor: '#EAECEE' }} />
            {getRow(
              'Καταγραφή αγοράς',
              'Θα σου στέλνουμε όταν καταγράφεται μια αγορά σου στο Pigogo.',
              'notification_new_purchase',
              userNotifications.notification_new_purchase,
            )}
            <Divider sx={{ borderColor: '#EAECEE' }} />
            {getRow(
              'Πληρωμή',
              'Θα σου στέλνουμε όταν πραγματοποιείται μία πληρωμή σου.',
              'notification_new_payment',
              userNotifications.notification_new_payment,
            )}
            <Divider sx={{ borderColor: '#EAECEE' }} />

            <Box
              gap={3}
              display={'flex'}
              justifyContent={'space-between'}
              flexDirection={smDown ? 'column' : 'row'}
              alignItems={smDown ? 'start' : 'flex-end'}
            >
              <Box gap={1} width={'100%'} display={'flex'} textAlign={'start'} flexDirection={'column'}>
                <Box>
                  <Typography variant={'subtitle1'} component="h4" color={'#313D53'}>
                    Αγαπημένα Καταστήματα
                  </Typography>
                </Box>
                <Box
                  gap={3}
                  display={'flex'}
                  justifyContent={'space-between'}
                  flexDirection={smDown ? 'column' : 'row'}
                  alignItems={'flex-start'}
                >
                  <Box flexShrink={1}>
                    <Typography variant={'subtitle1SmallR'} component="p" color={'#313D53'}>
                      Θα σου στέλνουμε όταν κάποιο από τα αγαπημένα σου καταστήματα έχει νέα προσφορά ή νέο κουπόνι
                      έκπτωσης.
                    </Typography>
                  </Box>
                  <Box display={'flex'} flexGrow={1} justifyContent={smDown ? 'flex-start' : 'flex-end'}>
                    <Typography
                      color={'#3C68C8'}
                      whiteSpace={'nowrap'}
                      variant={'subtitle1SmallM'}
                      component="button"
                      sx={{
                        backgroundColor: 'transparent',
                        textUnderlineOffset: '5px',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      p={0}
                      onClick={() => route.push('/favourites')}
                    >
                      Επεξεργασία αγαπημένων
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ContactOptionsUnsubscribe;
