import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  getPlatforms,
  isPlatform,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import {
  Box,
  ClickAwayListener,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material';
import SearchTextField from '../components/SearchTextField';
import { PigogoButton, PigogoRecentsCarousel, theme } from 'components';
import SearchContentMobile from '../components/SearchMobile/SearchContentMobile';
import { ReactComponent as Close } from '../assets/svgs/close.svg';
import { ReactComponent as LinkVertical } from '../assets/svgs/link_Vertical.svg';
import { ReactComponent as Search } from '../assets/svgs/search.svg';
import { useLazySearchQuery, useSearchQuery } from '../redux/api/queries/searchQueries';
import useDebounce from '../hooks/useDebounce';
import { getRecentlyViewed } from '../utils/viewedRecently';
import { Shop } from '../models/Shop';
import { useGetUserDataQuery } from '../redux/api/queries/userQueries';
import { useAppSelector } from '../redux/hooks';
import { setFirstLogin } from '../redux/slices/authenticationSlice';
import { setSnackBar } from '../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import StartStepsAlert from '../components/Home/StartStepsAlert';
import WalkthroughModal from '../components/Navigation/Modals/WalkthroughModal';
import { useHistory } from 'react-router';
import useQuery from '../hooks/useQuery';
import { Helmet } from 'react-helmet-async';

const recentlyViewed = true;

const StyleBox = styled(Box)(() => ({
  boxSizing: 'border-box',
  display: 'inline-flex',
  position: 'relative',
  borderRadius: '8px',
  background: 'none',
  height: '48px',
  width: '88px',
}));

const StyleBoxFillImg = styled(Box)(() => ({
  position: 'absolute',
  zIndex: '99',
  top: 0,
  right: 0,
  height: '100%',
  width: '100%',
}));

const StoreSearchMobile = () => {
  const desktop = isPlatform('desktop');

  const dispatch = useDispatch();

  const router = useIonRouter();
  const params = useQuery();

  const { data: userData } = useGetUserDataQuery();

  const [viewRecently, setViewRecently] = useState<{ shop: Shop; createdAt: string }[]>([]);
  const [search, setSearch] = useState<string>(params.get('search') ?? '');
  const [link, setLink] = useState<boolean>(false);

  const debouncedSearch = useDebounce(search, 500);

  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));

  const [startStep, setStartStep] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isXClicked, setIsXClicked] = useState<boolean>(false);
  const [walkthrough, setWalkthrough] = useState<boolean>(false);

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const inputRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewDidEnter(() => {
    if (localStorage.getItem('tab-changed') === '1') {
      contentRef.current && contentRef.current.scrollToTop();
    } else {
      setTimeout(() => {
        history.action !== 'PUSH' &&
          contentRef.current &&
          contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);
      }, 10);
    }
    localStorage.removeItem('search-parameters');
    localStorage.removeItem('scroll-positions');
    localStorage.removeItem('tab-changed');
  });

  const isFirst = useAppSelector((state) => state.authentication.data.firstLogin);

  const getTopPaper = () => {
    if (viewRecently.length !== 0 && !isFocused && !link && debouncedSearch === '') {
      return 96;
    } else return 0;
  };

  const [searchFetch, { isFetching, data: searchResults }] = useLazySearchQuery();

  useEffect(() => {
    if (debouncedSearch) {
      searchFetch({
        limit: debouncedSearch !== '' ? 6 : undefined,
        keyword: debouncedSearch,
      });
    }
  }, [debouncedSearch]);

  useIonViewDidEnter(() => {
    contentRef.current && contentRef.current.scrollToTop();

    setIsFocused(false);

    if (isFirst) {
      setTimeout(() => {
        setStartStep(true);
        dispatch(setFirstLogin({ isFirst: false }));
      }, 10000);
    }
  });

  useIonViewWillLeave(() => {
    setIsFocused(false);
  });

  useEffect(() => {
    if (userData && userData.id) {
      const recent: { shop: Shop; createdAt: string }[] = getRecentlyViewed(userData.id);
      setViewRecently(recent);
    }
  }, []);

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const getRecentlyStores = () => {
    if (viewRecently.length !== 0 && !link && debouncedSearch === '') {
      return (
        <Box>
          <Box px={2} py={1}>
            <Typography variant="subtitle1SmallM" component="h3" color={'#313D53'}>
              Είδες πρόσφατα
            </Typography>
          </Box>
          <Box>
            <PigogoRecentsCarousel>
              {viewRecently.map((item, index) => (
                <Box sx={{ height: '48px' }} key={index} onClick={() => router.push(`/${item.shop.slug.name}`)}>
                  <StyleBox>
                    <StyleBoxFillImg>
                      <img
                        alt={'logo-image'}
                        src={item.shop.image}
                        style={{ width: '100%', height: '100%', borderRadius: '8px', background: '#ffffff' }}
                      />
                    </StyleBoxFillImg>
                  </StyleBox>
                </Box>
              ))}
            </PigogoRecentsCarousel>
          </Box>
        </Box>
      );
    }
  };

  const handleClickAway = (event: any) => {
    if (!event?.target.classList.contains('focus-on-search')) {
      setIsFocused(false);
    }
    return;
  };

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar
          style={{
            '--background': '#E5E5E5',
            '--padding-start': 0,
            '--padding-end': 0,
            '--padding-bottom': 0,
            paddingTop: '0 + --ion-safe-area-top',
          }}
        >
          <Typography color="primary" variant="body2" p={2} component="h2" sx={{ textAlign: 'center' }}>
            Καταστήματα
          </Typography>
          <Box px={2} pt={0} pb={1}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <form autoComplete="false">
                <input
                  type="text"
                  readOnly
                  name="prevent_autofill"
                  id="prevent_autofill"
                  value=""
                  style={{ display: 'none' }}
                />
                <SearchTextField
                  sx={{
                    zIndex: 10000000,
                    background: 'white',
                    borderRadius: '30px',
                    '& .MuiOutlinedInput-root': {
                      paddingTop: '16px',
                      paddingBottom: '16px',
                      minHeight: '64px',
                      gap: 1,
                    },
                    '& .MuiOutlinedInput-input': {
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '1.42',
                    },
                    '& .MuiIconButton-edgeEnd': {
                      marginRight: 0,
                      padding: '4px',
                      right: 8,
                    },
                  }}
                  inputRef={inputRef}
                  value={search}
                  onChange={handleValueChange}
                  onClick={(event) => {
                    if (event.currentTarget.classList.contains('Mui-focused')) {
                      setIsFocused(false);
                    } else {
                      setIsFocused(true);
                      setIsXClicked(false);
                    }
                  }}
                  autoComplete="off"
                  fullWidth
                  placeholder="αναζήτησε κατάστημα, κατηγορία..."
                  InputProps={{
                    endAdornment: (
                      <>
                        {(isFocused || search) && !isXClicked && (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={(event: any) => {
                                event.stopPropagation();
                                setIsXClicked(true);
                                setSearch('');
                                inputRef.current?.blur();
                              }}
                            >
                              <Close />
                            </IconButton>
                          </InputAdornment>
                        )}
                        <InputAdornment position="start">
                          <IconButton aria-label="toggle link" edge="end" onClick={() => setLink(!link)}>
                            <LinkVertical />
                          </IconButton>
                        </InputAdornment>
                      </>
                    ),
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <Icon>
                            <Search />
                          </Icon>
                        </InputAdornment>
                      </>
                    ),
                  }}
                />
              </form>
            </ClickAwayListener>
          </Box>
        </IonToolbar>
      </IonHeader>
      {location.pathname === '/search-stores' && (
        <Helmet>
          <title>Αποτελέσματα αναζήτησης | Pigogo - Επιστροφή & προσφορές</title>
          <meta
            name="description"
            content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
          />
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      <IonContent
        className={'focus-on-search'}
        forceOverscroll={false}
        scrollY={false}
        ref={contentRef}
        style={{ '--ion-background-color': '#E5E5E5' }}
      >
        {!isFocused && getRecentlyStores()}

        <Paper
          sx={{
            borderTopLeftRadius: '32px',
            borderTopRightRadius: '32px',
            borderBottomRightRadius: '0px',
            borderBottomLeftRadius: '0px',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            top: getTopPaper(),
            p: '16px 16px 8px',
          }}
          className={'focus-on-search'}
        >
          <SearchContentMobile
            searchResultsLoading={isFetching}
            search={debouncedSearch}
            link={link}
            setLink={setLink}
            searchResults={searchResults}
            searchFocused={isFocused && !isXClicked}
          />
        </Paper>
        {(mobile || tablet) && (
          <StartStepsAlert
            open={startStep}
            setOpen={setStartStep}
            title="Δες πόσο εύκολα μπορείς να κερδίζεις επιστροφή χρημάτων."
            actionButton={
              <PigogoButton
                fullWidth
                text="Ας ξεκινήσουμε"
                variation="white"
                onClick={() => {
                  setStartStep(false);
                  setWalkthrough(true);
                }}
              />
            }
          />
        )}
        {(mobile || tablet) && (
          <WalkthroughModal
            open={walkthrough}
            handleClose={() => {
              setWalkthrough(false);
            }}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default StoreSearchMobile;
