import React, { useCallback, useEffect, useState } from 'react';
import { IonIcon, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, getPlatforms, useIonRouter } from '@ionic/react';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import favourite from '../assets/svgs/tabs/favourite.svg';
import information from '../assets/svgs/tabs/information.svg';
import pigogo from '../assets/svgs/tabs/pigogo.svg';
import profile from '../assets/svgs/tabs/profile.svg';
import stores from '../assets/svgs/tabs/stores.svg';
import informationActive from '../assets/svgs/tabs/active/information.svg';
import favouriteActive from '../assets/svgs/tabs/active/favourite.svg';
import profileActive from '../assets/svgs/tabs/active/profile.svg';
import storesActive from '../assets/svgs/tabs/active/stores.svg';
import pigogoActive from '../assets/svgs/tabs/active/pigogo.svg';
import Home from '../pages/Home';
import Favourites from '../pages/Favourites';
import Information from '../pages/Information';
import { makeStyles } from '@mui/styles';
import StoreSearchMobile from '../pages/StoreSearchMobile';
import { useMediaQuery } from '@mui/material';
import { PigogoButton, theme } from 'components';
import UserLayout from '../components/User/UserLayout';
import { useAppSelector } from '../redux/hooks';
import HomePreLogin from '../pages/HomePrelogin';
import Stores from '../pages/StoresListing/Stores';
import SearchStores from '../pages/StoresListing/SearchStores';
import Offers from '../pages/Offers';
import ContactForm from '../pages/ContactForm';
import FAQPage from '../pages/FAQPage';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';
import ReturnTips from '../pages/ReturnTips';
import { isWeb } from '../utils/device';
import Intro from '../pages/Intro';
import { useLogoutMutation } from '../redux/api/mutations/loginMutations';
import CreateClaim from '../pages/CreateClaim';
import PageNotFound from '../pages/PageNotFound';
import MiddlePage from '../pages/MiddlePage';
import NotLoggedInLayout from '../pages/NotLoggedInLayout';
import Privacy from '../pages/Privacy';
import MobileApp from '../pages/MobileApp';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import Alert from '../pages/Alert';
import AlertDownload from '../pages/AlertDownload';
import UnsubscribePage from '../pages/UnSubscribePage';
import BasicContent from '../pages/BasicContent';
import HowItWorks from '../pages/HowItWorksPage';
import Gamification from '../pages/Gamification';
import { usePromosQuery } from '../redux/api/queries/promosQueries';
import useQuery from '../hooks/useQuery';

const useStyles = makeStyles(() => ({
  tabSelectable: {
    borderTop: 'none',
    height: '72px',
    boxShadow: '0px -4px 24px rgba(49, 61, 83, 0.08)',
    '---background': '#FFFFFF',
  },
}));

const TabRoutes = () => {
  const classes = useStyles();

  const platforms = getPlatforms();
  const ionRouter = useIonRouter();
  const path = ionRouter.routeInfo.pathname;
  const previous = ionRouter.routeInfo.lastPathname;

  const web = isWeb();

  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedTab, setSelectedTab] = useState<string>('home');

  const location = useLocation();

  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [signupModal, setSignupModal] = useState<boolean>(false);

  const query = useQuery();

  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
  const {
    data: promos,
    isError: errorPromos,
    status: statusPromo,
  } = usePromosQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const [cookies, setCookies] = useState<boolean>(true);

  const [logout] = useLogoutMutation();

  const history = useHistory();

  const displayTabBar = useCallback(() => {
    if (
      !smallScreen ||
      path === '/intro' ||
      path === '/return-tips' ||
      path === '/create-claim' ||
      path === '/unsubscribe'
    ) {
      return true;
    } else if (smallScreen) {
      return false;
    }
  }, [smallScreen, path]);

  useEffect(() => {
    if (path !== location.pathname) {
      const pathname = location.pathname;
      const search = location.search.substring(1);
      const completePathname = `${pathname}` + search ? `?${search}` : '';
      history.replace(pathname ? completePathname : '');
    }
  }, [path]);

  useEffect(() => {
    if (!!accessToken || sessionLoggedIn) {
      if (query.get('redirectTo') && !query.get('redirectTo')?.includes('http')) {
        history.push(`/${query.get('redirectTo')}`);
      } else if (query.get('redirectTo') && query.get('redirectTo')?.includes('http')) {
        window.location.replace(query.get('redirectTo') ?? '');
      }
    }
  }, [accessToken, sessionLoggedIn]);

  useEffect(() => {
    if (query.get('login') === '' || query.get('login') === 'true') {
      setLoginModal(true);
    } else {
      setLoginModal(false);
    }

    if (query.get('signup')) {
      setSignupModal(true);
    } else {
      setSignupModal(false);
    }
  }, [query]);

  const handleLogout = async () => {
    await logout({});
    window.location.replace('/');
  };

  const findRootPage = () => {
    if (!web && !(!!accessToken || sessionLoggedIn)) {
      return <Redirect to={'/intro'} />;
    }

    return findHomeLanding();
  };

  const findHomeLanding = () => {
    if (!web && !(!!accessToken || sessionLoggedIn)) {
      return <Redirect to={'/intro'} />;
    }

    return !!accessToken || sessionLoggedIn ? (
      path === location.pathname ? (
        <Home handleRedir={() => setPasswordChanged(false)} passwordChanged={passwordChanged} />
      ) : undefined
    ) : path === location.pathname ? (
      <HomePreLogin
        openLoginModal={loginModal}
        openRegisterModal={signupModal}
        loggedInLoading={false}
        showCookies={cookies}
        initializeCookies={setCookies}
      />
    ) : undefined;
  };

  const findIntro = () => {
    if (!web && !(!!accessToken || sessionLoggedIn)) {
      return path === location.pathname ? <Intro /> : undefined;
    }

    return <Redirect to={'/'} />;
  };

  const findPromoPage = () => {
    const promoLabel = path.split('/').slice(-1)[0];
    if (promos !== undefined && !errorPromos) {
      const promo = promos.data.find((obj) => obj.label === promoLabel);

      if (statusPromo === 'fulfilled' && path.includes('/promo')) {
        if (promo === undefined) {
          return path === location.pathname ? <PageNotFound /> : undefined;
        } else {
          return path === location.pathname ? promo?.promo_type_id === 1 ? <Stores /> : <Offers /> : undefined;
        }
      }
    }
  };

  const handleSearch = () => {
    history.action === 'PUSH' && history.location.pathname === '/search' && localStorage.removeItem('search');
    return path === location.pathname ? <SearchStores /> : undefined;
  };

  const handleStores = () => {
    history.action === 'PUSH' && history.location.pathname === '/stores' && localStorage.removeItem('stores');
    return path === location.pathname ? <Stores /> : undefined;
  };

  const tabClicked = (newTab: string) => {
    if (selectedTab !== newTab) {
      localStorage.setItem('tab-changed', '1');

      if (selectedTab === 'stores' || newTab === 'stores') {
        localStorage.setItem('reset-menu-scroll', '1');
      }
    }
  };

  useEffect(() => {
    const userRegex = new RegExp('^(\\/user)(\\/\\S+)+$');
    const authRegex = new RegExp('^(\\/authenticate)(\\/\\S+)+$');

    switch (path) {
      case '/404-not-found':
        setSelectedTab('');
        break;
      case '/':
        setSelectedTab('home');
        break;
      case '/search-stores':
      case '/offers':
      case '/promo':
      case '/stores':
      case '/search':
        setSelectedTab('stores');
        break;
      case '/favourites':
        setSelectedTab('favourites');
        break;
      case '/information':
      case '/faq':
      case '/contact':
      case '/terms':
      case '/privacy':
        setSelectedTab('information');
        break;
      case '/tell-a-friend':
        setSelectedTab('profile');
        break;
      default:
        if (authRegex.test(path)) {
          if (previous === '/favourites') {
            setSelectedTab('favourites');
          } else if (previous === '/user/personal-info') {
            setSelectedTab('profile');
          }
          break;
        }
        if (userRegex.test(path)) {
          setSelectedTab('profile');
        } else {
          setSelectedTab('stores');
        }
        break;
    }
  }, [path]);

  return (
    <IonTabs>
      <IonRouterOutlet animated={false}>
        <Route exact path="/">
          {findRootPage()}
        </Route>

        <Route exact path="/intro">
          {findIntro()}
        </Route>

        <Route path="/promo">{findPromoPage()}</Route>

        <Route path="/authenticate" component={path === location.pathname ? NotLoggedInLayout : undefined} />

        {/* Here, we do not want to check if path === location.pathname, as it causes some transition flickering
            on responsive. (This check is done to avoid loading the previous route when clicking 'forward' on
            desktop.) */}
        <Route exact path="/search-stores" component={StoreSearchMobile} />

        <Route exact path="/create-claim">
          {!!accessToken || sessionLoggedIn ? (
            path === location.pathname ? (
              <CreateClaim />
            ) : undefined
          ) : (
            <Redirect to={'/?login&redirectTo=create-claim'} />
          )}
        </Route>
        <Route path="/user">
          {!!accessToken || sessionLoggedIn ? (
            path === location.pathname ? (
              <UserLayout />
            ) : undefined
          ) : (
            <Redirect to={`/?login&redirectTo=${(previous || '').replace(/^./, '')}`} />
          )}
        </Route>
        <Route exact path="/favourites">
          {!!accessToken || sessionLoggedIn ? (
            path === location.pathname ? (
              <Favourites />
            ) : undefined
          ) : (
            <Redirect to={'/?login&redirectTo=favourites'} />
          )}
        </Route>
        <Route exact path="/pages/:contentId" component={path === location.pathname ? BasicContent : undefined} />
        <Route exact path="/faq" component={path === location.pathname ? FAQPage : undefined} />
        <Route exact path="/stores">
          {handleStores()}
        </Route>
        <Route exact path="/offers" component={path === location.pathname ? Offers : undefined} />
        <Route exact path="/search">
          {handleSearch()}
        </Route>
        <Route exact path="/contact" component={path === location.pathname ? ContactForm : undefined} />
        <Route exact path="/return-tips" component={path === location.pathname ? ReturnTips : undefined} />
        <Route exact path="/terms" component={path === location.pathname ? TermsAndConditionsPage : undefined} />
        <Route exact path="/privacy" component={path === location.pathname ? Privacy : undefined} />
        <Route exact path="/mobile-app" component={path === location.pathname ? MobileApp : undefined} />
        <Route exact path="/alert" component={path === location.pathname ? Alert : undefined} />
        <Route exact path="/alert-download" component={path === location.pathname ? AlertDownload : undefined} />
        <Route exact path="/unsubscribe" component={path === location.pathname ? UnsubscribePage : undefined} />
        <Route exact path="/how-it-works" component={path === location.pathname ? HowItWorks : undefined} />
        <Route exact path="/gamification" component={path === location.pathname ? Gamification : undefined} />
        <Route exact path="/reset-password">
          {!!accessToken || sessionLoggedIn ? (
            <Redirect to={'/'} />
          ) : path === location.pathname ? (
            <ResetPasswordPage handleRedir={() => setPasswordChanged(true)} />
          ) : undefined}
        </Route>
        <Route exact path="/information">
          {path === location.pathname ? (
            <Information hasLogOut={!!accessToken || sessionLoggedIn} handleLogout={handleLogout} />
          ) : undefined}
        </Route>

        <Route path="/404-not-found" component={path === location.pathname ? PageNotFound : undefined} />

        <Route component={path === location.pathname ? MiddlePage : undefined} />
      </IonRouterOutlet>

      <IonTabBar slot="bottom" className={classes.tabSelectable} hidden={displayTabBar()}>
        <IonTabButton onClick={() => tabClicked('home')} tab="home" href={'/'}>
          <IonIcon icon={selectedTab === 'home' ? pigogoActive : pigogo} />
        </IonTabButton>
        <IonTabButton onClick={() => tabClicked('stores')} tab="stores" href="/search-stores">
          <IonIcon icon={selectedTab === 'stores' ? storesActive : stores} />
        </IonTabButton>
        <IonTabButton onClick={() => tabClicked('profile')} tab="profile" href="/user/overview">
          <IonIcon icon={selectedTab === 'profile' ? profileActive : profile} />
        </IonTabButton>
        <IonTabButton onClick={() => tabClicked('favourites')} tab="favourites" href="/favourites">
          <IonIcon icon={selectedTab === 'favourites' ? favouriteActive : favourite} />
        </IonTabButton>
        <IonTabButton onClick={() => tabClicked('information')} tab="information" href="/information">
          <IonIcon icon={selectedTab === 'information' ? informationActive : information} />
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default TabRoutes;
