import React, { FC, useEffect, useState } from 'react';
import { Box, Dialog, IconButton, styled, Typography, useMediaQuery } from '@mui/material';
import { IonHeader, IonModal, IonToolbar } from '@ionic/react';
import { ReactComponent as Close } from '../../../assets/svgs/close.svg';
import { PigogoButton, PigogoTextField, theme } from 'components';
import { useForgotPasswordMutation } from '../../../redux/api/mutations/loginMutations';
import { emailValid } from '../../../utils/validation';
import { useDispatch } from 'react-redux';
import { setSnackBar } from '../../../redux/slices/layoutSlice';

interface Props {
  isOpen: boolean;
  success: (values: boolean) => void;
  setOpen: (value: boolean) => void;
}

const ForgotModal: FC<Props> = ({ isOpen, setOpen, success }) => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const [forgotPassword, result] = useForgotPasswordMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!result.isLoading && result.isSuccess) {
      success(true);

      setEmail('');
      setOpen(false);
      setError(false);
    } else if (!result.isLoading && result.isError) {
      if ((result.error as any).data?.message.includes('Facebook, Google')) {
        dispatch(setSnackBar({ value: true, msg: (result.error as any).data?.message, severity: 'error' }));
      } else {
        setError(true);
      }
    }
  }, [result]);

  const handleForgot = () => {
    if (email) {
      forgotPassword({ email: email });
    }
  };

  const closeModal = () => {
    setOpen(false);
    setEmail('');
  };

  const isDisabled = () => {
    return !email || !emailValid(email);
  };

  return (
    <IonModal
      mode={'ios'}
      style={
        !smDown
          ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' }
          : { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px', paddingInline: '16px' }
      }
      isOpen={isOpen}
      canDismiss={true}
      onDidDismiss={() => closeModal()}
    >
      <IonHeader class="ion-no-border">
        <IonToolbar
          style={{
            '--background': '#FFFFFF',
            paddingTop: '12px',
            height: '48px',
            boxSizing: 'border-box',
            '--ion-safe-area-left': '-8px',
            '--ion-safe-area-right': '-8px',
          }}
        >
          <IconButton
            disableRipple
            className="iconBtn"
            sx={{
              position: 'absolute',
              right: 12,
              top: 0,
              padding: 0,
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => closeModal()}
          >
            <Close />
          </IconButton>
        </IonToolbar>
      </IonHeader>
      <Box className="overscroll innerPopup">
        <Box
          sx={{
            paddingInline: smDown ? '24px 12px' : '32px 20px',
            paddingBottom: smDown ? '8px' : '36px',
            paddingTop: smDown ? '0' : '16px',
          }}
        >
          <Box display={'flex'} flexDirection={'column'} gap={4}>
            <Box display={'flex'} flexDirection={'column'} textAlign={'center'} gap={1}>
              <Box>
                <Typography variant={'body1'} color={'#313D53'} component="h2">
                  Ξέχασα τον κωδικό
                </Typography>
              </Box>

              <Box>
                <Typography variant={'body2MediumR'} color={'#313D53'} component="p">
                  Συμπλήρωσε το email με το οποίο έχεις γραφτεί στο Pigogo, για να σου στείλουμε εκεί link δημιουργίας
                  νέου κωδικού.
                </Typography>
              </Box>
            </Box>

            <Box display={'flex'} flexDirection={'column'} gap={4}>
              <Box>
                <PigogoTextField
                  fullWidth
                  type={'email'}
                  label="Email"
                  value={email}
                  size={'medium'}
                  error={error}
                  helperText={error && 'Το email δεν είναι σωστό.'}
                  setValue={setEmail}
                  autoComplete={'email'}
                />
              </Box>

              <Box>
                <PigogoButton
                  fullWidth
                  type={'submit'}
                  text={'Αποστολή'}
                  variation="secondary"
                  onClick={handleForgot}
                  disabled={isDisabled()}
                  loading={result.isLoading}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </IonModal>
  );
};

export default ForgotModal;
