import { theme } from 'components';
import { ReactComponent as Close } from '../../../assets/svgs/close.svg';
import { ReactComponent as ChevronLeft } from '../../../assets/svgs/chevronLeft24.svg';
import { useAppDispatch } from '../../../redux/hooks';
import React, { FC, useEffect, useState } from 'react';
import RegisterForm from '../../authenticate/RegisterForm';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { useLoginMutation } from '../../../redux/api/mutations/loginMutations';
import { useCreateUserMutation } from '../../../redux/api/mutations/userMutations';
import { IonHeader, IonModal, IonToolbar, useIonRouter } from '@ionic/react';
import {
  setAccessToken,
  setFirstLogin,
  setRefreshToken,
  setSeenAlert,
  setSessionLoggedIn,
} from '../../../redux/slices/authenticationSlice';
import useQuery from '../../../hooks/useQuery';
import { Device } from '@capacitor/device';
import { isWeb } from '../../../utils/device';
import { setSnackBar } from '../../../redux/slices/layoutSlice';
import { SignInWithApple, SignInWithAppleResponse, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { createGenericEvent } from '../../../react-gtm-helpers';

interface Props {
  isOpen: boolean;
  setOpen: (b: boolean) => void;
  setLoginModal?: (loginModal: boolean) => void;
  userDismiss?: () => void;
  successCallback?: () => void;
  setTermsModal?: (val: boolean) => void;
}

const RegisterModal: FC<Props> = ({ isOpen, setOpen, setLoginModal, userDismiss, successCallback, setTermsModal }) => {
  const router = useIonRouter();
  const dispatch = useAppDispatch();
  const query = useQuery();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  const web = isWeb(); //const web = false;

  const [login, result] = useLoginMutation();
  const [createUser, { isLoading, isSuccess, isError, error, status }] = useCreateUserMutation();

  const [emailErrorMsg, setEmailErrorMsg] = useState<string>('');

  const [createdResource, setCreatedResource] = useState<boolean>(false);

  const [redirLoginModal, setRedirLoginModal] = useState<boolean>(false);

  const options: SignInWithAppleOptions = {
    clientId: 'gr.pigogo.appleclient',
    redirectURI: 'https://www.pigogo.gr/api/v1/login/apple/callback',
    scopes: 'email name',
  };

  useEffect(() => {
    if (isError) {
      const err = error as any;
      if (err.status !== 400) {
        setEmailErrorMsg('Υπάρχει ήδη εγγεγραμμένος χρήστης με αυτό το email! Χρησιμοποίησε τη φόρμα σύνδεσης.');
      } else if (err.status === 400) {
        setEmailErrorMsg('Το email δεν φαίνεται να είναι σωστό.');
      }
    } else {
      setEmailErrorMsg('');
    }
  }, [dispatch, error, isError, isLoading]);

  useEffect(() => {
    if (result.isSuccess) setOpen(false);
  }, [result.isSuccess]);

  useEffect(() => {
    if (isSuccess) setOpen(false);
  }, [isSuccess, setOpen]);

  const handleLoginModal = () => {
    setOpen(false);

    if (userDismiss && router.routeInfo.pathname === '/') {
      const redirectTo = query.get('redirectTo');
      router.push(`/?login${redirectTo ? '&redirectTo=' + redirectTo : ''}`);
    } else {
      setLoginModal && setLoginModal(true);
    }

    setRedirLoginModal(true);
  };

  const handleTermsClick = () => {
    if (!setTermsModal) {
      setOpen(false);
      router.push('/terms');
    } else {
      setTermsModal(true);
    }
  };

  const registerRequest = async (email: string, password: string) => {
    if (web) {
      const csrf: any = await fetch(process.env['REACT_APP_API_XSRF_TOKEN_URL'] ?? '', {
        credentials: 'include',
      });
    }
    const registerResponce: any = await createUser({ body: { email, password, signup_agree: true } });
    if (registerResponce && registerResponce.error) {
      return;
    }

    setTimeout(() => {
      if (registerResponce.data.resource_created) {
        setCreatedResource(true);
        dispatch(setFirstLogin({ isFirst: true }));
        dispatch(setSeenAlert({ seenAlert: false }));

        if (registerResponce.data.uid) {
          createGenericEvent('Successful Registration', {
            'User ID': registerResponce.data.uid,
          });
        }
      }

      if (web) {
        if (registerResponce?.data) {
          dispatch(setSessionLoggedIn({ sessionLoggedIn: true, rememberMe: true }));
        } else {
          dispatch(setSessionLoggedIn({ sessionLoggedIn: false }));
        }
      } else {
        dispatch(setAccessToken({ token: registerResponce?.data.access_token, rememberMe: true }));
        dispatch(setRefreshToken({ refresh_token: registerResponce?.data.refresh_token }));
      }
    }, 200);

    if (successCallback !== undefined) {
      successCallback();
    }
  };

  const handleRegister = (email: string, password: string) => {
    if (!email || !password) return;
    registerRequest(email, password);
  };

  const loginRequest = async (
    accessToken: string,
    type: string,
    email?: string,
    givenName?: string,
    familyName?: string,
  ) => {
    if (web) {
      const csrf: any = await fetch(process.env['REACT_APP_API_XSRF_TOKEN_URL'] ?? '', {
        credentials: 'include',
      });
    }
    const devName = (await Device.getId()).identifier;
    const loginResponse: any = await login({
      provider: type,
      rememberMe: true,
      social_access_token: accessToken,
      devName: devName,
      email: email,
      givenName: givenName,
      familyName: familyName,
    });
    if (loginResponse && loginResponse.error) {
      dispatch(setSnackBar({ value: true, msg: (loginResponse.error as any).data?.message, severity: 'error' }));
      return;
    }
    if (loginResponse && loginResponse.data && loginResponse.data.resource_created) {
      setTimeout(() => {
        setCreatedResource(true);
        dispatch(setFirstLogin({ isFirst: true }));
        dispatch(setSeenAlert({ seenAlert: false }));
      }, 200);

      if (loginResponse.data.uid) {
        createGenericEvent('Successful Registration', {
          'User ID': loginResponse.data.uid,
        });
      }
    }

    setOpen(false);
    if (web) {
      if (loginResponse?.data) {
        dispatch(setSessionLoggedIn({ sessionLoggedIn: true, rememberMe: true }));
      } else {
        dispatch(setSessionLoggedIn({ sessionLoggedIn: false }));
      }
    } else {
      if (loginResponse?.data) {
        setTimeout(() => {
          dispatch(setAccessToken({ token: loginResponse?.data.access_token, rememberMe: true }));
          dispatch(setRefreshToken({ refresh_token: loginResponse?.data.refresh_token }));
        }, 200);
      }
    }

    if (successCallback !== undefined) {
      successCallback();
    }
  };

  const handleFacebook = async () => {
    try {
      const result = await FacebookLogin.login({ permissions: ['public_profile', 'email'] });
      if (result.accessToken) {
        // Login successful.
        loginRequest(result.accessToken.token, 'facebook');
      }

      if (successCallback !== undefined) {
        successCallback();
      }
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const handleGoogle = async () => {
    try {
      const res = await GoogleAuth.signIn();
      if (res && res.authentication && res.authentication.accessToken) {
        const access = res.authentication.accessToken;
        const resp: any = await loginRequest(access, 'google');

        if (successCallback !== undefined) {
          successCallback();
        }
      }
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const handleApple = async () => {
    const res = SignInWithApple.authorize(options)
      .then(async (result: SignInWithAppleResponse) => {
        if (result.response && result.response.identityToken) {
          const idToken = result.response.identityToken;
          const appleId = result.response?.user ?? undefined;
          const familyName = result.response?.familyName ?? undefined;
          const givenName = result.response?.givenName ?? undefined;

          const resp: any = await loginRequest(idToken, 'apple', appleId, givenName, familyName);
          if (successCallback !== undefined) {
            successCallback();
          }
        }
      })
      .catch((error) => {
        // Handle error
        console.log(error);
        return;
      });
  };

  return (
    <IonModal
      mode={'ios'}
      isOpen={isOpen}
      canDismiss={true}
      style={!smDown ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' } : undefined}
      onDidDismiss={() => {
        if (userDismiss !== undefined && redirLoginModal) {
          setOpen && setOpen(false);
        } else if (userDismiss !== undefined) {
          userDismiss();
          if (router.routeInfo.pathname === '/') {
            router.push('/');
          }
        } else {
          successCallback && successCallback();
        }
      }}
    >
      <IonHeader class="ion-no-border">
        <IonToolbar
          style={{
            '--background': '#FFFFFF',
            paddingTop: '--ion-safe-area-top',
            marginTop: '12px',
            height: '24px',
            '--ion-safe-area-left': '-8px',
            '--ion-safe-area-right': '-8px',
          }}
        >
          <IconButton
            disableRipple
            className="iconBtn"
            sx={{
              position: 'absolute',
              right: 12,
              top: 0,
              padding: 0,
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => {
              if (userDismiss !== undefined) {
                userDismiss();
              } else {
                setOpen(!isOpen);
              }
            }}
          >
            <Close />
          </IconButton>
        </IonToolbar>
      </IonHeader>
      <Box className="overscroll innerPopup innerPopup--mobLarge overscroll--mobNoMTop">
        <Box
          display={'flex'}
          justifyContent={'center'}
          sx={{
            paddingInline: smDown ? '24px 12px' : '32px 20px',
            paddingTop: smDown ? '18px' : 0,
            paddingBottom: smDown ? 0 : '32px',
          }}
        >
          <RegisterForm
            loading={isLoading}
            header="Γίνε μέλος δωρεάν"
            headerDescription="Κέρδισε επιστροφή χρημάτων κάθε φορά
          που ψωνίζεις online"
            onClick={handleRegister}
            onClickGoogle={handleGoogle}
            onClickApple={() => handleApple()}
            emailErrorMsg={emailErrorMsg}
            onClickFacebook={handleFacebook}
            onClickSignIn={() => handleLoginModal()}
            onClickTerms={() => handleTermsClick()}
          />
        </Box>
      </Box>
    </IonModal>
  );
};

export default RegisterModal;
