import React, { useEffect, useState } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { PigogoButton, PigogoOfferCard, PigogoPromoCard, theme } from 'components';
import { useIonRouter } from '@ionic/react';
import { usePromosQuery } from '../../redux/api/queries/promosQueries';
import { useSavingsQuery } from '../../redux/api/queries/savingsQueries';
import { Promo } from '../../models/Promo';
import CustomContainer from '../CustomContainer';
import { useDispatch } from 'react-redux';
import { setSnackBar, setStateSnackBar } from '../../redux/slices/layoutSlice';
import { PigogoSingleCouponCard } from 'components';
import { useAppSelector } from '../../redux/hooks';
import { Link, useHistory } from 'react-router-dom';

const OffersSection = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const ssm = useMediaQuery(theme.breakpoints.only('ssm'));
  const ssmDown = useMediaQuery(theme.breakpoints.down('ssm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const { data: promos, isError: promoError } = usePromosQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const {
    data: savings,
    isFetching: loadSavings,
    isError: saveError,
  } = useSavingsQuery({ limit: 12, fillFeatured: true });

  const [offers, setOffers] = useState<any[] | undefined>(undefined);
  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const [isLoadingOffers, setIsLoadingOffers] = useState<boolean>(false);

  useEffect(() => {
    let finalData: any[] = [];
    let promo: any = [];

    if (savings) {
      finalData = savings.data.map((item) => {
        return {
          id: item.id,
          code: !item.is_offer && item.code ? item.code : undefined,
          logout: !(!!accessToken || sessionLoggedIn),
          logo: item.shop.image,
          name: item.shop.name,
          subTitle: item.description,
          cashbackCount: item.shop.cashback_categories_count,
          discount: item.shop.cashback !== null ? `${item.shop.cashback}${item.shop.currency}` : undefined,
          deleteDisc:
            item.shop.strikedCashback !== null ? `${item.shop.strikedCashback}${item.shop.strikedCurrency}` : undefined,
          date: item.end_date ? item.end_date : '',
          slugName: item.shop.slug.name,
          type: 'savings',
        };
      });
    }

    if (promos != undefined) {
      promo = {
        id: promos.data[0].id,
        code: undefined,
        url: promos.data[0].url,
        logout: !(!!accessToken || sessionLoggedIn),
        name: undefined,
        subTitle: undefined,
        cashbackCount: undefined,
        discount: undefined,
        deleteDisc: undefined,
        date: undefined,
        slugName: undefined,
        image: promos.data[0].image,
        color: promos.data[0].color,
        tile_title: promos.data[0].tile_title,
        call_to_action: promos.data[0].call_to_action,
        call_to_action_mobile: promos.data[0].call_to_action_mobile,
        type: 'promos',
      };

      finalData.splice(2, 0, promo);
      finalData.splice(10, 0, promo);
    }

    setOffers(finalData);
  }, [savings]);

  useEffect(() => {
    if (!!accessToken || sessionLoggedIn) {
      setIsLoadingOffers(true);
    }
  }, [accessToken, sessionLoggedIn]);

  useEffect(() => {
    if (offers && offers.length && !loadSavings) {
      setIsLoadingOffers(false);
    }
  }, [offers]);

  useEffect(() => {
    if (loadSavings) {
      setIsLoadingOffers(true);
    } else {
      setTimeout(() => {
        setIsLoadingOffers(false);
      }, 1500);
    }
  }, [loadSavings]);

  useEffect(() => {
    if (promoError || saveError) {
      const params = { value: true, msg: 'Κάτι πήγε στραβά με τις προσφορές!', severity: 'error' };
      dispatch(setSnackBar(params));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [promoError, saveError]);

  const onClickCopy = () => {
    const params = { value: true, msg: 'Αντιγράφηκε στο πρόχειρο', severity: 'infoBlue' };
    dispatch(setSnackBar(params));
  };

  const getCols = () => {
    if (ssmDown) {
      return 'repeat(auto-fit,calc(50% - 4px))';
    }
    if (mdDown) {
      return 'repeat(auto-fit,calc(25% - 12px))';
    }
    if (lgDown) {
      return 'repeat(auto-fit,calc(25% - 12px))';
    }
    if (mdDown) {
      return 'repeat(auto-fit,224px)';
    }

    return 'repeat(auto-fit,244px)';
  };

  const getOffersCard = () => {
    if (isLoadingOffers) {
      return [1, 1, 1, 1].map((item, index) => (
        <Box display={'flex'} key={index}>
          <PigogoOfferCard loading={true} />
        </Box>
      ));
    }
    if (!!offers && !isLoadingOffers) {
      return offers.map((item, index) => {
        if (index === 2 && promos !== undefined) {
          return (
            (smDown || sm || ssm) && (
              <Box key={index} display="flex" alignItems="center" justifyContent="center">
                <Link className="storeCard storeCard--offer" to={item.url}>
                  <PigogoPromoCard
                    lgText
                    loading={false}
                    logo={item.image}
                    color={item.color}
                    title={item.tile_title}
                    call_to_action={!mdDown ? item.call_to_action : item.call_to_action_mobile}
                  />
                </Link>
              </Box>
            )
          );
        } else if (index === 10 && promos !== undefined) {
          return (
            !smDown &&
            !sm &&
            !ssm && (
              <Box key={index} display="flex" alignItems="center" justifyContent="center">
                <Link className="storeCard storeCard--offer" to={item.url}>
                  <PigogoPromoCard
                    lgText
                    loading={false}
                    logo={item.image}
                    color={item.color}
                    title={item.tile_title}
                    call_to_action={!mdDown ? item.call_to_action : item.call_to_action_mobile}
                  />
                </Link>
              </Box>
            )
          );
        } else if (item.code) {
          return (
            <Box className="storeCardBox" key={index} display="flex" alignItems="center" justifyContent="center">
              <Link className="storeCard storeCard--abs" to={`/${item.slugName}?offer=${item.id}`}></Link>
              <PigogoSingleCouponCard
                code={item.code}
                date={item.date}
                logo={item.logo}
                name={item.name}
                logout={item.logout}
                subTitle={item.subTitle}
                discount={item.discount}
                cashbackCount={item.cashbackCount}
                deleteDisc={item.deleteDisc}
                setLogin={() => {
                  return;
                }}
                //onClick={() => router.push(`/${item.slugName}?offer=${item.id}`)}
                onClickCopy={onClickCopy}
                storeSlug={item.slugName}
                id={item.id}
              />
            </Box>
          );
        } else {
          return (
            <Box className="storeCardBox" key={index} display="flex" alignItems="center" justifyContent="center">
              <Link className="storeCard" to={`/${item.slugName}?offer=${item.id}`}>
                <PigogoOfferCard
                  logo={item.logo}
                  name={item.name}
                  date={item.date}
                  subTitle={item.subTitle}
                  discount={item.discount}
                  multiCashback={item.cashbackCount >= 2}
                  deleteDisc={item.deleteDisc}
                  //onClick={() => router.push(`/${item.slugName}?offer=${item.id}`)}
                />
              </Link>
            </Box>
          );
        }
      });
    }
  };

  return (
    <CustomContainer>
      <Box
        gap={smDown ? 3 : 6}
        display="flex"
        pb={smDown ? 4 : 6}
        py={smDown ? 4 : 10}
        flexDirection="column"
        alignItems={smDown ? 'flex-start' : 'center'}
      >
        <Typography color={'#1D2532'} component="h2" variant={smDown ? 'subtitle1' : 'smallB'}>
          Μη χάσεις τις προσφορές
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: getCols(),
            gridGap: ssmDown ? '16px 8px' : '16px',
            justifyContent: 'center',
          }}
        >
          {getOffersCard()}
        </Box>

        <Box width={'327px'} maxWidth={'100%'} mx={'auto'}>
          <Link className="pigogoButton pigogoButton--secondary pigogoButton--fullwidth" to={'/offers'}>
            Όλες οι προσφορές
          </Link>
        </Box>
      </Box>
    </CustomContainer>
  );
};

export default OffersSection;
