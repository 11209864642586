import React, { FC } from 'react';
import { Box, Skeleton, styled, Typography, useMediaQuery } from '@mui/material';
import theme from '../theme';
import PigogoButton from './PigogoButton';

const StyleBoxFillImg = styled(Box)(() => ({
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 1,
  position: 'absolute',
}));

const StyleBoxFill = styled(Box)(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  position: 'relative',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('extraLg')]: {
    padding: theme.spacing(3, 2),
  },
  padding: theme.spacing(3),
}));

const StyleProductBoxFill = styled(Box)(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  height: '264px',
  position: 'relative',
  boxSizing: 'border-box',
  padding: theme.spacing(3),
  width: '100%',
  [theme.breakpoints.up('md')]: {
    height: '352px',
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    background: 'linear-gradient(180deg, #313D53 0%, rgba(49, 61, 83, 0) 100%)',
    borderRadius: '24px',
    zIndex: 2,
  },
}));

interface Props {
  logo?: string;
  color?: string | undefined;
  lgText?: boolean;
  loading?: boolean;
  title?: string;
  productType?: boolean;
  call_to_action?: string;
  onClick?: () => void;
}
const PigogoPromoCard: FC<Props> = ({ loading, lgText, logo, color, title, productType, onClick, call_to_action }) => {
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const getFillProductCard = () => {
    return <StyleProductBoxFill>{getContainerBox()}</StyleProductBoxFill>;
  };

  const getFillOfferCard = () => {
    return <StyleBoxFill>{getContainerBox()}</StyleBoxFill>;
  };

  const getContainerBox = () => {
    return (
      <Box display={'flex'} width={'100%'} onClick={onClick} sx={{ cursor: 'pointer' }}>
        <StyleBoxFillImg>
          {loading ? (
            <Skeleton animation={'wave'} style={{ background: '#EAECEE', borderRadius: '16px' }} />
          ) : logo !== null ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '24px',
                background: `linear-gradient(180deg, #313D53 0%, #313D5300 100%), url('${logo}') center no-repeat`,
                backgroundSize: 'cover',
              }}
            />
          ) : (
            <div
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '24px',
                objectFit: 'cover',
                background: `${color}`,
              }}
            />
          )}
        </StyleBoxFillImg>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          zIndex={2}
          height={'100%'}
          width={'100%'}
        >
          <Box
            maxWidth={'100%'}
            overflow={'hidden'}
            sx={{
              WebkitLineClamp: matches ? 6 : 7,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              width: '100%',
            }}
          >
            <Typography textAlign={'start'} variant={'h4'} component="h3" color={'#FFFFFF'} sx={{ wordWrap: 'normal' }}>
              {loading ? <Skeleton animation={'wave'} style={{ background: '#ffff', borderRadius: '16px' }} /> : title}
            </Typography>
          </Box>

          {/* <Box width={'100%'}>
            {loading ? (
              <Skeleton animation={'wave'} style={{ background: '#EAECEE', borderRadius: '16px' }} />
            ) : (
              call_to_action && (
                <PigogoButton
                  fullWidth
                  onClick={onClick}
                  variation={'secondary'}
                  sx={{ padding: '16px 8px', width: '100%' }}
                  text={call_to_action}
                />
              )
            )}
          </Box> */}
          <Box width={'100%'}>
            {loading ? (
              <Skeleton animation={'wave'} style={{ background: '#EAECEE', borderRadius: '16px' }} />
            ) : (
              call_to_action && (
                <Box className="pigogoButton pigogoButton--secondary pigogoButton--fullwidth">{call_to_action}</Box>
              )
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  return productType ? getFillProductCard() : getFillOfferCard();
};

export default PigogoPromoCard;
