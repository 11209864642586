import { Box, BoxProps, Grid, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import PigogoChip from './PigogoChip';
import { Fire } from '../assets';

const StyleBox = styled(Box)(() => ({
  boxSizing: 'border-box',
  display: 'inline-flex',
  borderRadius: '24px',
  background: '#8C3E53',
  width: '100%',
}));

interface Props {
  title?: string;
  subTitle: string;
  date?: string;
  hotOffer?: string;
  boxProps?: BoxProps;
  onClick: () => void;
}

const PigogoStorePromo: FC<Props> = ({ title, subTitle, date, hotOffer, boxProps, onClick }) => {
  return (
    <StyleBox {...boxProps}>
      <Box style={{ padding: 24, width: '100%', height: '100%', zIndex: '100' }}>
        <Grid container style={{ height: '100%' }}>
          {title && (
            <Grid container item xs={12} alignItems={'flex-start'}>
              {hotOffer && (
                <Grid container item xs={3} sm={2} md={4}>
                  <PigogoChip width={65} size={'small'} color={'secondary'} label={hotOffer} startIcon={<Fire />} />
                </Grid>
              )}
              <Grid item>
                <Typography variant={'body2'} style={{ color: '#F2A4B9' }}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container item xs={12} alignItems={'end'} justifyContent={'start'} style={{ paddingTop: 26 }}>
            <Grid
              item
              xs={12}
              sx={{
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitLineClamp: '5',
              }}
            >
              <Typography variant={'body1'} style={{ color: '#fff' }}>
                {subTitle}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 4 }}>
              <Typography variant={'subtitle1SmallR'} style={{ color: '#fff' }}>
                {date}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyleBox>
  );
};

export default PigogoStorePromo;
