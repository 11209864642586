import React, { FC, useEffect, useState } from 'react';
import { IonContent, IonHeader, IonModal, IonToolbar, useIonRouter } from '@ionic/react';
import { ReactComponent as SignupLogo } from '../../../assets/SignupLogo.svg';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import { PigogoLogin, theme } from 'components';
import { ReactComponent as Close } from '../../../assets/svgs/close.svg';
import { ReactComponent as ChevronLeft } from '../../../assets/svgs/chevronLeft24.svg';
import { useLazyObtainCsrfTokenQuery, useLoginMutation } from '../../../redux/api/mutations/loginMutations';
import { useAppDispatch } from '../../../redux/hooks';
import {
  setAccessToken,
  setFirstLogin,
  setRefreshToken,
  setSeenAlert,
  setSessionLoggedIn,
} from '../../../redux/slices/authenticationSlice';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { setSnackBar } from '../../../redux/slices/layoutSlice';
import { Router } from 'workbox-routing';
import useQuery from '../../../hooks/useQuery';
import Cookies from 'js-cookie';
import { isPlatform, getPlatforms } from '@ionic/react';
import { isWeb } from '../../../utils/device';
import { Device } from '@capacitor/device';
import { SignInWithApple, SignInWithAppleResponse, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { createGenericEvent } from '../../../react-gtm-helpers';

interface Props {
  isOpen: boolean;
  message?: string;
  dismiss: () => void;
  setLoginModal?: (val: boolean) => void;
  openForgotModal: () => void;
  openRegisterModal?: () => void;
  userDismiss?: () => void;
  footerMessage?: string;
  footerRedir?: () => void;
  footerRedirLink?: string;
}

const LoginModal: FC<Props> = ({
  isOpen,
  dismiss,
  message,
  openRegisterModal,
  openForgotModal,
  footerMessage,
  footerRedir,
  userDismiss,
  setLoginModal,
  footerRedirLink,
}) => {
  const dispatch = useAppDispatch();
  const router = useIonRouter();
  const query = useQuery();

  const [obtainCsrf, csrfResult] = useLazyObtainCsrfTokenQuery();

  const web = isWeb(); //const web = false;

  const [registerModal, setRegisterModal] = useState<boolean>(false);

  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [login, result] = useLoginMutation();

  const [socialError, setSocialError] = useState<boolean>(false);

  const options: SignInWithAppleOptions = {
    clientId: 'gr.pigogo.appleclient',
    redirectURI: 'https://www.pigogo.gr/api/v1/login/apple/callback',
    scopes: 'email name',
  };

  useEffect(() => {
    if (result.isSuccess) {
      dismiss();
    }
  }, [result.isSuccess, dismiss]);

  useEffect(() => {
    if (result.isError) {
      if ((result.error as any).data?.message) {
        if (((result.error as any).data?.message as string).includes('Facebook ή Google')) {
          setSocialError(true);
        } else {
          setSocialError(false);
          dispatch(setSnackBar({ value: true, msg: (result.error as any).data?.message, severity: 'error' }));
        }
      } else {
        dispatch(setSnackBar({ value: true, msg: 'Τα στοιχεία εισόδου δεν είναι σωστά.', severity: 'error' }));
      }
    } else {
      dispatch(setSnackBar({ value: false, msg: '' }));
    }
  }, [result.isError, dispatch]);

  const onLogin = async (email: string, password: string, rememberMe: boolean) => {
    if (!email || !password) return;

    if (web) {
      const csrf: any = await fetch(process.env['REACT_APP_API_XSRF_TOKEN_URL'] ?? '', {
        credentials: 'include',
      });
    }

    const devName = (await Device.getId()).identifier;

    const response: any = await login({ email, password, rememberMe, devName: devName });
    if (web) {
      if (response?.data) {
        dispatch(setSessionLoggedIn({ sessionLoggedIn: true, rememberMe: rememberMe }));
      } else {
        dispatch(setSessionLoggedIn({ sessionLoggedIn: false }));
      }
    } else {
      if (response?.data) {
        setTimeout(() => {
          dispatch(setAccessToken({ token: response?.data.access_token, rememberMe: rememberMe }));
          dispatch(setRefreshToken({ refresh_token: response?.data.refresh_token }));
        }, 200);
      }
    }
  };

  const loginRequest = async (
    accessToken: string,
    type: string,
    email?: string,
    givenName?: string,
    familyName?: string,
  ) => {
    if (web) {
      const csrf: any = await fetch(process.env['REACT_APP_API_XSRF_TOKEN_URL'] ?? '', {
        credentials: 'include',
      });
    }
    const devName = (await Device.getId()).identifier;
    const loginResponse: any = await login({
      provider: type,
      rememberMe: true,
      social_access_token: accessToken,
      devName: devName,
      email: email,
      givenName: givenName,
      familyName: familyName,
    });
    if (loginResponse && loginResponse.error) {
      dispatch(setSnackBar({ value: true, msg: (loginResponse.error as any).data?.message, severity: 'error' }));
      return;
    }
    if (loginResponse.data.resource_created) {
      dispatch(setFirstLogin({ isFirst: true }));
      dispatch(setSeenAlert({ seenAlert: false }));

      if (loginResponse.data.uid) {
        createGenericEvent('Successful Registration', {
          'User ID': loginResponse.data.uid,
        });
      }
    }
    if (web) {
      if (loginResponse?.data) {
        dispatch(setSessionLoggedIn({ sessionLoggedIn: true, rememberMe: true }));
      } else {
        dispatch(setSessionLoggedIn({ sessionLoggedIn: false }));
      }
    } else {
      if (loginResponse?.data) {
        setTimeout(() => {
          dispatch(setAccessToken({ token: loginResponse?.data.access_token, rememberMe: true }));
          dispatch(setRefreshToken({ refresh_token: loginResponse?.data.refresh_token }));
        }, 200);
      }
    }

    dismiss();
  };

  const handleFacebook = async () => {
    try {
      const result = await FacebookLogin.login({ permissions: ['public_profile', 'email'] });
      if (result.accessToken) {
        // Login successful.
        loginRequest(result.accessToken.token, 'facebook');
      }
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const handleGoogle = async () => {
    try {
      const res = await GoogleAuth.signIn();
      if (res && res.authentication && res.authentication.accessToken) {
        const access = res.authentication.accessToken;
        loginRequest(access, 'google');
      }
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const handleApple = async () => {
    const res = SignInWithApple.authorize(options)
      .then(async (result: SignInWithAppleResponse) => {
        if (result.response && result.response.identityToken) {
          const idToken = result.response.identityToken;
          const email = result.response?.email ?? undefined;
          const familyName = result.response?.familyName ?? undefined;
          const givenName = result.response?.givenName ?? undefined;

          const resp: any = await loginRequest(idToken, 'apple', email, givenName, familyName);
        }
      })
      .catch((error) => {
        // Handle error
        console.log(error);
        return;
      });
  };

  const handleRegisterModal = () => {
    if (setLoginModal) {
      setLoginModal(false);

      if (router.routeInfo.pathname === '/') {
        router.push(`/?signup`);
      } else {
        openRegisterModal && openRegisterModal();
      }
    } else {
      dismiss();

      openRegisterModal && openRegisterModal();
    }

    setRegisterModal(true);
  };

  const handleForgotModal = () => {
    setLoginModal && setLoginModal(false);
    openForgotModal();
  };

  return (
    <IonModal
      mode={'ios'}
      isOpen={isOpen}
      canDismiss={true}
      onDidDismiss={() => {
        if (userDismiss !== undefined && registerModal) {
          setLoginModal && setLoginModal(false);
        } else if (userDismiss !== undefined) {
          userDismiss();
        } else {
          dismiss();
        }
      }}
      style={!smDown ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' } : undefined}
    >
      <IonHeader class="ion-no-border">
        <IonToolbar
          style={{
            '--background': '#FFFFFF',
            paddingTop: '--ion-safe-area-top',
            marginTop: '12px',
            height: '24px',
            '--ion-safe-area-left': '-8px',
            '--ion-safe-area-right': '-8px',
          }}
        >
          <IconButton
            disableRipple
            className="iconBtn"
            sx={{
              position: 'absolute',
              right: 12,
              top: 0,
              padding: 0,
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => {
              if (userDismiss !== undefined) {
                userDismiss();
              } else {
                dismiss();
              }
            }}
          >
            <Close />
          </IconButton>
        </IonToolbar>
      </IonHeader>

      <Box className="overscroll innerPopup overscroll--mobNoMTop innerPopup--mobLarge">
        <Box
          display={'flex'}
          justifyContent={'center'}
          sx={{ paddingInline: smDown ? '24px 12px' : '32px 20px', paddingTop: smDown ? '18px' : 0 }}
        >
          <PigogoLogin
            loading={result.isLoading}
            headerImage={<SignupLogo />}
            header="Σύνδεση"
            message={message ? message : undefined}
            onClick={(email: string, password: string, rememberMe: boolean) => onLogin(email, password, rememberMe)}
            onClickFacebook={() => handleFacebook()}
            onClickGoogle={() => handleGoogle()}
            onClickApple={() => handleApple()}
            onClickSignUp={() => handleRegisterModal()}
            onClickForgot={() => handleForgotModal()}
            footerMessage={footerMessage ? footerMessage : undefined}
            footerRedir={footerRedir}
            setSocialError={setSocialError}
            socialError={socialError}
            footerRedirLink={footerRedirLink}
          />
        </Box>
      </Box>
    </IonModal>
  );
};

export default LoginModal;
