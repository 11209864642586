import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { Mutex } from 'async-mutex';
import { clearUser, setAccessToken } from '../slices/authenticationSlice';
import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { isWeb } from '../../utils/device';

const URL = process.env['REACT_APP_API_URL'];

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: URL,
  credentials: !Cookies.get('impersonateAccessToken') ? 'include' : undefined,
  prepareHeaders: async (headers, { getState }) => {
    const accessToken = (getState() as RootState).authentication.data.accessToken;
    if (accessToken && accessToken != undefined) {
      headers.append('Authorization', `Bearer ${accessToken}`);
    } else {
      headers.append('X-XSRF-TOKEN', decodeURIComponent(Cookies.get('XSRF-TOKEN') || ''));
    }
    headers.append('Accept', 'application/json');

    if (Cookies.get('impersonateAccessToken')) {
      headers.append('Impersonation', 'true');
    }

    return headers;
  },
  paramsSerializer: (params) =>
    Object.keys(params)
      .reduce((a: string[], k: string) => {
        if (params[k] !== undefined) a.push(k + '=' + params[k]);
        return a;
      }, [])
      .join('&'),
});

const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (isWeb()) {
      api.dispatch(clearUser());
      return result;
    }
    const refreshToken = (api.getState() as RootState).authentication.data.refreshToken;
    if (!refreshToken) {
      api.dispatch(clearUser());
    }
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult: AxiosResponse = await axios.post(
          URL + 'users/actions/refresh',
          {},
          {
            headers: { Authorization: `Bearer ${refreshToken}` },
          },
        );
        if (refreshResult) {
          api.dispatch(setAccessToken({ token: refreshResult.data.access_token }));
          result = await baseQuery(args, api, extraOptions);
          return result;
        } else {
          api.dispatch(clearUser());
        }
      } catch (e) {
        api.dispatch(clearUser());
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

// Define a service using a base URL and expected endpoints
export const pigogoApi = createApi({
  baseQuery: baseQueryWithReAuth as typeof baseQuery,
  refetchOnMountOrArgChange: true,
  reducerPath: 'pigogo',
  tagTypes: [
    'shops',
    'shop',
    'allCategories',
    'favourites',
    'purchases',
    'purchasesShops',
    'payments',
    'clickouts',
    'claims',
    'claimsShops',
    'promos',
    'savings',
    'search',
    'user',
    'tellAFriend',
  ],
  endpoints: () => ({}),
});
