import { AllShop } from '../../models/AllShop';
import { Box, Typography } from '@mui/material';
import { PigogoProductCard, PigogoPromoCard, theme } from 'components';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { usePromosQuery } from '../../redux/api/queries/promosQueries';
import { useIonRouter, useIonViewDidEnter } from '@ionic/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useHistory } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import useDebounce from '../../hooks/useDebounce';

const dummyArray = [1, 1, 1, 1, 1, 1, 1, 1];
interface Props {
  loading?: boolean;
  setLoading: (val: boolean) => void;
  loadingMore?: boolean;
  setLoadingMore: (val: boolean) => void;
  dataStores?: AllShop[];
  setFavourite?: (shop: AllShop, index: number) => void;
  favouriteStores?: number[];
  unfavouriteStores?: number[];
  isPerformingFavUnfavAction?: boolean;
  fetchedDataFromCache?: boolean;
}

const StoreCards: React.FC<Props> = ({
  loading,
  setLoading,
  dataStores,
  setFavourite,
  loadingMore,
  setLoadingMore,
  favouriteStores,
  unfavouriteStores,
  isPerformingFavUnfavAction = false,
  fetchedDataFromCache = false,
}) => {
  const router = useIonRouter();
  const path = router.routeInfo.pathname;

  const params = useQuery();

  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const { data: promosData } = usePromosQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const [loadArray, setLoadArray] = useState(dummyArray);
  const [initPage] = useState(params.get('page'));

  const history = useHistory();
  const [displayPromoTile] = useState<boolean>(
    !!(history.location.state as any)?.fetchPrevious || fetchedDataFromCache,
  );

  useEffect(() => {
    if (mdDown) {
      setLoadArray(dummyArray.slice(0, 4));
    } else {
      setLoadArray(dummyArray);
    }
  }, [mdDown]);

  useEffect(() => {
    setLoading(false);
    setLoadingMore(false);
  }, [dataStores]);

  const storeCards = () => {
    if (loading && !loadingMore && !isPerformingFavUnfavAction) {
      return loadArray.map((item, index) => (
        <Box
          key={index}
          sx={{
            width: {
              xs: 'calc(50% - 4px)',
              ssm: 'calc(25% - 6px)',
              md: 'calc(25% - 12px)',
            },
          }}
        >
          <PigogoProductCard
            loading={true}
            logout={!(!!accessToken || sessionLoggedIn)}
            handleClick={() => {
              return;
            }}
          />
        </Box>
      ));
    }

    if (dataStores && dataStores?.length > 0 && !loading) {
      return dataStores.map((shop: AllShop, index: number) => {
        return (
          <React.Fragment key={index}>
            <Box
              sx={{
                width: {
                  xs: 'calc(50% - 4px)',
                  ssm: 'calc(25% - 6px)',
                  md: 'calc(25% - 12px)',
                },
              }}
            >
              <a className="storeCard" href={`/${shop.slug.name}`}>
                <PigogoProductCard
                  shop={{
                    ...shop,
                    user_favourite: favouriteStores?.some((elem) => elem === shop?.id),
                  }}
                  loading={loading}
                  handleClick={(e: any) => {
                    e.preventDefault();
                    if (mdDown) {
                      const searchParams = JSON.parse(localStorage.getItem('search-parameters') ?? '{}');
                      searchParams[history.location.pathname] = history.location.search;
                      localStorage.setItem('search-parameters', JSON.stringify(searchParams));

                      const scrollPositions = JSON.parse(localStorage.getItem('scroll-positions') ?? '{}');
                      scrollPositions[history.location.pathname] = (history.location.state as any)?.scrollY ?? 0;
                      localStorage.setItem('scroll-positions', JSON.stringify(scrollPositions));
                    }
                    router.push(`/${shop.slug.name}`);
                  }}
                  setFavourite={() => handleSetFavourite(shop, index)}
                />
              </a>
            </Box>
            {index === 8 &&
              promosData !== undefined &&
              !path.includes('/promo') &&
              (initPage === '1' || !initPage || displayPromoTile) && (
                <Box
                  key={dataStores.length}
                  sx={{
                    width: {
                      xs: 'calc(50% - 4px)',
                      ssm: 'calc(25% - 6px)',
                      md: 'calc(25% - 12px)',
                    },
                  }}
                >
                  <Link
                    className="storeCard"
                    to={promosData.data[0].url}
                    onClick={(e: any) => {
                      if (mdDown) {
                        const searchParams = JSON.parse(localStorage.getItem('search-parameters') ?? '{}');
                        searchParams[history.location.pathname] = history.location.search;
                        localStorage.setItem('search-parameters', JSON.stringify(searchParams));

                        const scrollPositions = JSON.parse(localStorage.getItem('scroll-positions') ?? '{}');
                        scrollPositions[history.location.pathname] = (history.location.state as any)?.scrollY ?? 0;
                        localStorage.setItem('scroll-positions', JSON.stringify(scrollPositions));
                      }
                    }}
                  >
                    <PigogoPromoCard
                      productType
                      logo={promosData.data[0].image}
                      color={promosData.data[0].color}
                      title={promosData.data[0]?.tile_title}
                      call_to_action={
                        !mdDown ? promosData.data[0].call_to_action : promosData.data[0].call_to_action_mobile
                      }
                    />
                  </Link>
                </Box>
              )}
          </React.Fragment>
        );
      });
    }
  };

  const loadingMoreSkeleton = () => {
    if (loadingMore) {
      return loadArray.map((item, index) => (
        <Box
          key={index}
          sx={{
            width: {
              xs: 'calc(50% - 4px)',
              ssm: 'calc(25% - 6px)',
              md: 'calc(25% - 12px)',
            },
          }}
        >
          <PigogoProductCard loading={true} logout={!(!!accessToken || sessionLoggedIn)} />
        </Box>
      ));
    }
  };
  const handleSetFavourite = (shop: AllShop, index: number) => {
    if ((!!accessToken || sessionLoggedIn) && setFavourite) {
      setFavourite(shop, index);
    }
  };

  return (
    <Box
      paddingTop={3}
      display={'flex'}
      flexWrap={'wrap'}
      gap={mdDown ? 1 : 2}
      alignItems="flex-start"
      paddingBottom={6}
      justifyContent="flex-start"
      minHeight={mdDown ? '400px' : '792px'}
    >
      {storeCards()}
      {loadingMoreSkeleton()}
    </Box>
  );
};

export default StoreCards;
