import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { PigogoHorizontalProductCard, theme } from 'components';
import { getRecentlyViewed, reviewViewedRecentlyStorage } from '../../../../utils/viewedRecently';
import { useIonRouter } from '@ionic/react';
import { Shop } from '../../../../models/Shop';
import { useGetUserDataQuery } from '../../../../redux/api/queries/userQueries';
import { Link } from 'react-router-dom';

const Suggested = () => {
  const router = useIonRouter();

  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));

  const { data: userData } = useGetUserDataQuery();

  const [viewRecently, setViewRecently] = useState<{ shop: Shop; createdAt: string }[]>([]);

  useEffect(() => {
    if (userData && userData.id) {
      reviewViewedRecentlyStorage(userData.id);
      const recent = getRecentlyViewed(userData.id);
      setViewRecently(recent.slice(0, 5));
    }
  }, [userData]);

  return (
    <Box className={'focus-on-search'} display={'flex'} flexDirection={'column'} gap={2} mt={4}>
      {viewRecently.length !== 0 && (
        <Typography className={'focus-on-search'} variant="body2" color={'#313D53'}>
          Είδες πρόσφατα
        </Typography>
      )}
      <Box className={'focus-on-search'} display={'flex'} flexDirection={'column'} gap={1}>
        {viewRecently
          ? viewRecently.map((shop, index) => (
              <Box key={index}>
                <Link className="suggestedLink" to={`/${shop.shop.slug.name}`}>
                  <Box
                    className={'focus-on-search'}
                    display={'flex'}
                    width={tablet || mobile ? '98%' : '400px'}
                    sx={{ position: 'relative' }}
                  >
                    <PigogoHorizontalProductCard shop={shop.shop} />
                  </Box>
                </Link>
              </Box>
            ))
          : ''}
      </Box>
    </Box>
  );
};

export default Suggested;
