import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Chip, Container, Divider, Paper, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { useIonRouter } from '@ionic/react';
import {
  useGetUserDataQuery,
  useGetUserPurchasesShopsQuery,
  useLazyGetUserPurchasesQuery,
} from '../../redux/api/queries/userQueries';
import { PigogoButton, PigogoSmallButton, theme } from 'components';
import { useDispatch } from 'react-redux';
import { setSnackBar } from '../../redux/slices/layoutSlice';
import { PurchaseStatus } from '../../redux/api/types/enum/PurchaseStatus';
import { PurchaseSort } from '../../redux/api/types/enum/PurchaseSort';
import { Purchase } from '../../models/Purchase';
import { ReactComponent as SearchPositive } from '../../assets/svgs/illustrations/cart.svg';
import { ReactComponent as Close } from '../../assets/svgs/vectors/close.svg';
import PurchasesTableDesktop from './Purchases/PurchasesTableDesktop';
import PurchasesTableMobile from './Purchases/PurchasesTableMobile';
import PurchasesLookingForPurchaseBox from './Purchases/PurchasesLookingForPurchaseBox';
import PurchaseModal from './PurchaseModal';
import PurchasesFilterModal from './PurchasesFilterModal';
import MultipleDropdown from './MultipleDropdown';
import Pagination from '../Stores/Pagination';
import WarningMessage from './Overview/WarningMessage';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import UserTablesRefresher from './UserTablesRefresher';

const purchaseStates = [
  { label: 'Σε αναμονή', value: PurchaseStatus.hold },
  { label: 'Προς πληρωμή', value: PurchaseStatus.due },
  { label: 'Πληρωμένη', value: PurchaseStatus.completed },
  { label: 'Ακυρωμένη', value: PurchaseStatus.cancelled },
];

const Purchases = () => {
  const router = useIonRouter();
  const dispatch = useDispatch();

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [filtersModal, setFiltersModal] = useState<boolean>(false);
  const [purchaseList, setPurchaseList] = useState<Purchase[]>([]);
  const [purchaseModal, setPurchaseModal] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<PurchaseSort>(PurchaseSort.purchase_date);
  const [selectedPurchase, setSelectedPurchase] = useState<Purchase | null>(null);
  const [status, setStatus] = useState<{ label: string; value: PurchaseStatus }[]>([]);
  const [selectedShops, setSelectedShops] = useState<{ label: string; value: number }[]>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(true);

  const cursorRef = useRef<string | null | undefined>(null);

  //Here we fetch the shops to be displayed inside the dropdown menu
  const { data: allShops, isLoading: shopsLoading, isError: allShopsError } = useGetUserPurchasesShopsQuery();
  const [trigger, { data: purchases, isLoading, isFetching, isError: purchasesError, isSuccess }] =
    useLazyGetUserPurchasesQuery({});
  const { data: userData, isLoading: userDataLoading } = useGetUserDataQuery();

  const [isLoadingPurchases, setIsLoadingPurchases] = useState<boolean>(true);
  const [isRefreshingPurchases, setIsRefreshingPurchases] = useState<boolean>(false);

  useEffect(() => {
    if (allShopsError || purchasesError) {
      const params = { value: true, msg: 'Κάτι πήγε στραβά με τις αγορές.', severity: 'error' };
      dispatch(setSnackBar(params));
    } else {
      const params = { value: false, msg: '' };
      dispatch(setSnackBar(params));
    }
  }, [allShopsError, purchasesError]);

  useEffect(() => {
    if (!isSuccess || isFetching) return;
    if (purchases && !isRefreshingPurchases) {
      setPurchaseList(purchaseList.concat(purchases.data));
      cursorRef.current = purchases.nextCursor;
    } else if (purchases && isRefreshingPurchases) {
      setPurchaseList(purchases.data);
      cursorRef.current = purchases.nextCursor;
    }
    setIsLoadingPurchases(false);
    setIsRefreshingPurchases(false);
    setIsProcessing(false);
  }, [isFetching, purchases]);

  useEffect(() => {
    setIsProcessing(true);
    setPurchaseList([]);
    cursorRef.current = null;
    setIsLoadingPurchases(true);
    callTrigger();
  }, [order, orderBy, selectedShops, status]);

  const formattedShops = useCallback(() => {
    if (allShops && allShops.data) {
      return allShops?.data?.map((shop) => {
        return { label: shop.shop_name, value: shop.shop_id };
      });
    } else {
      return [];
    }
  }, [allShops]);

  const callTrigger = async () => {
    await trigger(
      {
        order: order,
        sort: orderBy,
        shop: selectedShops.length ? selectedShops.map((shop) => shop.value.toString()) : undefined,
        status: status.length ? status.map((item) => item.value).toString() : undefined,
        limit: 25,
        nextCursor: purchases ? cursorRef.current : undefined,
      },
      true,
    );
  };

  const hasNoNextPage = () => {
    return !!(purchases && purchases?.totalCount && purchaseList.length >= purchases?.totalCount);
  };

  const loadData = async (ev?: any) => {
    setIsLoadingPurchases(true);
    await callTrigger();
    if (ev) ev.target.complete();
  };

  const handleRemoveShop = (value: number) => {
    setSelectedShops(selectedShops.filter((e) => e.value !== value));
  };

  const handleRemoveStatus = (value: number) => {
    setStatus(status.filter((e) => e.value !== value));
  };

  const handleTableRowClick = (purchase: Purchase) => {
    setPurchaseModal(true);
    setSelectedPurchase(purchase);
  };

  const getLabel = () => {
    if (isLoading || isLoadingPurchases) {
      return null;
    }
    if (purchaseList.length === 0 && (selectedShops.length > 0 || status.length > 0)) {
      return (
        <Typography variant="subtitle1SmallL" color="primary">
          Δεν βρέθηκαν αγορές
        </Typography>
      );
    }

    if (purchaseList.length === 0 && !isProcessing) {
      return (
        <Typography variant="subtitle1SmallL" component="p" color="primary">
          Δεν έχεις πραγματοποιήσει ακόμα κάποια αγορά.
        </Typography>
      );
    }
  };

  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const renderChips = (desktop: boolean) => {
    if (selectedShops.length > 0 || status.length > 0) {
      return (
        <Box display="flex" flexWrap="wrap" gap={1} pt={0} pb={5} px={desktop ? 3 : 0}>
          {selectedShops.map((shop, index) => (
            <Chip
              key={index}
              label={shop?.label}
              deleteIcon={<Close />}
              sx={{
                fontSize: mdDown ? '14px' : '12px',
                fontWeight: 500,
                lineHeight: '1.33',
                backgroundColor: '#EAECEE',
                color: '#313D53',
                padding: mdDown ? '8px 12px 8px 12px' : '4px 8px 4px 12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '4px',
                borderRadius: '32px',
                minHeight: mdDown ? '40px' : '32px',
                '& .MuiChip-deleteIcon': {
                  width: '24px',
                  height: '24px',
                  margin: '0',
                  color: '#313D53',
                  padding: mdDown ? '0' : '3px',
                },
                '& .MuiChip-label': {
                  paddingRight: '0px',
                  paddingLeft: '0px',
                },
              }}
              onDelete={() => handleRemoveShop(shop.value)}
            />
          ))}
          {status.map((item, index) => (
            <Chip
              key={index}
              label={item?.label}
              deleteIcon={<Close />}
              sx={{
                fontSize: mdDown ? '14px' : '12px',
                fontWeight: 500,
                lineHeight: '1.33',
                backgroundColor: '#EAECEE',
                color: '#313D53',
                padding: mdDown ? '8px 12px 8px 12px' : '4px 8px 4px 12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '4px',
                borderRadius: '32px',
                minHeight: mdDown ? '40px' : '32px',
                '& .MuiChip-deleteIcon': {
                  width: '24px',
                  height: '24px',
                  margin: '0',
                  color: '#313D53',
                  padding: mdDown ? '0' : '3px',
                },
                '& .MuiChip-label': {
                  paddingRight: '0px',
                  paddingLeft: '0px',
                },
              }}
              onDelete={() => handleRemoveStatus(item.value)}
            />
          ))}
        </Box>
      );
    }
  };

  const getChips = () => {
    if (purchaseList.length === 0) {
      return <Box pt={2}>{renderChips(true)}</Box>;
    }
    if (purchaseList.length > 0) {
      return renderChips(true);
    }
  };

  const renderMobileView = () => {
    if (isLoadingPurchases || isRefreshingPurchases || purchaseList.length) {
      return (
        <Box>
          <Box
            display="flex"
            pt={5}
            pb={2}
            mb={2}
            borderBottom={'1px solid #EAECEE'}
            flexDirection={'column'}
            gap={2}
            alignItems="flex-start"
          >
            <PigogoSmallButton
              variant={'outlined'}
              text={'Φίλτρα & Ταξινόμηση'}
              onClick={() => setFiltersModal(true)}
              typographyProps={{ variant: 'subtitle1SmallR' }}
              sx={{ fontSize: '14px', fontWeight: 400, height: '42px' }}
            />
            {(selectedShops.length > 0 || status.length > 0) && (
              <Box display="flex" flexWrap="wrap" gap={1} pt={0} pb={0} px={0}>
                {selectedShops.map((shop, index) => (
                  <Chip
                    deleteIcon={<Close />}
                    sx={{
                      fontSize: mdDown ? '14px' : '12px',
                      fontWeight: 500,
                      lineHeight: '1.33',
                      backgroundColor: '#EAECEE',
                      color: '#313D53',
                      padding: mdDown ? '8px 12px 8px 12px' : '4px 8px 4px 12px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '4px',
                      borderRadius: '32px',
                      minHeight: mdDown ? '40px' : '32px',
                      '& .MuiChip-deleteIcon': {
                        width: '24px',
                        height: '24px',
                        margin: '0',
                        color: '#313D53',
                        padding: mdDown ? '0' : '3px',
                      },
                      '& .MuiChip-label': {
                        paddingRight: '0px',
                        paddingLeft: '0px',
                      },
                    }}
                    key={index}
                    label={shop?.label}
                    onDelete={() => handleRemoveShop(shop.value)}
                  />
                ))}
                {status.map((item, index) => (
                  <Chip
                    deleteIcon={<Close />}
                    sx={{
                      fontSize: mdDown ? '14px' : '12px',
                      fontWeight: 500,
                      lineHeight: '1.33',
                      backgroundColor: '#EAECEE',
                      color: '#313D53',
                      padding: mdDown ? '8px 12px 8px 12px' : '4px 8px 4px 12px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '4px',
                      borderRadius: '32px',
                      minHeight: mdDown ? '40px' : '32px',
                      '& .MuiChip-deleteIcon': {
                        width: '24px',
                        height: '24px',
                        margin: '0',
                        color: '#313D53',
                        padding: mdDown ? '0' : '3px',
                      },
                      '& .MuiChip-label': {
                        paddingRight: '0px',
                        paddingLeft: '0px',
                      },
                    }}
                    key={index}
                    label={item?.label}
                    onDelete={() => handleRemoveStatus(item.value)}
                  />
                ))}
              </Box>
            )}
          </Box>

          <UserTablesRefresher
            setIsRefreshingData={setIsRefreshingPurchases}
            trigger={async () => {
              return await trigger(
                {
                  order: order,
                  sort: orderBy,
                  shop: selectedShops.length ? selectedShops.map((shop) => shop.value.toString()) : undefined,
                  status: status.length ? status.map((item) => item.value).toString() : undefined,
                  limit: 25,
                  nextCursor: undefined,
                },
                false,
              );
            }}
          ></UserTablesRefresher>

          {!isRefreshingPurchases && <PurchasesTableMobile purchases={purchaseList} onRowClick={handleTableRowClick} />}
          {(isLoadingPurchases || isRefreshingPurchases) && <PurchasesTableMobile isLoading />}
          <Box mb={3}>
            {!isLoadingPurchases && (
              <Pagination
                currentCount={purchases ? purchaseList.length : 0}
                total={purchases?.totalCount ?? 0}
                nextPage={purchases?.nextCursor}
                loadMore={loadData}
              />
            )}
          </Box>
          <PurchasesLookingForPurchaseBox />
        </Box>
      );
    } else {
      return (
        <Box py={mdDown ? '0 16px' : 5}>
          <UserTablesRefresher
            setIsRefreshingData={setIsRefreshingPurchases}
            trigger={async () => {
              return await trigger(
                {
                  order: order,
                  sort: orderBy,
                  shop: selectedShops.length ? selectedShops.map((shop) => shop.value.toString()) : undefined,
                  status: status.length ? status.map((item) => item.value).toString() : undefined,
                  limit: 25,
                  nextCursor: undefined,
                },
                false,
              );
            }}
          ></UserTablesRefresher>
          {userData?.user_warning_message && (
            <Box display="flex" flexDirection="column">
              <WarningMessage />
            </Box>
          )}

          {selectedShops.length > 0 || status.length > 0 ? (
            <Box display="flex" pt={4} pb={2} justifyContent="space-between">
              <PigogoSmallButton
                variant={'outlined'}
                text={'Φίλτρα & Ταξινόμηση'}
                onClick={() => setFiltersModal(true)}
                typographyProps={{ variant: 'subtitle1SmallR' }}
                sx={{ fontSize: '14px', fontWeight: 400, height: '42px' }}
              />
            </Box>
          ) : (
            ''
          )}

          {renderChips(false)}
          <Box sx={{ textAlign: 'center', paddingTop: 5 }}>
            <SearchPositive />
            {!isProcessing && !isLoadingPurchases && (
              <Box display="flex" justifyContent="center" px={3}>
                <Typography width="279px" variant="body2" color={'#313D53'}>
                  {selectedShops.length > 0 || status.length > 0
                    ? 'Δεν βρέθηκαν αγορές.'
                    : 'Δεν έχεις πραγματοποιήσει ακόμα κάποια αγορά.'}
                </Typography>
              </Box>
            )}
          </Box>

          <PurchasesLookingForPurchaseBox />
        </Box>
      );
    }
  };
  const renderDesktopView = () => {
    return (
      <Box pt={tablet ? 4 : undefined}>
        {userData?.user_warning_message && (
          <Box display="flex" flexDirection="column" mt={tablet ? -2 : undefined} mb={2}>
            <WarningMessage />
          </Box>
        )}
        <Box border="2px solid #EAECEE" borderRadius="24px">
          <Box
            p={'20px 24px 19px'}
            display={'flex'}
            flexDirection={'column'}
            borderBottom="2px solid #EAECEE"
            gap={'4px'}
          >
            <Typography color={'#1D2532'} variant="body1" component="h3" letterSpacing={0}>
              Αγορές
            </Typography>
            {getLabel()}
          </Box>
          {purchaseList.length || shopsLoading || isLoadingPurchases ? (
            <Box p={3} display="flex" gap={3}>
              {(shopsLoading || isLoadingPurchases) && !purchaseList.length && (
                <Skeleton width="200px" height="46px" sx={{ borderRadius: '30px' }} variant="rectangular" />
              )}
              {(shopsLoading || isLoadingPurchases) && !purchaseList.length && (
                <Skeleton width="200px" height="46px" sx={{ borderRadius: '30px' }} variant="rectangular" />
              )}
              {(!shopsLoading && !isLoadingPurchases) || purchaseList.length ? (
                <MultipleDropdown
                  purchases
                  width="200px"
                  label="Κατάστημα"
                  renderValue={(elems: number) => {
                    return elems === 1 ? `${elems} επιλεγμένο` : `${elems} επιλεγμένα`;
                  }}
                  options={formattedShops()}
                  optionsChecked={selectedShops}
                  setOptionsChecked={setSelectedShops}
                />
              ) : (
                ''
              )}
              {(!shopsLoading && !isLoadingPurchases) || purchaseList.length ? (
                <MultipleDropdown
                  width="200px"
                  label="Κατάσταση"
                  renderValue={(elems: number) => {
                    return elems === 1 ? `${elems} επιλεγμένη` : `${elems} επιλεγμένες`;
                  }}
                  optionsChecked={status}
                  options={purchaseStates}
                  setOptionsChecked={setStatus}
                />
              ) : (
                ''
              )}
            </Box>
          ) : (
            ''
          )}
          {getChips()}
          {isLoadingPurchases || (!purchaseList.length && isProcessing) || purchaseList.length ? (
            <PurchasesTableDesktop
              purchases={purchaseList}
              isLoading={isLoadingPurchases}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              order={order}
              setOrder={setOrder}
              onRowClick={handleTableRowClick}
            />
          ) : (
            <Box p={'8px 24px 24px'} textAlign="center">
              <SearchPositive />
            </Box>
          )}
          {!isLoadingPurchases &&
            purchaseList.length > 0 &&
            purchases &&
            purchaseList.length !== purchases.totalCount && (
              <Box pt={6} mb={3}>
                <Pagination
                  currentCount={purchases ? purchaseList.length : 0}
                  total={purchases?.totalCount ?? 0}
                  nextPage={purchases?.nextCursor}
                  loadMore={loadData}
                />
              </Box>
            )}
        </Box>
        <Paper elevation={0} sx={{ background: '#F7F7F8', borderRadius: '24px', border: '2px solid #EAECEE', mt: 6 }}>
          <PurchasesLookingForPurchaseBox />
        </Paper>
      </Box>
    );
  };

  const extractView = () => {
    if (!mobile) {
      return renderDesktopView();
    } else if (mobile) {
      return renderMobileView();
    }
  };

  return (
    <Box>
      {location.pathname === '/user/purchases' && (
        <Helmet>
          <title>Αγορές - Ο λογαριασμός μου | Pigogo</title>
          <meta
            name="description"
            content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
          />
        </Helmet>
      )}
      {extractView()}
      <PurchaseModal
        isOpen={purchaseModal}
        setOpen={setPurchaseModal}
        selectedPurchase={selectedPurchase}
        setSelectedPurchase={setSelectedPurchase}
      />
      <PurchasesFilterModal
        sort={order}
        setSort={setOrder}
        status={status}
        setStatus={setStatus}
        selectedShops={selectedShops}
        setSelectedShops={setSelectedShops}
        isOpen={filtersModal}
        setOpen={setFiltersModal}
        purchaseStates={purchaseStates}
        shops={formattedShops()}
      />
    </Box>
  );
};

export default Purchases;
