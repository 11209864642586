import { useIonRouter, useIonViewWillEnter } from '@ionic/react';
import React, { FC, useEffect, useState } from 'react';
import { PigogoButton, PigogoCardsCarousel, PigogoProductCard, PigogoPromoCard, theme } from 'components';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import FavouritesTable from './FavouritesTable';
import { useShopsQuery } from '../../redux/api/queries/shopQuery';
import { useDeleteFavouriteMutation, usePostFavouriteMutation } from '../../redux/api/mutations/favouritesMutation';
import { useAppSelector } from '../../redux/hooks';
import { usePromosQuery } from '../../redux/api/queries/promosQueries';
import { Promo } from '../../models/Promo';
import { Favourite } from '../../models/Favourite';
import { useDispatch } from 'react-redux';
import { setSnackBar } from '../../redux/slices/layoutSlice';
import CustomContainer from '../CustomContainer';

const loadArray = [1, 1, 1, 1];

interface FavouritesProps {
  favourites: Favourite[];
  loading: boolean;
  showCarousel: boolean;
}

const FavouriteContent: FC<FavouritesProps> = ({ favourites, loading, showCarousel }) => {
  const router = useIonRouter();
  const dispatch = useDispatch();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const xlUp = useMediaQuery(theme.breakpoints.up('extraLg'));

  const [postFavourite, { isError: postFavouriteError }] = usePostFavouriteMutation();
  const [deleteFavourite, { isError: deleteError }] = useDeleteFavouriteMutation();

  const { data: promos, isError: errorPromo } = usePromosQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const { data: shops, isLoading: shopsLoad, isError: errorShops } = useShopsQuery({ limit: 11 });

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const [carouselData, setCarouselData] = useState<any[]>([]);

  useEffect(() => {
    if (deleteError || postFavouriteError) {
      const params = { value: true, msg: 'Κάτι πήγε στραβά!', severity: 'error' };
      dispatch(setSnackBar(params));
    }
  }, [deleteError, postFavouriteError, errorShops, errorPromo]);

  useEffect(() => {
    const finalData: any[] = [];

    if (shops) {
      shops.data.map((shop) => {
        const {
          image = 'N/A',
          name = 'N/A',
          cashback = 'N/A',
          currency = 'N/A',
          strikedCashback = null,
          strikedCurrency = null,
          user_favourite = null,
          savings = { coupon_count: undefined, offer_count: undefined },
          promos = { has_Promos: false, has_double: false },
          cashback_categories_count = null,
        } = shop ? shop : {};

        finalData.push({
          type: 'shop',
          id: shop.id,
          image: image,
          name: name,
          promos: promos,
          cashback,
          currency,
          strikedCashback,
          strikedCurrency,
          cashback_categories_count,
          savings: savings,
          logout: !(!!accessToken || sessionLoggedIn),
          slugName: shop.slug.name,
          hotOffer: promos.has_double,
          favourites: !!user_favourite,
          user_favourite: user_favourite,
          discount: `${cashback}${currency}`,
          tag: savings.offer_count ? savings.offer_count : null,
          ticket: savings.coupon_count ? savings.coupon_count : null,
          deleteDisc: strikedCashback ? `${strikedCashback}${strikedCurrency}` : undefined,
        });
      });
    }

    if (promos && promos.data) {
      const promoData = {
        type: 'promo',
        logo: promos.data[0].image,
        label: promos.data[0].label,
        tile_title: promos.data[0].tile_title,
        url: promos.data[0].url,
        promo_type_id: promos.data[0].promo_type_id,
        call_to_action: promos.data[0].call_to_action,
        call_to_action_mobile: promos.data[0].call_to_action_mobile,
      };

      finalData.splice(3, 0, promoData);
    }

    setCarouselData(finalData);
  }, [promos, shops]);

  const favourite = (userFavourite: string | null, id: number) => {
    if (userFavourite) {
      deleteFavourite(id);
    } else {
      postFavourite({ shop_id: id, notifications: 1 });
    }
  };

  const handlePromoClick = (promo: Promo) => {
    const urlPromo = promo.url;

    router.push(urlPromo);
  };

  const storeCards = () => {
    if (carouselData && !shopsLoad) {
      return carouselData.map((item: any, index: number) => {
        return (
          <Box
            mx={xlUp ? '12px' : mdUp ? 1 : 0.5}
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ cursor: 'pointer' }}
          >
            {item.type === 'promo' ? (
              <PigogoPromoCard
                productType
                logo={item.logo}
                color={item.color}
                title={item?.tile_title}
                call_to_action={!mdDown ? item.call_to_action : item.call_to_action_mobile}
                onClick={() => handlePromoClick(item)}
              />
            ) : (
              <PigogoProductCard
                shop={item}
                loading={false}
                handleClick={() => router.push(`/${item.slugName}`)}
                setFavourite={() => (!!accessToken || sessionLoggedIn ? favourite(item.user_favourite, item.id) : '')}
              />
            )}
          </Box>
        );
      });
    }

    if (shopsLoad) {
      loadArray.map((load, index: number) => {
        return (
          <Grid item xs={6} md={4} lg={5} key={index} display="flex" alignItems="center" justifyContent="center">
            <PigogoProductCard loading={true} logout={!(!!accessToken || sessionLoggedIn)} />
          </Grid>
        );
      });
    }

    if (!shopsLoad && !shops) {
      return <Typography textAlign="center">Δεν υπάρχουν καταστήματα</Typography>;
    }
  };

  const getWidth = () => {
    if (xlUp) {
      return 'calc(100% + 24px)';
    }
    if (mdUp) {
      return 'calc(100% + 16px)';
    }
    return 'calc(100% + 8px)';
  };

  const getMargin = () => {
    if (xlUp) {
      return '-12px';
    }
    if (mdUp) {
      return '-8px';
    }
    return '-4px';
  };

  const getNoData = () => {
    return (
      <CustomContainer paddingTop={7} paddingBottom={10}>
        <style>
          {`
        .react-multi-carousel-dot-list {
          height: 0;
        }
        `}
        </style>
        <Box display={'flex'} flexDirection={'column'}>
          {smDown ? (
            <Box>
              <Typography variant={'subtitle1'} component="h2">
                Προτεινόμενα καταστήματα
              </Typography>
            </Box>
          ) : (
            <Box display={'flex'} flexDirection={'column'} textAlign={'center'} pt={3} gap={2}>
              <Typography variant={'smallB'} component="h2" color={'#1D2532'}>
                Προτεινόμενα καταστήματα
              </Typography>
              <Typography variant={'body2MediumL'} component="p" color={'#1D2532'}>
                Στο Pigogo θα βρεις τις καλύτερες προσφορές των καταστημάτων.
              </Typography>
            </Box>
          )}
          <Box paddingTop={smDown ? 3 : 6} sx={{ width: getWidth(), marginLeft: getMargin() }}>
            {shops && <PigogoCardsCarousel>{storeCards()}</PigogoCardsCarousel>}
          </Box>
          {!smDown && (
            <Box paddingTop={14} textAlign={'center'}>
              <PigogoButton
                variation="secondary"
                sx={{ width: '327px' }}
                text="Δες τα καταστήματα"
                onClick={() => router.push(`/stores`)}
              />
            </Box>
          )}
        </Box>
      </CustomContainer>
    );
  };

  const getFavourites = () => {
    return (
      <CustomContainer>
        <Box paddingTop={smDown ? 4 : 10} paddingBottom={smDown ? 4 : 16}>
          <FavouritesTable triggerFetch={true} />
        </Box>
        {showCarousel && (
          <Box display={'flex'} flexDirection={'column'} paddingBottom={10}>
            {smDown ? (
              <Box>
                <Typography variant={'subtitle1'}>Προτεινόμενα καταστήματα</Typography>
              </Box>
            ) : (
              <Box display={'flex'} flexDirection={'column'} textAlign={'center'} pt={3} gap={2}>
                <Typography variant={'smallB'} color={'#1D2532'}>
                  Προτεινόμενα καταστήματα
                </Typography>
                <Typography variant={'body2MediumL'} color={'#1D2532'}>
                  Στο Pigogo θα βρεις τις καλύτερες προσφορές των καταστημάτων.
                </Typography>
              </Box>
            )}
            <Box paddingTop={smDown ? 3 : 6}>{shops && <PigogoCardsCarousel>{storeCards()}</PigogoCardsCarousel>}</Box>
            {!smDown && (
              <Box paddingTop={14} textAlign={'center'}>
                <PigogoButton
                  variation="secondary"
                  sx={{ width: '327px' }}
                  text="Δες τα καταστήματα"
                  onClick={() => router.push(`/stores`)}
                />
              </Box>
            )}
          </Box>
        )}
      </CustomContainer>
    );
  };

  return favourites.length > 0 || loading ? getFavourites() : getNoData();
};

export default FavouriteContent;
