import { Shop } from '../models/Shop';
import { IViewedRecentlyStored } from '../models/IViewedRecentlyStored';
import moment from 'moment';

const viewedShop = (shop: Shop, id: number) => {
  const { id: shopId } = shop;
  let viewedRecentlyShops: IViewedRecentlyStored[] = [];
  const viewedRecentlyShopsStr = localStorage.getItem('viewed-recently-shops');

  viewedRecentlyShops = viewedRecentlyShopsStr ? JSON.parse(viewedRecentlyShopsStr) : [];
  const foundUser = viewedRecentlyShops.findIndex((viewedRecentlyShop) => viewedRecentlyShop.userId === id);

  if (foundUser !== -1) {
    const foundUserData = viewedRecentlyShops[foundUser].data;
    const foundIndex = foundUserData.findIndex((viewedRecentlyShop) => viewedRecentlyShop.shop.id === shopId);

    if (foundIndex !== -1) {
      foundUserData[foundIndex].createdAt = moment().toISOString();
    } else {
      const newViewedShop = { shop, createdAt: moment().toISOString() };
      viewedRecentlyShops[foundUser].data.unshift(newViewedShop);
    }
  } else {
    const newViewedShop: IViewedRecentlyStored = {
      userId: id,
      data: [{ shop: shop, createdAt: moment().toISOString() }],
    };
    viewedRecentlyShops.unshift(newViewedShop);
  }

  const shopsStr = JSON.stringify(viewedRecentlyShops);
  localStorage.setItem('viewed-recently-shops', shopsStr);
};

const reviewViewedRecentlyStorage = (id: number) => {
  let viewedRecentlyShops: IViewedRecentlyStored[] = [];
  const viewedRecentlyShopsStr = localStorage.getItem('viewed-recently-shops');
  if (!viewedRecentlyShopsStr) {
    return;
  }
  viewedRecentlyShops = JSON.parse(viewedRecentlyShopsStr);
  const foundUser = viewedRecentlyShops.findIndex((viewedRecentlyShop) => viewedRecentlyShop.userId === id);
  if (foundUser !== -1) {
    viewedRecentlyShops[foundUser].data = viewedRecentlyShops[foundUser].data.filter((viewedRecentlyShop) => {
      const createdAt = viewedRecentlyShop.createdAt;

      const fromNow = moment(createdAt).diff(moment(new Date()), 'days', true);

      return fromNow <= 7;
    });

    const updatedViewedRecentlyStr = JSON.stringify(viewedRecentlyShops);
    localStorage.setItem('viewed-recently-shops', updatedViewedRecentlyStr);
  }
};

const clearViewedRecently = () => {
  localStorage.removeItem('viewed-recently-shops');
};

const getRecentlyViewed = (id: number) => {
  let viewedRecentlyShops: IViewedRecentlyStored[] = [];

  const viewedRecentlyShopsStr = localStorage.getItem('viewed-recently-shops');
  if (!viewedRecentlyShopsStr) {
    return [];
  }

  viewedRecentlyShops = JSON.parse(viewedRecentlyShopsStr);
  const foundUser = viewedRecentlyShops.findIndex((viewedRecentlyShop) => viewedRecentlyShop.userId === id);

  if (foundUser !== -1) {
    return viewedRecentlyShops[foundUser].data;
  } else {
    return [];
  }
};

export { viewedShop, reviewViewedRecentlyStorage, clearViewedRecently, getRecentlyViewed };
