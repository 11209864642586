import React, { FC, useEffect, useState } from 'react';
import { IonHeader, IonModal, IonToolbar } from '@ionic/react';
import {
  Box,
  IconButton,
  Step,
  StepContent,
  stepContentClasses,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ReactComponent as MessageBubble } from '../../assets/svgs/messageBubble.svg';
import { ReactComponent as ChevronLeft } from '../../assets/svgs/chevronLeft24.svg';
import { ReactComponent as Close } from '../../assets/svgs/close.svg';
import moment from 'moment';
import { useGetUserDataQuery } from '../../redux/api/queries/userQueries';
import { Claim } from '../../models/Claims';
import { theme } from 'components';
import { useLazyShopQuery } from '../../redux/api/queries/shopQuery';
import ExtractClaimType from '../ExtractClaimType';
import { ReactComponent as CheckPink } from '../../assets/svgs/check_pink.svg';
import Linkify from 'linkify-react';

interface Props {
  isOpen: boolean;
  setOpen: (b: boolean) => void;
  selectedClaim: Claim | null;
  setSelectedClaim: (selectedClaim: Claim | null) => void;
}

const StyledStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ ownerState }) => ({
  color: '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#FFFFFF',
    zIndex: 1,
    fontSize: 18,
    width: 22,
    height: 22,
    borderRadius: '50%',
    backgroundColor: '#E9688A',
  },
  '& .QontoStepIcon-circle': {
    width: 22,
    height: 22,
    borderRadius: '50%',
    backgroundColor: '#ECEFF4',
  },
}));

const ongoingSteps = [
  {
    label: 'Σε επεξεργασία',
    description: `Ο έλεγχος των αιτημάτων πραγματοποιείται συνήθως σε συνεργασία με τα καταστήματα και μπορεί να διαρκέσει από λίγες ημέρες έως μερικές εβδομάδες.`,
  },
  {
    label: 'Εγκρίθηκε',
    description: 'Το αίτημά σου έχει εγκριθεί.',
  },
];

const cancelledSteps = [
  {
    label: 'Σε επεξεργασία',
    description: `Η αγορά έχει καταγραφεί και αναμένεται να αλλάξει σε «Προς πληρωμή» μόλις επιβεβαιωθεί από το κατάστημα σε περίπου 3 εβδομάδες.`,
  },
  {
    label: 'Δεν εγκρίθηκε',
    description: 'Το αίτημά σου δεν έχει εγκριθεί.',
  },
];

const ClaimModal: FC<Props> = ({ isOpen, setOpen, selectedClaim, setSelectedClaim }) => {
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState<{ label: string; description: string; date: string; waitingTime?: string }[]>([]);

  const { data: userData } = useGetUserDataQuery();
  const [trigger, { data: shopData }] = useLazyShopQuery();

  const [cancelledStep1, cancelledStep2] = cancelledSteps;
  const [ongoingStep1, ongoingStep2] = ongoingSteps;

  const renderLink = ({ attributes, content }: any) => {
    const { href, ...props } = attributes;
    return (
      <a href={href} target={'_blank'} style={{ color: '#313D53', textDecorationColor: '#313D53', fontWeight: '400' }}>
        {content}
      </a>
    );
  };

  useEffect(() => {
    if (selectedClaim?.shop_id !== undefined) {
      trigger({ slugName: selectedClaim?.shop_slug.name });
    }
  }, [selectedClaim, trigger]);

  useEffect(() => {
    if (selectedClaim?.claim_status_id === 3) {
      setActiveStep(1);
      setSteps([
        {
          ...{ ...cancelledStep1, description: '' },
          date: moment(selectedClaim?.created_at).format('DD/MM/YYYY'),
        },
        {
          ...cancelledStep2,
          date: moment(selectedClaim?.updated_at).format('DD/MM/YYYY'),
        },
      ]);
    } else if (selectedClaim?.claim_status_id === 1) {
      setActiveStep(0);
      setSteps([
        {
          ...ongoingStep1,
          date: moment(selectedClaim?.created_at).format('DD/MM/YYYY'),
          waitingTime: `Εντός ${selectedClaim?.shop_claim_response_time} εβδομάδ${
            selectedClaim?.shop_claim_response_time === 1 ? 'ας' : 'ων'
          } από την ημερομηνία καταχώρησης του αιτήματος.`,
        },
        {
          ...ongoingStep2,
          date: moment(selectedClaim?.confirmation_date).format('DD/MM/YYYY'),
        },
      ]);
    } else if (selectedClaim?.claim_status_id === 2) {
      setActiveStep(1);
      setSteps([
        {
          ...{ ...ongoingStep1, description: '' },
          date: moment(selectedClaim?.created_at).format('DD/MM/YYYY'),
        },
        {
          ...ongoingStep2,
          date: moment(selectedClaim?.confirmation_date).format('DD/MM/YYYY'),
        },
      ]);
    }
  }, [selectedClaim, userData]);

  const handleCloseModal = () => {
    setOpen(false);
    setActiveStep(0);
    setSteps([]);
    setSelectedClaim(null);
  };

  const stepIcon = (index: number) => {
    const active = activeStep > index || activeStep === index;
    const className = active ? 'QontoStepIcon-completedIcon' : 'QontoStepIcon-circle';
    return (
      <StyledStepIconRoot ownerState={{ active }} className={className}>
        {active ? <CheckPink /> : <div className="QontoStepIcon-circle" />}
      </StyledStepIconRoot>
    );
  };

  const getBorder = (index: number) => {
    if (steps.length - 1 === index) {
      return 'none';
    } else if (activeStep > index || activeStep === index) {
      return '2px solid #E9688A';
    } else {
      return '2px solid #ECEFF4';
    }
  };

  return (
    <IonModal
      mode={'ios'}
      style={!smDown ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' } : undefined}
      isOpen={isOpen}
      canDismiss={true}
      onDidDismiss={() => handleCloseModal()}
    >
      <IonHeader class="ion-no-border">
        {smDown && (
          <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '0 + --ion-safe-area-top' }}>
            <Box pr={1} pl={'5px'} py={2} display="flex" gap={2} alignItems="flex-start">
              <IconButton
                sx={{
                  padding: 0,
                  width: '24px',
                  height: '24px',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
                disableRipple
                onClick={() => setOpen(!isOpen)}
              >
                <ChevronLeft />
              </IconButton>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography variant="body2" component="p" color={'#313D53'}>
                  {selectedClaim?.shop_name}
                </Typography>
                {selectedClaim && (
                  <>
                    <Typography variant={'subtitle1SmallR'} color={'#6F7787'} component="p">
                      <ExtractClaimType
                        claim={selectedClaim?.claim_type_id}
                        variant={'subtitle1SmallR'}
                        color={'#6F7787'}
                      />
                    </Typography>
                    <Typography variant={'subtitle1SmallR'} color={'#6F7787'} component="p">
                      {selectedClaim?.order_number}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </IonToolbar>
        )}
        {!smDown && (
          <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '12px', height: '24px' }}>
            <IconButton
              disableRipple
              className="iconBtn"
              sx={{
                position: 'absolute',
                right: 12,
                top: 0,
                padding: 0,
                width: '24px',
                height: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onClick={() => setOpen(!isOpen)}
            >
              <Close />
            </IconButton>
          </IonToolbar>
        )}
      </IonHeader>
      <Box className="overscroll innerPopup">
        {!smDown && (
          <Box display="flex" flexDirection="column" gap="4px" alignItems="center" pb={4}>
            {selectedClaim && (
              <ExtractClaimType
                claim={selectedClaim?.claim_type_id}
                variant={'large900'}
                component="h2"
                color={'#313D53'}
              />
            )}
            <Typography variant="body2MediumM" textAlign="center" component="p" color={'#313D53'}>
              {selectedClaim?.shop_name}
            </Typography>
            <Typography variant={'caption'} component="p" color={'#313D53'}>
              {selectedClaim?.order_number}
            </Typography>
          </Box>
        )}
        <Box pl={!smDown ? 4 : 2} pr={!smDown ? '20px' : 2}>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <Box display="flex" sx={{ padding: 0, paddingBlock: '4px' }} gap={2} alignItems={'center'}>
                {stepIcon(index)}
                <Typography variant="body2" fontWeight={activeStep === index ? 900 : 700} color={'#313D53'}>
                  {step.label}
                </Typography>
              </Box>
              {index <= activeStep && (
                <StepContent
                  sx={{
                    [`&.${stepContentClasses.root}`]: {
                      borderLeft: getBorder(index),
                      paddingLeft: '27px',
                      paddingBottom: '24px',
                      marginLeft: '11px',
                    },
                    [`&.${stepContentClasses.last}`]: {
                      borderLeft: '0px',
                    },
                  }}
                >
                  <Box display="flex" flexDirection="column">
                    {step.date && index <= activeStep && (
                      <Typography variant="subtitle1SmallR" color={'#6F7787'}>
                        {step.date}
                      </Typography>
                    )}

                    <Typography variant="subtitle1SmallR" color={'#6F7787'}>
                      {step.description}
                    </Typography>

                    {step.waitingTime && (
                      <Box pt={2} display="flex" flexDirection="column" gap={1}>
                        <Typography variant="caption" color={'#313D53'}>
                          Συνήθης χρόνος έγκρισης του καταστήματος:
                        </Typography>
                        <Typography variant="caption" color={'#313D53'}>
                          {step.waitingTime}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </StepContent>
              )}
            </Step>
          ))}
        </Box>
        <Box p={smDown ? '16px' : '16px 20px 20px 32px'}>
          <Box
            gap="26px"
            display="flex"
            padding="24px"
            borderRadius="24px"
            sx={{ background: '#F7F7F8' }}
            flexDirection="column"
          >
            <Box display="flex" gap={'10px'} alignItems="center">
              <MessageBubble />
              <Typography variant="subtitle1SmallM" component="p" color={'#313D53'}>
                Μήνυμα από την ομάδα του Pigogo
              </Typography>
            </Box>
            <Typography variant="subtitle1SmallM" component="p" color={'#6F7787'}>
              <Linkify options={{ render: renderLink }}>
                {selectedClaim?.admin_note ? selectedClaim?.admin_note : 'Δεν έχεις κάποιο μήνυμα'}
              </Linkify>
            </Typography>
          </Box>
        </Box>
      </Box>
    </IonModal>
  );
};

export default ClaimModal;
