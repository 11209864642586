import React, { FC, useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import {
  PigogoButton,
  PigogoCardsCarousel,
  PigogoOfferCard,
  PigogoPromoCard,
  PigogoSingleCouponCard,
  theme,
} from 'components';
import { useIonRouter } from '@ionic/react';
import { useSavingsQuery } from '../../redux/api/queries/savingsQueries';
import { Promo } from '../../models/Promo';
import CustomContainer from '../CustomContainer';
import { setSnackBar, setStateSnackBar } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../redux/hooks';
import { Link } from 'react-router-dom';

const dummyArray = [1, 1, 1, 1, 1, 1, 1, 1];

interface Props {
  message: (value: string) => void;
  setLogin: (value: boolean) => void;
}

const HotOffersSection: FC<Props> = ({ message, setLogin }) => {
  const router = useIonRouter();
  const dispatch = useDispatch();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const {
    data: savings,
    isLoading: savingsLoading,
    isError: errorSavings,
  } = useSavingsQuery({ limit: 12, no_promo: true });

  const [loadArray, setLoadArray] = useState(dummyArray);
  const [hotOffers, setHotOffers] = useState<any[]>([]);

  const [isDragging, setIsDragging] = useState<any>(false);

  useEffect(() => {
    if (smDown) {
      setLoadArray(dummyArray.slice(0, 4));
    } else {
      setLoadArray(dummyArray);
    }
  }, [smDown]);

  useEffect(() => {
    let finalData: any[] = [];

    if (savings) {
      finalData = savings.data.map((item) => {
        return {
          id: item.id,
          code: !item.is_offer && item.code ? item.code : undefined,
          logout: !(!!accessToken || sessionLoggedIn),
          logo: item.shop.image,
          name: item.shop.name,
          subTitle: item.description,
          cashbackCount: item.shop.cashback_categories_count,
          discount: item.shop.cashback !== null ? `${item.shop.cashback}${item.shop.currency}` : undefined,
          deleteDisc:
            item.shop.strikedCashback !== null ? `${item.shop.strikedCashback}${item.shop.strikedCurrency}` : undefined,
          date: item.end_date ? item.end_date : '',
          slugName: item.shop.slug.name,
          type: 'savings',
        };
      });
    }

    setHotOffers(finalData);
  }, [savings]);

  const onClickCopy = () => {
    const params = { value: true, msg: 'Αντιγράφηκε στο πρόχειρο', severity: 'infoBlue' };
    dispatch(setSnackBar(params));
  };

  const openModalCoupon = () => {
    setLogin(true);
    message('Για να δεις τον κωδικό του κουπονιού πρέπει πρώτα να συνδεθείς στο Pigogo.');
  };

  const savingsCards = () => {
    if (savingsLoading) {
      return loadArray.map((item, index) => (
        <Box
          p={2}
          key={index}
          display="flex"
          alignItems="center"
          justifyContent="center"
          maxWidth={mdDown ? 'calc(100% - 8px)' : 'calc(100% - 16px)'}
          mx={'auto'}
        >
          <PigogoOfferCard loading={true} />
        </Box>
      ));
    }

    if (!savingsLoading && savings) {
      return hotOffers.map((item, index) => {
        if (item.type === 'promo') {
          return (
            <Box
              onDrag={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              draggable="true"
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="center"
              maxWidth={mdDown ? 'calc(100% - 8px)' : 'calc(100% - 16px)'}
              mx={'auto'}
            >
              <Link className="storeCard storeCard--offer" to={item.url}>
                <PigogoPromoCard
                  lgText
                  loading={false}
                  logo={item.logo}
                  color={item.color}
                  productType={false}
                  title={item.tile_title}
                  call_to_action={!mdDown ? item.call_to_action : item.call_to_action_mobile}
                />
              </Link>
            </Box>
          );
        } else {
          if (item.code) {
            return (
              <Box
                onDragStart={(e) => {
                  setIsDragging(true);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDragEnd={(e) => {
                  if (isDragging) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                  setTimeout(() => {
                    setIsDragging(false);
                  }, 3500);
                }}
                onDrag={(e) => {
                  setIsDragging(true);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDrop={(e) => {
                  setTimeout(() => {
                    setIsDragging(false);
                  }, 3500);
                }}
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="center"
                maxWidth={mdDown ? 'calc(100% - 8px)' : 'calc(100% - 16px)'}
                mx={'auto'}
                position="relative"
              >
                <Link className="storeCard storeCard--abs" to={!isDragging ? `/${item.slugName}?offer=${item.id}` : ''}>
                  <PigogoSingleCouponCard
                    code={item.code}
                    date={item.date}
                    logo={item.logo}
                    name={item.name}
                    logout={item.logout}
                    subTitle={item.subTitle}
                    discount={item.discount}
                    cashbackCount={item.cashbackCount}
                    deleteDisc={item.deleteDisc}
                    setLogin={() => openModalCoupon()}
                    //onClick={() => router.push(`/${item.slugName}?offer=${item.id}`)}
                    onClickCopy={onClickCopy}
                    storeSlug={item.slugName}
                    id={item.id}
                  />
                </Link>
              </Box>
            );
          } else {
            return (
              <Box
                onDragStart={(e) => {
                  setIsDragging(true);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDragEnd={(e) => {
                  if (isDragging) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                  setTimeout(() => {
                    setIsDragging(false);
                  }, 3500);
                }}
                onDrag={(e) => {
                  setIsDragging(true);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDrop={(e) => {
                  setTimeout(() => {
                    setIsDragging(false);
                  }, 3500);
                }}
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="center"
                maxWidth={mdDown ? 'calc(100% - 8px)' : 'calc(100% - 16px)'}
                mx={'auto'}
              >
                <Link className="storeCard" to={!isDragging ? `/${item.slugName}?offer=${item.id}` : ''}>
                  <PigogoOfferCard
                    logo={item.logo}
                    name={item.name}
                    date={item.date}
                    subTitle={item.subTitle}
                    discount={item.discount}
                    multiCashback={item.cashbackCount >= 2}
                    deleteDisc={item.deleteDisc}
                    //onClick={() => router.push(`/${item.slugName}?offer=${item.id}`)}
                  />
                </Link>
              </Box>
            );
          }
        }
      });
    }
  };

  return (
    <CustomContainer py={mdDown ? 4 : 10}>
      <Box display={'flex'} flexDirection={'column'} gap={6}>
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <Typography variant="smallB" color={'#1D2532'} component="h2">
            Hot προσφορές
          </Typography>
          {!smDown && (
            <Typography fontWeight={300} variant="body2" color={'#1D2532'}>
              Στο Pigogo θα βρεις τις καλύτερες προσφορές των καταστημάτων.
            </Typography>
          )}
        </Box>
        <Box
          pb={'50px'}
          onMouseOut={(e) => {
            if (isDragging) {
              e.preventDefault();
              e.stopPropagation();
            }
            setTimeout(() => {
              setIsDragging(false);
            }, 1500);
          }}
        >
          {savings && (
            <PigogoCardsCarousel
              beforeChange={(previousSlide, state) => setIsDragging(true)}
              afterChange={(previousSlide, state) => setIsDragging(false)}
            >
              {savingsCards()}
            </PigogoCardsCarousel>
          )}
        </Box>
        <Box display={'flex'} justifyContent={'center'}>
          <Box width={'327px'} maxWidth={'100%'}>
            <Link className="pigogoButton pigogoButton--secondary pigogoButton--fullwidth" to={'/offers'}>
              Όλες οι προσφορές
            </Link>
          </Box>
        </Box>
      </Box>
    </CustomContainer>
  );
};

export default HotOffersSection;
