import React, { FC, useEffect, useRef, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  isPlatform,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { Box, Container, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PigogoButton, theme } from 'components';
import { useLogoutMutation } from '../../redux/api/mutations/loginMutations';
import QuestionSection from '../Home/QuestionSection';
import AlertSection from '../Home/AlertSection';
import PigogoFooter from '../PigogoFooter';
import { useGetUserDataQuery } from '../../redux/api/queries/userQueries';
import FlipCards from '../FlipCards';
import CustomContainer from '../CustomContainer';
import { Link, Switch } from 'react-router-dom';
import Layout from '../Navigation/Layout';
import { Route, useHistory } from 'react-router';
import Purchases from './Purchases';
import Payments from './Payments';
import PaymentsOptions from './Options/PaymentsOptions';
import PersonalInfo from './Personal/PersonalInfo';
import ContactOptions from './ContactOptions';
import Claims from './Claims';
import TellAFriend from './TellAFriend';
import { useDispatch } from 'react-redux';
import { setLoad, setSnackBar } from '../../redux/slices/layoutSlice';
import { makeStyles } from '@mui/styles';
import SidebarMenu from './SidebarMenu';
import Overview from './Overview/Overview';
import { useAppSelector } from '../../redux/hooks';
import { setFirstLogin } from '../../redux/slices/authenticationSlice';
import StartStepsAlert from '../Home/StartStepsAlert';
import WalkthroughModal from '../Navigation/Modals/WalkthroughModal';
import { Helmet } from 'react-helmet-async';

const customTabs = {
  borderBottom: '1px solid #EAECEE',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'secondary',
    borderRadius: '10px',
    border: '1px solid #E9688A',
    width: '70%',
  },
};

const tabs = [
  {
    value: '/user/overview',
    label: 'Σύνοψη',
    to: '/user/overview',
  },
  {
    value: '/user/purchases',
    label: 'Αγορές',
    to: '/user/purchases',
  },
  {
    value: '/user/payments',
    label: 'Πληρωμές',
    to: '/user/payments',
  },
  {
    value: '/user/claims',
    label: 'Αιτήματα',
    to: '/user/claims',
  },
  {
    value: '/user/personal-info',
    label: 'Στοιχεία λογαριασμού',
    to: '/user/personal-info',
  },
  {
    value: '/user/payments-options',
    label: 'Στοιχεία πληρωμής',
    to: '/user/payments-options',
  },
  {
    value: '/user/contact-options',
    label: 'Ρυθμίσεις επικοινωνίας',
    to: '/user/contact-options',
  },
  {
    value: '/user/tell-a-friend',
    label: 'Tell a friend',
    to: '/user/tell-a-friend',
  },
];

const useStyles = makeStyles(() => ({
  tabSelectable: {
    borderTop: 'none',
    '---background': '#FFFFFF',
    boxShadow: '0px -4px 24px rgba(49, 61, 83, 0.08)',
  },
}));

const UserLayout: FC = () => {
  const classes = useStyles();
  const router = useIonRouter();
  const dispatch = useDispatch();
  const history = useHistory();

  const desktop = isPlatform('desktop');

  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const pathname = router.routeInfo.pathname;

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const tablet = useMediaQuery(theme.breakpoints.only('md'));
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));

  const [startStep, setStartStep] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(true);
  const [walkthrough, setWalkthrough] = useState<boolean>(false);

  const isFirst = useAppSelector((state) => state.authentication.data.firstLogin);

  const [logout, { isLoading: loadLogout }] = useLogoutMutation();
  const { data: userData, isLoading: userDataLoading } = useGetUserDataQuery();

  useIonViewDidEnter(() => {
    setIsFocused(true);

    if (localStorage.getItem('tab-changed') === '1') {
      contentRef.current && contentRef.current.scrollToTop();
    }
    localStorage.removeItem('tab-changed');

    if (isFirst) {
      setTimeout(() => {
        setStartStep(true);
        dispatch(setFirstLogin({ isFirst: false }));
      }, 10000);
    }
  });

  useIonViewWillLeave(() => {
    setIsFocused(false);
  });

  useEffect(() => {
    contentRef.current && contentRef.current.scrollToTop();
  }, [pathname]);

  useEffect(() => {
    dispatch(setLoad(loadLogout));
  }, [loadLogout]);

  useEffect(() => {
    if (!userDataLoading) {
      setTimeout(() => {
        history.action !== 'PUSH' &&
          contentRef.current &&
          contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);
      }, 1000);
    }
  }, [userDataLoading]);

  function handleScrollEnd(e: any) {
    history.replace({ state: { scrollY: e.target.detail.scrollTop } });
  }

  const handleLogout = async () => {
    await logout({});
    window.location.replace('/');
  };

  const handleMenuClick = (pathname: string) => {
    router.push(pathname);
  };

  const handleLetsStart = () => {
    setWalkthrough(true);
  };

  const menuMobile = () => {
    if (mdDown) {
      return (
        <IonHeader class="ion-no-border">
          <IonToolbar className={classes.tabSelectable}>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Box pt={2} px={2}>
                <Typography color={'#313D53'} variant={'body2'} component="h2" sx={{ textAlign: 'center' }}>
                  Ο λογαριασμός μου
                </Typography>
              </Box>
              <Box>
                <Tabs
                  sx={customTabs}
                  textColor="primary"
                  scrollButtons={false}
                  allowScrollButtonsMobile
                  indicatorColor="secondary"
                  value={history.location.pathname.includes('/user') ? history.location.pathname : '/user/overview'}
                  variant={mdDown ? 'scrollable' : 'standard'}
                >
                  {tabs.map((listItem, index) => (
                    <Tab key={index} label={listItem.label} value={listItem.value} to={listItem.to} component={Link} />
                  ))}
                </Tabs>
              </Box>
            </Box>
          </IonToolbar>
        </IonHeader>
      );
    }
  };

  const tipsContent = () => {
    return (
      <Box display={'flex'} flexDirection={'column'} gap={6}>
        <Box display="flex" flexDirection="column" pt={11} px={3} gap={2} justifyContent="center">
          <Typography variant="smallB" component="h3" textAlign="center" color={'#1D2532'}>
            Tips για την επιστοφή
          </Typography>
          <Typography variant="body2MediumL" component="p" textAlign="center" color={'#1D2532'}>
            Δες τα 5 tips για να καταγράφονται οι αγορές σου στο Pigogo
          </Typography>
        </Box>
        <FlipCards />
      </Box>
    );
  };

  const extraSections = () => {
    if (pathname === '/user/overview') {
      if (!mdDown) {
        return <PigogoFooter />;
      } else
        return (
          <Box>
            <SidebarMenu
              pathname={pathname}
              userData={userData}
              handleLogout={handleLogout}
              userDataLoading={userDataLoading}
              handleMenuClick={handleMenuClick}
              mobile
            />
          </Box>
        );
    } else if (smDown) {
      if (pathname === '/user/payments-options') {
        return (
          <Container sx={{ pb: 6 }}>
            <QuestionSection faqCategory={'4'} />
          </Container>
        );
      } else if (pathname === '/user/claims') {
        return tipsContent();
      } else return;
    } else if (mdDown) {
      return (
        <>
          {pathname === '/user/purchases' && (
            <Container maxWidth={'md'} style={{ paddingTop: '48px' }}>
              <QuestionSection faqCategory={'3'} />
            </Container>
          )}
          {pathname === '/user/payments' && (
            <Container maxWidth={'md'} style={{ paddingTop: '48px' }}>
              <QuestionSection faqCategory={'4'} />
            </Container>
          )}
          {pathname === '/user/payments-options' && (
            <Container maxWidth={'md'} style={{ paddingTop: '48px' }}>
              <QuestionSection faqCategory={'4'} />
            </Container>
          )}
          {pathname !== '/user/purchases' &&
            pathname !== '/user/payments' &&
            pathname !== '/user/payments-options' &&
            pathname !== '/user/claims' &&
            pathname !== '/user/personal-info' &&
            pathname !== '/user/contact-options' &&
            pathname !== '/user/tell-a-friend' && (
              <Container style={{ paddingBottom: 2, paddingTop: 16 }}>
                <QuestionSection />
              </Container>
            )}
          {pathname === '/user/claims' && tipsContent()}
          {!userData?.has_used_extension && !mdDown && <AlertSection />}
        </>
      );
    } else {
      return (
        <>
          {pathname === '/user/purchases' && (
            <Container maxWidth={'md'} style={{ paddingTop: '48px' }}>
              <QuestionSection faqCategory={'3'} />
            </Container>
          )}
          {pathname === '/user/payments' && (
            <Container maxWidth={'md'} style={{ paddingTop: '48px' }}>
              <QuestionSection faqCategory={'4'} />
            </Container>
          )}
          {pathname === '/user/payments-options' && (
            <Container maxWidth={'md'} style={{ paddingTop: '48px' }}>
              <QuestionSection faqCategory={'4'} />
            </Container>
          )}
          {pathname !== '/user/purchases' &&
            pathname !== '/user/payments' &&
            pathname !== '/user/payments-options' &&
            pathname !== '/user/claims' &&
            pathname !== '/user/personal-info' &&
            pathname !== '/user/contact-options' &&
            pathname !== '/user/tell-a-friend' && (
              <Container maxWidth={'md'} style={{ paddingTop: '48px' }}>
                <QuestionSection />
              </Container>
            )}
          {pathname === '/user/claims' && tipsContent()}
          {!userData?.has_used_extension && !mdDown && <AlertSection />}
          <PigogoFooter />
        </>
      );
    }
  };

  return (
    <IonPage>
      {menuMobile()}
      <IonContent scrollEvents={!userDataLoading} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        <Layout>
          <>
            <CustomContainer sx={{ maxWidth: '1088px' }}>
              {!mdDown && (
                <Box>
                  <Typography color={'#313D53'} variant="h4" component="h2" pt={4} pb={4}>
                    Ο λογαριασμός μου
                  </Typography>
                </Box>
              )}
              <Box display="flex" gap={smDown ? 0 : 4} justifyContent={smDown ? 'center' : 'space-between'}>
                {!mdDown && (
                  <Box>
                    <SidebarMenu
                      pathname={pathname}
                      userData={userData}
                      handleLogout={handleLogout}
                      userDataLoading={userDataLoading}
                      handleMenuClick={handleMenuClick}
                    />
                  </Box>
                )}
                <Box flexGrow={1} width={'100%'} maxWidth={!mdDown ? 'calc(100% - 296px)' : '100%'}>
                  <Switch>
                    <Route exact path="/user/overview" component={Overview} />
                    <Route exact path="/user/claims" component={Claims} />
                    <Route exact path="/user/payments" component={Payments} />
                    <Route exact path="/user/purchases" component={Purchases} />
                    <Route exact path="/user/tell-a-friend" component={TellAFriend} />
                    <Route exact path="/user/personal-info" component={PersonalInfo} />
                    <Route exact path="/user/contact-options" component={ContactOptions} />
                    <Route exact path="/user/payments-options" component={PaymentsOptions} />
                  </Switch>
                </Box>
              </Box>
            </CustomContainer>
            {extraSections()}
            {(mobile || tablet) && (
              <StartStepsAlert
                open={startStep}
                setOpen={setStartStep}
                title="Δες πόσο εύκολα μπορείς να κερδίζεις επιστροφή χρημάτων."
                actionButton={
                  <PigogoButton fullWidth text="Ας ξεκινήσουμε" variation="white" onClick={handleLetsStart} />
                }
              />
            )}
            {(mobile || tablet) && (
              <WalkthroughModal
                open={walkthrough}
                handleClose={() => {
                  setStartStep(false);
                  setWalkthrough(false);
                }}
              />
            )}
          </>
        </Layout>
      </IonContent>
    </IonPage>
  );
};

export default UserLayout;
