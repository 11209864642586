import { Box, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import TellAFriendCard from './TellAFriendCard';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PigogoSmallButton, PigogoTextArea, PigogoTextField, PigogoTooltip, theme } from 'components';
import { ReactComponent as Mail } from '../../../assets/svgs/illustrations/tellAFriend/mail.svg';
import { ReactComponent as InfoEmail } from '../../../assets/svgs/infoEmail.svg';
import { ReactComponent as Success } from '../../../assets/svgs/illustrations/successNew.svg';
import { ReactComponent as SuccessSmall } from '../../../assets/svgs/illustrations/successSmall.svg';
import { useTellAFriendMutation } from '../../../redux/api/mutations/tellAFriendMutation';
import { useGetUserDataQuery } from '../../../redux/api/queries/userQueries';
import { emailValid } from '../../../utils/validation';
import TellAFriendModal from '../../Navigation/Modals/TellAFriendModal';

const StyleSuccessBox = styled(Box)(() => ({
  top: '48px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    left: 0,
    top: '270px',
  },
}));

const placeHolderMsg =
  'Το ήξερες ότι μπορείς να κερδίζεις επιστροφή χρημάτων από τις αγορές που κάνεις online, μέσω του Pigogo; Εγώ το χρησιμοποιώ ήδη! Μπες μέσω του link που θα βρεις παρακάτω και γράψου, είναι δωρεάν! \n';

const ShareWithEmail = () => {
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [openTooltip, setOpenTooltip] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [message, setMessage] = useState<string>(placeHolderMsg);

  const { data: userData } = useGetUserDataQuery();

  const [tellAFriend, { isSuccess: emailSuccess, isLoading: emailLoading, error }] = useTellAFriendMutation();
  const [openTellAFriend, setOpenTellAFriend] = useState<boolean>(false);

  const mouseOver = () => {
    setOpenTooltip((previousOpen) => !previousOpen);
  };

  const mouseOut = () => {
    setOpenTooltip(false);
  };

  useEffect(() => {
    if (emailSuccess) {
      setSuccess(true);
      setEmail('');
      setMessage(placeHolderMsg);
      const timeout = setTimeout(() => {
        setSuccess(false);
        clearTimeout(timeout);
      }, 4000);
    }

    if (error) {
      setEmailError('Κάτι πήγε στραβά. Προσπάθησε ξανά');
    }
  }, [emailSuccess, error]);

  const handleSendEmail = () => {
    let hasError = false;

    if (email === '') {
      setEmailError('Το πεδίο είναι υποχρεωτικό');
      hasError = true;
    } else {
      setEmailError('');
    }

    if (message === '') {
      setErrorMessage('Γράψε εδώ το μήνυμά σου');
      hasError = true;
    } else {
      setErrorMessage('');
    }

    const cleanEmails = email.split(',').map((email) => email.replace(/\s+/g, ''));

    const areValid = cleanEmails.every((email) => {
      return emailValid(email);
    });

    if (!areValid) {
      if (cleanEmails.length > 1) {
        setEmailError('Κάποιο email δεν είναι σωστό.');
      } else {
        setEmailError('Το email δεν είναι σωστό.');
      }
      hasError = true;
    }

    if (message.trim().length < 6) {
      setErrorMessage('Το μήνυμα πρέπει να αποτελείται από τουλάχιστον 6 χαρακτήρες.');
      hasError = true;
    } else {
      setErrorMessage('');
    }

    if (
      (!userData?.full_name || userData?.full_name === '' || userData?.full_name === ' ') &&
      message.trim().length >= 6
    ) {
      setOpenTellAFriend(true);
      hasError = true;
    }

    if (hasError) {
      return;
    }

    tellAFriend({ email: email, message_content: message });
  };

  const successCard = () => {
    return (
      <StyleSuccessBox>
        <Box style={{ justifyContent: 'center', textAlign: 'center' }}>{mobile ? <SuccessSmall /> : <Success />}</Box>

        <Box
          width={'100%'}
          pt={mobile ? 3 : 6}
          pb={mobile ? 3 : 6}
          maxWidth={mobile ? '343px' : '664px'}
          sx={{ backgroundColor: '#FEF6F8', borderRadius: '24px' }}
        >
          <Box display={'flex'} justifyContent={'center'}>
            <Typography
              textAlign={'center'}
              style={{
                fontSize: 20,
                fontWeight: 700,
                color: '#1D2532',
                lineHeight: '24px',
                width: mobile ? '195px' : undefined,
              }}
            >
              Το email στάλθηκε με επιτυχία!
            </Typography>
          </Box>
        </Box>
      </StyleSuccessBox>
    );
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <TellAFriendModal open={openTellAFriend} handleClose={() => setOpenTellAFriend(false)} />
      {success && !mobile && successCard()}
      <Box sx={{ opacity: success && !mobile ? '10%' : 'unset' }}>
        <TellAFriendCard title="Μέσω email" img={<Mail style={{ width: '64px', height: '64px' }} />}>
          <Typography color="#6F7787" variant="body2MediumR" component="p">
            Στείλε το παρακάτω μήνυμα μέσω email στους φίλους σου. Αν οι φίλοι σου τελικά εγγραφούν στο Pigogo (μέσω του
            email σου), αυτό θα καταγραφεί στον λογαριασμό σου!
          </Typography>
          {success && mobile && successCard()}
          <Box display={'flex'} flexDirection={'column'} gap={3} sx={{ opacity: success && mobile ? '10%' : 'unset' }}>
            <Box display="flex" gap={'18.25px'} alignItems="flex-start">
              <PigogoTextField
                label="Email"
                value={email}
                fullWidth={mobile}
                setValue={(value: string) => {
                  setEmailError('');
                  setEmail(value);
                }}
                error={!!emailError}
                helperText={emailError}
                sx={{ minWidth: !mobile ? '316px' : undefined }}
              />
              <Box
                onMouseOver={mouseOver}
                onMouseOut={mouseOut}
                sx={{
                  width: '24px',
                  height: '24px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 2,
                }}
              >
                <PigogoTooltip
                  open={openTooltip}
                  onClose={mouseOut}
                  title={'Γράψε τα email των φίλων σου, το ένα μετά το άλλο, διαχωρίζοντας τα με κόμμα'}
                >
                  <InfoEmail />
                </PigogoTooltip>
              </Box>
            </Box>
            <style>
              {`
                textarea {
                  min-height: 40px;
                }
                @media only screen and (max-width: 599px) {
                  textarea {
                    min-height: 54px;
                  }
                }
                `}
            </style>
            <PigogoTextArea
              label="Μήνυμα"
              value={message}
              setValue={(value: string) => {
                setErrorMessage('');
                setMessage(value);
              }}
              error={errorMessage !== ''}
              helperText={errorMessage}
              inputProps={{ multiline: true, rows: 6, sx: { maxWidth: '664px', width: '100%' } }}
            />
            <Box display="flex" gap={2} alignItems="center">
              <PigogoSmallButton
                text="Αποστολή email"
                sx={{ px: 3, width: '154px' }}
                onClick={() => handleSendEmail()}
                typographyProps={{ variant: 'subtitle1SmallM', lineHeight: '24px' }}
              />
              {emailLoading && <CircularProgress color="primary" sx={{ height: '30px', width: '30px' }} />}
            </Box>
          </Box>
        </TellAFriendCard>
      </Box>
    </Box>
  );
};

export default ShareWithEmail;
