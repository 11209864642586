import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Chip, Divider, Paper, Typography, useMediaQuery, Container, Skeleton } from '@mui/material';
import { PigogoButton, PigogoSmallButton, theme } from 'components';
import {
  useGetUserDataQuery,
  useGetUserClaimsShopsQuery,
  useLazyGetUserClaimsQuery,
} from '../../redux/api/queries/userQueries';
import { ReactComponent as Close } from '../../assets/svgs/vectors/close.svg';
import { useIonRouter } from '@ionic/react';
import { ReactComponent as SearchPositive } from '../../assets/svgs/illustrations/cart.svg';
import { ReactComponent as Plus } from '../../assets/svgs/plus.svg';
import MultipleDropdown from './MultipleDropdown';
import { Claim } from '../../models/Claims';
import ClaimModal from './ClaimModal';
import ClaimsFilterModal from './ClaimsFilterModal';
import { ClaimSort } from '../../redux/api/types/enum/ClaimSort';
import ClaimsTableDesktop from './Claims/ClaimsTableDesktop';
import ClaimsTableMobile from './Claims/ClaimsTableMobile';
import { ClaimStatusId } from '../../models/ClaimStatusId';
import Pagination from '../Stores/Pagination';
import { useDispatch } from 'react-redux';
import { setSnackBar } from '../../redux/slices/layoutSlice';
import WarningMessage from './Overview/WarningMessage';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import UserTablesRefresher from './UserTablesRefresher';
import PurchasesLookingForPurchaseBox from './Purchases/PurchasesLookingForPurchaseBox';

const claimStatuses = [
  { label: 'Σε επεξεργασία', value: ClaimStatusId.validated },
  { label: 'Εγκρίθηκε', value: ClaimStatusId.paid },
  { label: 'Δεν εγκρίθηκε', value: ClaimStatusId.cancelled },
];

const Claims = () => {
  const router = useIonRouter();
  const dispatch = useDispatch();

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [claimsList, setClaimsList] = useState<Claim[]>([]);
  const [claimModal, setClaimModal] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<ClaimSort>(ClaimSort.submission_date);
  const [filtersModal, setFiltersModal] = useState<boolean>(false);
  const [selectedClaim, setSelectedClaim] = useState<Claim | null>(null);
  const [selectedShops, setSelectedShops] = useState<{ label: string; value: number }[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<{ label: string; value: ClaimStatusId }[]>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(true);

  const cursorRef = useRef<string | null | undefined>(null);
  const appendData = useRef<boolean>(false);

  const [trigger, { data: claims, isFetching, isError: claimsError, isSuccess }] = useLazyGetUserClaimsQuery({});
  const { data: claimShops, isLoading: claimShopsLoading, isError: claimShopsError } = useGetUserClaimsShopsQuery();
  const { data: userData, isLoading: userDataLoading, isError: userDataError } = useGetUserDataQuery();

  const [isLoadingClaims, setIsLoadingClaims] = useState<boolean>(true);
  const [isRefreshingClaims, setIsRefreshingClaims] = useState<boolean>(false);

  const getFormattedShops = useCallback(() => {
    if (claimShops) {
      return claimShops.data.map((shop) => {
        return { label: shop.shop_name, value: shop.shop_id };
      });
    }
    return [];
  }, [claimShops]);

  useEffect(() => {
    if (claimsError || claimShopsError) {
      const params = { value: true, msg: 'Κάτι πήγε στραβά με τα αιτήματα.', severity: 'error' };
      dispatch(setSnackBar(params));
    } else {
      const params = { value: false, msg: '' };
      dispatch(setSnackBar(params));
    }
  }, [claimsError, claimShopsError]);

  useEffect(() => {
    if (!isSuccess || isFetching) return;
    if (claims && !isRefreshingClaims) {
      if (claims) {
        if (appendData.current) {
          setClaimsList(claimsList.concat(claims.data));
        } else {
          setClaimsList(claims.data);
        }
        cursorRef.current = claims.nextCursor;
      }
    } else if (claims && isRefreshingClaims) {
      setClaimsList(claims.data);
    }
    setIsLoadingClaims(false);
    setIsRefreshingClaims(false);
    setIsProcessing(false);
  }, [isFetching, claims]);

  useEffect(() => {
    setIsProcessing(true);
    appendData.current = false;
    cursorRef.current = null;
    setIsLoadingClaims(true);
    callTrigger();
  }, [order, orderBy, selectedShops, selectedStatuses]);

  const callTrigger = async () => {
    await trigger(
      {
        limit: 25,
        order: order,
        sort: orderBy,
        nextCursor: claims ? cursorRef.current : undefined,
        shop: selectedShops.length ? selectedShops.map((shop) => shop.value.toString()).join(',') : undefined,
        status: selectedStatuses.length
          ? selectedStatuses.map((status) => status.value.toString()).join(',')
          : undefined,
      },
      true,
    );
  };

  const hasNoNextPage = () => {
    return !!(claims && claims.totalCount && claimsList.length >= claims.totalCount);
  };

  const loadData = async (ev?: any) => {
    appendData.current = true;
    setIsLoadingClaims(true);
    await callTrigger();
    if (ev) ev.target.complete();
  };

  const handleTableRowClick = (purchase: Claim) => {
    setClaimModal(true);
    setSelectedClaim(purchase);
  };

  const handleRemoveShop = (value: number) => {
    setSelectedShops(selectedShops.filter((e) => e.value !== value));
  };

  const handleRemoveClaim = (value: number) => {
    setSelectedStatuses(selectedStatuses.filter((e) => e.value !== value));
  };

  const getSubTitle = () => {
    if (!mobile) {
      if (claimShopsLoading) {
        return '';
      }
      if (claimsList.length === 0 && (selectedShops.length > 0 || selectedStatuses.length > 0)) {
        return (
          <Typography variant="subtitle1SmallL" component="p" color="#313D53">
            Δεν βρέθηκαν αιτήματα
          </Typography>
        );
      }
      if (claimsList.length === 0 && !isProcessing) {
        return (
          <Typography variant="subtitle1SmallL" component="p" color="#313D53">
            Δεν έχεις καταχωρήσει ακόμα κάποιο αίτημα.
          </Typography>
        );
      }
    } else {
      if (claimShopsLoading) {
        return '';
      }
      if (claimsList.length === 0 && (selectedShops.length > 0 || selectedStatuses.length > 0)) {
        return (
          <Box display="flex" justifyContent="center" px={3}>
            <Typography width="279px" variant="body2" color={'#313D53'} component="h3">
              Δεν βρέθηκαν αιτήματα.
            </Typography>
          </Box>
        );
      }
      if (claimsList.length === 0 && !isProcessing) {
        return (
          <Box display="flex" justifyContent="center" px={3}>
            <Typography width="279px" variant="body2" color={'#313D53'} component="h3">
              Δεν έχεις καταχωρήσει ακόμα κάποιο αίτημα.
            </Typography>
          </Box>
        );
      }
    }
  };

  const renderChipsList = (desktop: boolean) => {
    return (
      <>
        {(selectedShops.length > 0 || selectedStatuses.length > 0) && (
          <Box display="flex" flexWrap="wrap" gap={1} pt={0} pb={5} px={desktop ? 3 : 0}>
            {selectedShops.map((shop, index) => (
              <Chip
                key={index}
                label={shop?.label}
                deleteIcon={<Close />}
                sx={{
                  fontSize: mdDown ? '14px' : '12px',
                  fontWeight: 500,
                  lineHeight: '1.33',
                  backgroundColor: '#EAECEE',
                  color: '#313D53',
                  padding: mdDown ? '8px 12px 8px 12px' : '4px 8px 4px 12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '4px',
                  borderRadius: '32px',
                  minHeight: mdDown ? '40px' : '32px',
                  '& .MuiChip-deleteIcon': {
                    width: '24px',
                    height: '24px',
                    margin: '0',
                    color: '#313D53',
                    padding: mdDown ? '0' : '3px',
                  },
                  '& .MuiChip-label': {
                    paddingRight: '0px',
                    paddingLeft: '0px',
                  },
                }}
                onDelete={() => handleRemoveShop(shop.value)}
              />
            ))}
            {selectedStatuses.map((item, index) => (
              <Chip
                key={index}
                label={item?.label}
                deleteIcon={<Close />}
                sx={{
                  fontSize: mdDown ? '14px' : '12px',
                  fontWeight: 500,
                  lineHeight: '1.33',
                  backgroundColor: '#EAECEE',
                  color: '#313D53',
                  padding: mdDown ? '8px 12px 8px 12px' : '4px 8px 4px 12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '4px',
                  borderRadius: '32px',
                  minHeight: mdDown ? '40px' : '32px',
                  '& .MuiChip-deleteIcon': {
                    width: '24px',
                    height: '24px',
                    margin: '0',
                    color: '#313D53',
                    padding: mdDown ? '0' : '3px',
                  },
                  '& .MuiChip-label': {
                    paddingRight: '0px',
                    paddingLeft: '0px',
                  },
                }}
                onDelete={() => handleRemoveClaim(item.value)}
              />
            ))}
          </Box>
        )}
      </>
    );
  };

  const renderFiltersMobile = () => {
    return (
      (claimsList.length > 0 || selectedShops.length > 0 || selectedStatuses.length > 0) && (
        <>
          <Box
            display="flex"
            pt={claimsList.length > 0 ? 5 : 4}
            pb={claimsList.length > 0 ? 2 : 0}
            mb={claimsList.length > 0 ? 2 : 0}
            flexDirection={'column'}
            gap={2}
            alignItems="flex-start"
            borderBottom={claimsList.length && '1px solid #EAECEE'}
          >
            <Box display="flex" justifyContent="space-between">
              <PigogoSmallButton
                variant={'outlined'}
                text={'Φίλτρα & Ταξινόμηση'}
                onClick={() => setFiltersModal(true)}
                typographyProps={{ variant: 'subtitle1SmallR' }}
                sx={{ fontSize: '14px', fontWeight: 400, height: '42px' }}
              />
            </Box>
            {renderChipsList(false)}
          </Box>
        </>
      )
    );
  };

  const renderMobileView = () => {
    if (isLoadingClaims || isRefreshingClaims || claimsList.length) {
      return (
        <Box>
          <Box
            display="flex"
            pt={5}
            pb={2}
            mb={2}
            borderBottom={'1px solid #EAECEE'}
            flexDirection={'column'}
            gap={2}
            alignItems="flex-start"
          >
            <PigogoSmallButton
              variant={'outlined'}
              text={'Φίλτρα & Ταξινόμηση'}
              onClick={() => setFiltersModal(true)}
              typographyProps={{ variant: 'subtitle1SmallR' }}
              sx={{ fontSize: '14px', fontWeight: 400, height: '42px' }}
            />
            {(selectedShops.length > 0 || selectedStatuses.length > 0) && (
              <Box display="flex" flexWrap="wrap" gap={1} pt={0} pb={0} px={0}>
                {selectedShops.map((shop, index) => (
                  <Chip
                    deleteIcon={<Close />}
                    sx={{
                      fontSize: mdDown ? '14px' : '12px',
                      fontWeight: 500,
                      lineHeight: '1.33',
                      backgroundColor: '#EAECEE',
                      color: '#313D53',
                      padding: mdDown ? '8px 12px 8px 12px' : '4px 8px 4px 12px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '4px',
                      borderRadius: '32px',
                      minHeight: mdDown ? '40px' : '32px',
                      '& .MuiChip-deleteIcon': {
                        width: '24px',
                        height: '24px',
                        margin: '0',
                        color: '#313D53',
                        padding: mdDown ? '0' : '3px',
                      },
                      '& .MuiChip-label': {
                        paddingRight: '0px',
                        paddingLeft: '0px',
                      },
                    }}
                    key={index}
                    label={shop?.label}
                    onDelete={() => handleRemoveShop(shop.value)}
                  />
                ))}
                {selectedStatuses.map((shop, index) => (
                  <Chip
                    deleteIcon={<Close />}
                    sx={{
                      fontSize: mdDown ? '14px' : '12px',
                      fontWeight: 500,
                      lineHeight: '1.33',
                      backgroundColor: '#EAECEE',
                      color: '#313D53',
                      padding: mdDown ? '8px 12px 8px 12px' : '4px 8px 4px 12px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '4px',
                      borderRadius: '32px',
                      minHeight: mdDown ? '40px' : '32px',
                      '& .MuiChip-deleteIcon': {
                        width: '24px',
                        height: '24px',
                        margin: '0',
                        color: '#313D53',
                        padding: mdDown ? '0' : '3px',
                      },
                      '& .MuiChip-label': {
                        paddingRight: '0px',
                        paddingLeft: '0px',
                      },
                    }}
                    key={index}
                    label={shop?.label}
                    onDelete={() => handleRemoveClaim(shop.value)}
                  />
                ))}
              </Box>
            )}
          </Box>

          <UserTablesRefresher
            setIsRefreshingData={setIsRefreshingClaims}
            trigger={async () => {
              return await trigger(
                {
                  limit: 25,
                  order: order,
                  sort: orderBy,
                  nextCursor: undefined,
                  shop: selectedShops.length ? selectedShops.map((shop) => shop.value.toString()).join(',') : undefined,
                  status: selectedStatuses.length
                    ? selectedStatuses.map((status) => status.value.toString()).join(',')
                    : undefined,
                },
                false,
              );
            }}
          ></UserTablesRefresher>

          {!isLoadingClaims && !isRefreshingClaims && (
            <ClaimsTableMobile claims={claimsList} onRowClick={handleTableRowClick} />
          )}
          {(isLoadingClaims || isRefreshingClaims) && <ClaimsTableMobile isLoading />}
          <Box mb={3}>
            {!isLoadingClaims && (
              <Pagination
                currentCount={claims ? claimsList.length : 0}
                total={claims?.totalCount ?? 0}
                nextPage={claims?.nextCursor}
                loadMore={loadData}
              />
            )}
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            pt={2}
            pb={2}
            sx={{ position: 'sticky', bottom: '0px', backgroundColor: 'transparent' }}
          >
            <Link
              className="pigogoButton pigogoButton--outlined pigogoButton--icon pigogoButton--small pigogoButton--rounded"
              to={'/create-claim'}
              style={{ height: '56px', paddingBlock: '16px', paddingInline: '24px', background: '#ffffff' }}
            >
              <span>Δημιουργία αιτήματος</span>
              <Plus />
            </Link>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box py={mdDown ? '0 16px' : 5}>
          <UserTablesRefresher
            setIsRefreshingData={setIsRefreshingClaims}
            trigger={async () => {
              return await trigger(
                {
                  limit: 25,
                  order: order,
                  sort: orderBy,
                  nextCursor: undefined,
                  shop: selectedShops.length ? selectedShops.map((shop) => shop.value.toString()).join(',') : undefined,
                  status: selectedStatuses.length
                    ? selectedStatuses.map((status) => status.value.toString()).join(',')
                    : undefined,
                },
                false,
              );
            }}
          ></UserTablesRefresher>
          {userData?.user_warning_message && (
            <Box display="flex" flexDirection="column">
              <WarningMessage />
            </Box>
          )}

          {selectedShops.length > 0 ? (
            <Box display="flex" pt={4} pb={2} justifyContent="space-between">
              <PigogoSmallButton
                variant={'outlined'}
                text={'Φίλτρα & Ταξινόμηση'}
                onClick={() => setFiltersModal(true)}
                typographyProps={{ variant: 'subtitle1SmallR' }}
                sx={{ fontSize: '14px', fontWeight: 400, height: '42px' }}
              />
            </Box>
          ) : (
            ''
          )}

          {renderChipsList(false)}
          <Box sx={{ textAlign: 'center', paddingTop: 5 }}>
            <SearchPositive />
            {getSubTitle()}
          </Box>

          <PurchasesLookingForPurchaseBox />
        </Box>
      );
    }
  };

  const renderMobile = () => {
    if (isLoadingClaims || isRefreshingClaims || claimsList.length) {
      return (
        <Box>
          <UserTablesRefresher
            setIsRefreshingData={setIsRefreshingClaims}
            trigger={async () => {
              return await trigger(
                {
                  limit: 25,
                  order: order,
                  sort: orderBy,
                  nextCursor: undefined,
                  shop: selectedShops.length ? selectedShops.map((shop) => shop.value.toString()).join(',') : undefined,
                  status: selectedStatuses.length
                    ? selectedStatuses.map((status) => status.value.toString()).join(',')
                    : undefined,
                },
                false,
              );
            }}
          ></UserTablesRefresher>
          {renderFiltersMobile()}
          {!(isLoadingClaims || isRefreshingClaims) && (
            <ClaimsTableMobile claims={claimsList} onRowClick={handleTableRowClick} />
          )}
          {(isLoadingClaims || isRefreshingClaims) && <ClaimsTableMobile isLoading />}
          <Box mb={4}>
            {!isLoadingClaims && (
              <Pagination
                currentCount={claims ? claimsList.length : 0}
                total={claims?.totalCount ?? 0}
                nextPage={claims?.nextCursor}
                loadMore={loadData}
              />
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            pt={2}
            pb={2}
            sx={{ position: 'sticky', bottom: '0px', backgroundColor: 'transparent' }}
          >
            <Link
              className="pigogoButton pigogoButton--outlined pigogoButton--icon pigogoButton--small pigogoButton--rounded"
              to={'/create-claim'}
              style={{ height: '56px', paddingBlock: '16px', paddingInline: '24px', background: '#ffffff' }}
            >
              <span>Δημιουργία αιτήματος</span>
              <Plus />
            </Link>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box textAlign="center" pb={5}>
          <UserTablesRefresher
            setIsRefreshingData={setIsRefreshingClaims}
            trigger={async () => {
              return await trigger(
                {
                  limit: 25,
                  order: order,
                  sort: orderBy,
                  nextCursor: undefined,
                  shop: selectedShops.length ? selectedShops.map((shop) => shop.value.toString()).join(',') : undefined,
                  status: selectedStatuses.length
                    ? selectedStatuses.map((status) => status.value.toString()).join(',')
                    : undefined,
                },
                false,
              );
            }}
          ></UserTablesRefresher>
          {renderFiltersMobile()}
          <Box pt={5}>
            <SearchPositive />
          </Box>
          {getSubTitle()}

          <PurchasesLookingForPurchaseBox />
        </Box>
      );
    }
  };

  const renderFiltersDesktop = () => {
    return (
      <>
        {(claimShopsLoading || isLoadingClaims || claimsList.length > 0) && (
          <>
            <Box p={3} display="flex" gap={3}>
              {(claimShopsLoading || isLoadingClaims) && !claimsList.length && (
                <Skeleton width="200px" height="46px" sx={{ borderRadius: '30px' }} variant="rectangular" />
              )}
              {(claimShopsLoading || isLoadingClaims) && !claimsList.length && (
                <Skeleton width="200px" height="46px" sx={{ borderRadius: '30px' }} variant="rectangular" />
              )}
              {((!claimShopsLoading && !isLoadingClaims) || claimsList.length > 0) && (
                <MultipleDropdown
                  claims
                  width="200px"
                  label="Κατάστημα"
                  renderValue={(elems: number) => {
                    return elems === 1 ? `${elems} επιλεγμένο` : `${elems} επιλεγμένα`;
                  }}
                  options={getFormattedShops()}
                  optionsChecked={selectedShops}
                  setOptionsChecked={setSelectedShops}
                />
              )}
              {((!claimShopsLoading && !isLoadingClaims) || claimsList.length > 0) && (
                <MultipleDropdown
                  width="200px"
                  label="Κατάσταση"
                  renderValue={(elems: number) => {
                    return elems === 1 ? `${elems} επιλεγμένη` : `${elems} επιλεγμένες`;
                  }}
                  options={claimStatuses}
                  optionsChecked={selectedStatuses}
                  setOptionsChecked={setSelectedStatuses}
                />
              )}
            </Box>
            {(selectedStatuses.length > 0 || selectedShops.length > 0) && (
              <Box display="flex" textAlign={'left'} gap={3} pb={5}>
                {renderChipsList(true)}
              </Box>
            )}
          </>
        )}
        {!(claimShopsLoading || isLoadingClaims || claimsList.length > 0) && (
          <Box pt={2} display="flex" textAlign={'left'} gap={3}>
            {renderChipsList(true)}
          </Box>
        )}
      </>
    );
  };

  const renderDesktopView = () => {
    return (
      <Box pt={tablet ? 4 : 0}>
        <Box border="2px solid #EAECEE" borderRadius="24px">
          <Box p={'20px 24px 19px'} display="flex" justifyContent="space-between" borderBottom="1px solid #D6D8DD">
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="large900" component="h3" color={'#1D2532'}>
                Αιτήματα
              </Typography>
              {getSubTitle()}
            </Box>
            {claimsList.length > 0 ? (
              <Box display="flex" gap={'16.5px'}>
                <Divider orientation="vertical" />
                <Link
                  className="pigogoButton pigogoButton--outlined pigogoButton--icon pigogoButton--small pigogoButton--rounded"
                  to={'/create-claim'}
                  style={{ width: '229px' }}
                >
                  <span>Δημιουργία αιτήματος</span>
                  <Plus />
                </Link>
              </Box>
            ) : null}
          </Box>
          {renderFiltersDesktop()}
          {isLoadingClaims || (!claimsList.length && isProcessing) || claimsList.length ? (
            <ClaimsTableDesktop
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              claims={claimsList}
              isLoading={isLoadingClaims}
              setOrderBy={setOrderBy}
              onRowClick={handleTableRowClick}
            />
          ) : (
            <Box p={'8px 24px 24px'} textAlign="center">
              <SearchPositive />
            </Box>
          )}
          {claimsList.length > 0 && claims && claimsList.length !== claims?.totalCount && !isLoadingClaims && (
            <Box pt={6} mb={3}>
              <Pagination
                currentCount={claims ? claimsList.length : 0}
                total={claims?.totalCount ?? 0}
                nextPage={claims?.nextCursor}
                loadMore={loadData}
              />
            </Box>
          )}
        </Box>
        <Paper elevation={0} sx={{ background: '#F7F7F8', borderRadius: '24px', border: '2px solid #EAECEE', mt: 6 }}>
          <PurchasesLookingForPurchaseBox />
        </Paper>
      </Box>
    );
  };

  const extractView = () => {
    if (!mobile) {
      return renderDesktopView();
    } else if (mobile) {
      return renderMobileView();
    }
  };

  return (
    <Box>
      {location.pathname === '/user/claims' && (
        <Helmet>
          <title>Αιτήματα - Ο λογαριασμός μου | Pigogo</title>
          <meta
            name="description"
            content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
          />
        </Helmet>
      )}
      {userData?.user_warning_message && (
        <Box display="flex" flexDirection="column" mt={mobile || tablet ? 2 : undefined} mb={tablet || mobile ? 0 : 2}>
          <WarningMessage />
        </Box>
      )}
      {extractView()}
      <ClaimModal
        isOpen={claimModal}
        setOpen={setClaimModal}
        selectedClaim={selectedClaim}
        setSelectedClaim={setSelectedClaim}
      />
      <ClaimsFilterModal
        sort={order}
        setSort={setOrder}
        claim={selectedStatuses}
        setClaim={setSelectedStatuses}
        selectedShops={selectedShops}
        setSelectedShops={setSelectedShops}
        isOpen={filtersModal}
        setOpen={setFiltersModal}
        shops={getFormattedShops()}
        claimStatuses={claimStatuses}
      />
    </Box>
  );
};

export default Claims;
