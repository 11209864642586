import React, { FC, ReactNode, SyntheticEvent, useState, KeyboardEvent } from 'react';
import { Box, Button, FormControlLabel, FormGroup, styled, Typography, useMediaQuery } from '@mui/material';
import theme from '../theme/index';
import { isPlatform } from '@ionic/react';
import PigogoCheckbox from './PigogoCheckbox';
import { Apple, Facebook, Google, Info } from '../assets';
import PigogoTextField from './PigogoTextField';
import PigogoButton from './PigogoButton';
import PigogoSmallButton from './PigogoSmallButton';
import PigogoLink from './PigogoLink';
import { emailIsValid } from '../utils/emailValidation';

const StyleBox = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  justifyContent: 'center',
  display: 'flex',
  borderRadius: '16px',
  paddingBottom: 32,
  background: theme.palette.error.contrastText,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    paddingBottom: 0,
  },
  [theme.breakpoints.up('sm')]: {
    width: '480px',
  },
}));

interface Props {
  header: string;
  loading: boolean;
  message?: string;
  headerImage?: ReactNode;
  headerDescription?: string;
  onClickGoogle: () => void;
  onClickApple?: () => void;
  onClickSignUp: () => void;
  onClickForgot: () => void;
  onClickFacebook: () => void;
  onClick: (email: string, password: string, rememberMe: boolean) => void;
  footerMessage?: string;
  footerRedir?: () => void;
  setSocialError?: (val: boolean) => void;
  socialError?: boolean;
  footerRedirLink?: string;
}

const PigogoLogin: FC<Props> = (props) => {
  const matches = useMediaQuery(theme.breakpoints.only('xs'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [email, setEmail] = useState<string>('');
  const [rememberMe, setRememberMe] = useState(true);
  const [password, setPassword] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const handleSubmitForm = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!emailIsValid(email) || email.trim() === '') {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    if (password.trim() === '') {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }

    if (emailIsValid(email) && email.trim() !== '' && password.trim() !== '') {
      props.onClick(email, password, rememberMe);
    }
  };

  const enterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmitForm(e);
    }
  };

  const extractHeader = () => {
    if (smDown || props.message) {
      return (
        <Box width={'100%'}>
          {props.message && (
            <Box
              mb={2}
              gap={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                background: '#ECEFF4',
                padding: '16px',
                borderRadius: '8px',
              }}
            >
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Info />
              </Box>
              <Typography variant={smDown ? 'captionM' : 'body2MediumM'} color={'#313D53'} textAlign={'left'}>
                {props.message}
              </Typography>
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant={'body1'} color="#313D53">
              Σύνδεση
            </Typography>
            <Button onClick={props.onClickSignUp}>
              <Typography
                variant={'body2MediumM'}
                color="#313D53"
                sx={{ textDecoration: 'underline', textDecorationColor: '#313D53', textUnderlineOffset: '5px' }}
              >
                Γίνε μέλος
              </Typography>
            </Button>
          </Box>
        </Box>
      );
    } else
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '16px' }}>
          {props.headerImage && <Box>{props.headerImage}</Box>}
          <Box>
            <Typography variant="h4" color="#313D53">
              {props.header}
            </Typography>
          </Box>
          {props.headerDescription && (
            <Box>
              <Typography style={{ fontWeight: 500, fontSize: 16 }} color="#313D53">
                {props.headerDescription}
              </Typography>
            </Box>
          )}
        </Box>
      );
  };

  return (
    <StyleBox>
      <Box display={'flex'} flexDirection={'column'} gap={2} width="100%">
        {extractHeader()}
        <Box>
          <PigogoTextField
            fullWidth
            type={'email'}
            label="Email"
            value={email}
            size={'medium'}
            error={emailError || (props.socialError && !!email)}
            onFocus={() => {
              props.setSocialError && props.setSocialError(false);
              setEmailError(false);
            }}
            setValue={(value: string) => {
              setEmailError(false);
              props.setSocialError && props.setSocialError(false);
              setEmail(value);
            }}
            helperText={
              emailError
                ? !email
                  ? 'Το πεδίο είναι υποχρεωτικό.'
                  : 'Το email δεν φαίνεται να είναι σωστό.'
                : props.socialError && email
                ? 'Υπάρχει ήδη χρήστης με αυτό το email μέσω Facebook ή Google. Δεν μπορείτε να εγγραφείτε ξανά, συνδεθείτε με τον λογαριασμό που έχετε ήδη.'
                : ''
            }
            onKeyPress={enterPress}
          />
        </Box>
        <Box>
          <PigogoTextField
            fullWidth
            label="Κωδικός"
            value={password}
            type={'password'}
            error={passwordError}
            autoComplete={'password'}
            onFocus={() => {
              setPasswordError(false);
            }}
            setValue={(value: string) => {
              setPasswordError(false);
              setPassword(value);
            }}
            helperText={passwordError ? (!password ? 'Το πεδίο είναι υποχρεωτικό.' : 'Ο κωδικός δεν είναι σωστός') : ''}
            onKeyPress={enterPress}
          />
        </Box>
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" pr={3}>
            <FormGroup sx={{ px: 4 }}>
              <FormControlLabel
                control={
                  <PigogoCheckbox
                    inputProps={{ color: 'secondary' }}
                    setValue={() => setRememberMe(!rememberMe)}
                    checked={rememberMe}
                  />
                }
                label={
                  <Typography variant="captionM" color={'#1D2532'}>
                    Να με θυμάσαι
                  </Typography>
                }
              />
            </FormGroup>
            <PigogoLink
              typoProps={{ variant: 'captionM' }}
              color={'primary'}
              background={'primary'}
              colorText={'#1D2532'}
              text={'Ξέχασα τον κωδικό'}
              handleClick={() => props.onClickForgot()}
            />
          </Box>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Box>
            <PigogoButton
              fullWidth
              text={'Σύνδεση'}
              variation="secondary"
              loading={props.loading}
              onClick={handleSubmitForm}
            />
          </Box>
          <Box>
            <Typography
              variant={'subtitle1SmallM'}
              component="p"
              sx={{ fontWeight: 600, lineHeight: '24px', color: '#313D53', textAlign: 'center' }}
            >
              ή
            </Typography>
          </Box>
          <Box display={'flex'} gap={2} flexDirection={smDown ? 'column' : 'row'}>
            <Box width={smDown ? '100%' : '200px'}>
              <PigogoSmallButton
                fullWidth
                variant={'outlined'}
                startIcon={<Facebook />}
                onClick={props.onClickFacebook}
                typographyProps={{ variant: 'subtitle2' }}
                text={smDown ? 'Σύνδεση με Facebook' : 'Facebook'}
                sx={{ justifyContent: smDown ? 'center' : 'flex-start' }}
              />
            </Box>
            <Box width={smDown ? '100%' : '200px'}>
              <PigogoSmallButton
                fullWidth
                variant={'outlined'}
                startIcon={<Google />}
                onClick={props.onClickGoogle}
                typographyProps={{ variant: 'subtitle2' }}
                text={smDown ? 'Σύνδεση με Google' : 'Google'}
                sx={{ justifyContent: smDown ? 'center' : 'flex-start' }}
              />
            </Box>
            {isPlatform('ios') && (
              <Box width={smDown ? '100%' : '200px'}>
                <PigogoSmallButton
                  fullWidth
                  variant={'outlined'}
                  startIcon={<Apple />}
                  onClick={props?.onClickApple}
                  typographyProps={{ variant: 'subtitle2' }}
                  text={smDown ? 'Σύνδεση με Apple' : 'Apple'}
                  sx={{ justifyContent: smDown ? 'center' : 'flex-start' }}
                />
              </Box>
            )}
          </Box>
        </Box>
        {props.onClickSignUp && !smDown && !props.footerMessage && (
          <Box>
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ flexDirection: 'row' }}>
              <Typography color="primary" variant="buttonSmallM" component="p">
                Δεν έχεις λογαριασμό;
              </Typography>
              <Button onClick={props.onClickSignUp}>
                <Typography
                  color="primary"
                  sx={{ textDecoration: 'underline', textDecorationColor: 'primary', textUnderlineOffset: '5px' }}
                  variant="buttonSmallM"
                >
                  Γίνε μέλος τώρα!
                </Typography>
              </Button>
            </Box>
          </Box>
        )}
        {props.footerMessage && (
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              pt={smDown ? 0 : '26px'}
              mt={smDown ? 0 : 2}
              pb={smDown ? '34px' : 0}
            >
              <a href={props.footerRedirLink} target="_blank">
                <Button>
                  <Typography
                    color="primary"
                    sx={{ textDecoration: 'underline', textDecorationColor: 'primary', textUnderlineOffset: '5px' }}
                    variant="buttonSmallM"
                  >
                    {props.footerMessage}
                  </Typography>
                </Button>
              </a>
            </Box>
          </Box>
        )}
      </Box>
    </StyleBox>
  );
};

export default PigogoLogin;
