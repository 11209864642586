import React, { FC, useRef } from 'react';
import { Box, FormControl, Icon, InputAdornment, TextFieldProps } from '@mui/material';
import { ReactComponent as Close } from '../../../../assets/svgs/close.svg';
import { ReactComponent as LinkVertical } from '../../../../assets/svgs/link_Vertical.svg';
import { ReactComponent as Search } from '../../../../assets/svgs/search.svg';
import SearchTextField from '../../../SearchTextField';
import { useIonRouter } from '@ionic/react';
import { useHistory } from 'react-router';

interface Props {
  link: boolean;
  search: string;
  searchPopoverOpen: boolean;
  setLink: (link: boolean) => void;
  setSearch: (value: string) => void;
  setSearchPopover: (value: boolean) => void;
  setSearchKeyword?: (value: string) => void;
}

const PigogoSearch: FC<Props & TextFieldProps> = ({
  search,
  searchPopoverOpen,
  setSearchPopover,
  setSearch,
  link,
  setLink,
  setSearchKeyword,
  ...rest
}) => {
  const history = useHistory();
  const searchRef: any = useRef();

  const handleClick = () => {
    setSearchPopover(true);
    setLink(false);
  };

  const handleValueChange = (e: any) => {
    setSearch(e.target.value);
  };

  const onEnterPressed = () => {
    setSearchPopover(false);
    setTimeout(() => {
      if (search) {
        setSearchKeyword && setSearchKeyword(search);
        history.push(`/search?keyword=${search}`);
      }
    }, 500);
  };

  const handleLinkClick = () => {
    //setSearchPopoverOpen();
    setLink(!link);
  };

  const id = searchPopoverOpen ? 'simple-popover' : undefined;

  return (
    <Box ref={searchRef}>
      <FormControl fullWidth>
        <SearchTextField
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onEnterPressed();
            }
          }}
          id="search"
          placeholder="αναζήτησε κατάστημα, κατηγορία..."
          value={search || null}
          onChange={handleValueChange}
          autoComplete={'one-time-code'}
          onFocus={handleClick}
          fullWidth
          InputProps={{
            endAdornment: (
              <Box display={'flex'} gap={2}>
                {search && (
                  <Box width={'24px'} height={'24px'}>
                    <InputAdornment position={'end'} style={{ cursor: 'pointer' }}>
                      <Icon onClick={() => setSearch('')}>
                        <Close />
                      </Icon>
                    </InputAdornment>
                  </Box>
                )}
                <Box width={'24px'} height={'24px'} onClick={() => handleLinkClick()}>
                  <InputAdornment position={'end'} style={{ cursor: 'pointer' }}>
                    <LinkVertical />
                  </InputAdornment>
                </Box>
              </Box>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <Icon>
                  <Search />
                </Icon>
              </InputAdornment>
            ),
          }}
          {...rest}
        />
      </FormControl>
    </Box>
  );
};

export default PigogoSearch;
