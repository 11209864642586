import { pigogoApi } from '../Api';
import { GenericRequest } from '../../../models/GenericRequest';

interface UnsubscribeParams {
  email: string;
  name: string;
}

interface ChangeUserNotifications {
  email: string;
  notification_newsletter?: boolean;
  notification_new_shop?: boolean;
  notification_new_payment?: boolean;
  notification_new_purchase?: boolean;
}

const unsubscribeApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    patchUnsubscribe: builder.mutation<GenericRequest<any>, UnsubscribeParams>({
      query: (args) => {
        const { email, name } = args;
        return {
          method: 'PATCH',
          url: '/users/actions/unsubscribe',
          params: { email, name },
        };
      },
    }),
    patchUserNotificationsUnsubscribe: builder.mutation<GenericRequest<any>, ChangeUserNotifications>({
      query: (body) => {
        return {
          method: 'PATCH',
          url: '/users/actions/change-notifications',
          params: { ...body },
        };
      },
    }),
  }),
});

export const { usePatchUnsubscribeMutation, usePatchUserNotificationsUnsubscribeMutation } = unsubscribeApi;
