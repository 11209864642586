import React, { FC, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import ContactTextFields from './ContactTextFields';
import CustomContainer from '../CustomContainer';
import { Box, useMediaQuery } from '@mui/material';
import ContactCard from '../ContactCard';
import { PigogoEntryPoint, theme } from 'components';
import { useAppSelector } from '../../redux/hooks';
import { Link } from 'react-router-dom';

interface Props {
  openLoginModal?: () => void;
}

const ContactDesktop: FC<Props> = ({ openLoginModal }) => {
  const router = useIonRouter();
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const width = () => {
    if (downLg && upMd) {
      return 'calc(50% - 16px)';
    }
    if (downMd) {
      return '100%';
    }
  };
  return (
    <CustomContainer>
      <Box style={{ paddingTop: 80 }}>
        <Box
          display={'flex'}
          pb={8}
          gap={downMd ? 2 : 10}
          flexWrap={downMd ? 'wrap' : 'nowrap'}
          justifyContent={'flex-start'}
          sx={{ width: '1024px', maxWidth: '100%', marginInline: 'auto' }}
        >
          <ContactTextFields />
          <ContactCard />
        </Box>
        <CustomContainer
          display={'flex'}
          gap={downMd ? 2 : 4}
          flexWrap={downMd ? 'wrap' : 'nowrap'}
          justifyContent={'center'}
          sx={{ padding: downMd ? '0 0 48px' : '0 32px 130px' }}
        >
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: width(), position: 'relative' }}>
            <Link className="absLink" to={'/faq'}></Link>
            <PigogoEntryPoint
              title={'Συχνές ερωτήσεις'}
              subTitle={'Μπορεί το ερώτημά σου να έχει ήδη απαντηθεί στις συχνές ερωτήσεις.'}
              linkText={'δες περισσότερα'}
              onClick={() => {
                return;
              }}
            />
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: width(), position: 'relative' }}>
            <Link
              className="absLink"
              to={'/create-claim'}
              onClick={() => {
                if (openLoginModal && !(!!accessToken || sessionLoggedIn)) {
                  openLoginModal();
                } else {
                  router.push('/create-claim');
                }
              }}
            ></Link>
            <PigogoEntryPoint
              sx={{ background: '#FDF0F3' }}
              title={'Δημιουργία αιτήματος'}
              subTitle={
                'Ψάχνεις κάτι σχετικό με τις αγορές σου και δεν το βρίσκεις; Μπορείς να δημιουργήσεις ένα αίτημα για να το ερευνήσουμε!'
              }
              linkText={'δες περισσότερα'}
              onClick={() => {
                if (openLoginModal && !(!!accessToken || sessionLoggedIn)) {
                  openLoginModal();
                } else {
                  router.push('/create-claim');
                }
              }}
            />
          </Box>
        </CustomContainer>
      </Box>
    </CustomContainer>
  );
};

export default ContactDesktop;
