import React, { useEffect, useRef, useState } from 'react';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLazySavingsQuery } from '../redux/api/queries/savingsQueries';
import Pagination from '../components/Stores/Pagination';
import OffersCards from '../components/OffersCards';
import { Savings } from '../models/Savings';
import { useDispatch } from 'react-redux';
import { setSnackBar, setStateSnackBar } from '../redux/slices/layoutSlice';
import LoginModal from '../components/Navigation/Modals/LoginModal';
import ForgotModal from '../components/Navigation/Modals/ForgotModal';
import SuccessPasswordModal from '../components/Navigation/Modals/SuccessPasswordModal';
import RegisterModal from '../components/Navigation/Modals/RegisterModal';
import PromoBanner from '../components/Stores/PromoBanner';
import FiltersSection from '../components/Stores/FiltersSection';
import PigogoFooter from '../components/PigogoFooter';
import CustomContainer from '../components/CustomContainer';
import Layout from '../components/Navigation/Layout';
import useQuery from '../hooks/useQuery';
import NoResultsPage from './NoResultsPage';
import backArrow from '../assets/svgs/back_arrow.svg';
import { useHistory } from 'react-router';
import { GenericRequest } from '../models/GenericRequest';
import CustomHeader from '../components/CustomHeader';
import { Helmet } from 'react-helmet-async';
import { usePromosQuery } from '../redux/api/queries/promosQueries';

const Offers = () => {
  const theme = useTheme();
  const params = useQuery();
  const dispatch = useDispatch();
  const router = useIonRouter();

  const path = router.routeInfo.pathname;

  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const appendData = useRef<boolean>(false);
  const infiniteEvent = useRef<HTMLIonInfiniteScrollElement | null>(null);

  const [trigger, { data: savings, isLoading, isFetching, isError: errorSavings, status }] = useLazySavingsQuery();

  const { data: promos, isError: errorPromos } = usePromosQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const [offers, setOffers] = useState<Savings[]>([]);
  const [title, setTitle] = useState<string>('Hot Προσφορές');

  const [message, setMessage] = useState<string>('');
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [forgotModal, setForgotModal] = useState<boolean>(false);
  const [registerModal, setRegisterModal] = useState<boolean>(false);
  const [successForgotModal, setSuccessForgotModal] = useState<boolean>(false);

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const [fetchPrevious, setFetchPrevious] = useState<boolean>(!!(history.location.state as any)?.fetchPrevious);

  const [response, setResponse] = useState<GenericRequest<Savings[]> | undefined>(undefined);
  const [fetchedFromCache, setFetchedFromCache] = useState<boolean>(false);

  const [isLoadingSavings, setIsLoadingSavings] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const [sort, setSort] = useState<string>('p');
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedDiscounts, setSelectedDiscounts] = useState<string[]>([]);

  const [page, setPage] = useState<number>(1);
  const [promo, setPromo] = useState<string | undefined>(undefined);
  const [initialized, setInitialized] = useState<boolean>(false);

  const [resetFilters, setResetFilters] = useState<boolean>(false);

  useIonViewWillEnter(() => {
    if (!mdDown) return;

    if (!localStorage.getItem(`offers`)) {
      setFetchPrevious(false);
      setResetFilters(true);
      return;
    }

    const searchParams = JSON.parse(localStorage.getItem('search-parameters') ?? '{}');
    const searchParam = Object.assign({}, searchParams)[history.location.pathname];

    if (!!searchParam && !history.location.search) {
      searchParams[history.location.pathname] = '';
      localStorage.setItem('search-parameters', JSON.stringify(searchParams));
      history.replace(`${history.location.pathname}${searchParam}`, {
        scrollY: (history.location.state as any)?.scrollY ?? 0,
        fetchPrevious: true,
      });
    }
  });

  const setParameters = async (params: URLSearchParams) => {
    const pageParam = params.get('page');
    const sortParam = params.get('sort');
    const discountParam = params.get('discountTypes');
    const categoryParam = params.get('categories');
    const promoParam = path.includes('/promo') ? path.split('/').slice(-1)[0] : null;

    await Promise.all([
      new Promise((res) => {
        res(setPage(pageParam ? Number(pageParam) : 1));
      }),
      new Promise((res) => {
        res(setSort(sortParam ?? 'p'));
      }),
      new Promise((res) => {
        res(categoryParam !== '' && setSelectedCategories(categoryParam?.split(',') ?? []));
      }),
      new Promise((res) => {
        res(discountParam !== '' && setSelectedDiscounts(discountParam?.split(',') ?? []));
      }),
      new Promise((res) => {
        res(setPromo(promoParam ?? undefined));
      }),
    ]);
  };

  useIonViewDidEnter(() => {
    const params = new URLSearchParams(history.location.search);

    const setParametersAsync = async (params: URLSearchParams) => {
      await setParameters(params);
    };
    ((!!promo && history.location.pathname.includes('offers')) ||
      history.location.pathname.includes('promo') ||
      !fetchPrevious) &&
      setParametersAsync(params);
    setInitialized(true);

    if (!mdDown) return;
    const scrollPos = JSON.parse(localStorage.getItem('scroll-positions') ?? '{}');
    const scrollPosition = Object.assign({}, scrollPos)[history.location.pathname];

    setTimeout(() => {
      !!scrollPosition && contentRef && contentRef.current && contentRef.current.scrollToPoint(0, scrollPosition, 10);
    }, 300);
  });

  useIonViewWillLeave(() => {
    setFetchPrevious(true);
  });

  function handleScrollEnd(e: any) {
    if (history.location.pathname === '/offers') {
      history.replace(`${router.routeInfo.pathname}?${params}`, {
        scrollY: e.target.detail.scrollTop,
        fetchPrevious: (history.location.state as any)?.fetchPrevious,
      });
    }
  }

  useEffect(() => {
    if (isLoadingSavings) return;

    const scrollPos = JSON.parse(localStorage.getItem('scroll-positions') ?? '{}');
    const scrollPosition = Object.assign({}, scrollPos)[history.location.pathname];

    const scroll = mdDown ? scrollPosition ?? 0 : (history.location.state as any)?.scrollY;
    setTimeout(
      () => {
        (history.location.state as any)?.scrollY &&
          contentRef &&
          contentRef.current &&
          contentRef.current.scrollToPoint(0, scroll, 10);
      },
      mdDown ? 300 : 600,
    );
  }, [isLoadingSavings]);

  useEffect(() => {
    if (!initialized) return;
    const setInitializedAsync = async (value: boolean) => {
      await new Promise((res) => {
        res(setInitialized(value));
      });
    };

    const setParametersAsync = async (params: URLSearchParams) => {
      await setParameters(params);
    };

    const params = new URLSearchParams(history.location.search);
    if (
      (params.get('page') ?? '1') !== (page.toString() ?? '1') ||
      (params.get('sort') ?? 'p') !== (sort.toString() ?? 'p') ||
      (params.get('discountTypes') ?? '') !== (selectedDiscounts ?? []).join(',') ||
      (params.get('categories') ?? '') !== (selectedCategories ?? []).join(',')
    ) {
      setInitializedAsync(false);
      setParametersAsync(params);
      setInitializedAsync(true);

      return;
    }

    const promoId = path.includes('/promo') ? path.split('/').slice(-1)[0] : null;
    if (promoId) {
      setTitle('Προσφορές & Κουπόνια');
    } else {
      setTitle('Hot Προσφορές');
    }

    const triggerFetchAsync = async () => {
      await new Promise((res) => {
        res(
          triggerFetch(
            promoId,
            {
              sort,
              discount: selectedDiscounts.join(','),
              category: selectedCategories.join(','),
            },
            page.toString() ?? null,
            path.includes('/promo') ? true : false,
          ),
        );
      });
    };

    const setIsLoadingSavingsAsync = async (value: boolean) => {
      await new Promise((res) => {
        res(setIsLoadingSavings(value));
      });
    };

    history.action === 'PUSH' && !loadingMore && setIsLoadingSavingsAsync(true);
    triggerFetchAsync();
    setTimeout(() => {
      setIsLoadingSavingsAsync(false);
    }, 3000);
  }, [initialized, selectedCategories, selectedDiscounts, sort, page, promo]);

  useEffect(() => {
    if (!resetFilters || history.location.search !== '') return;

    const params = new URLSearchParams('');
    const setParametersAsync = async (params: URLSearchParams) => {
      await setParameters(params);
    };

    const setIsLoadingSavingsAsync = async (value: boolean) => {
      await new Promise((res) => {
        res(setIsLoadingSavings(value));
      });
    };

    const setInitializedAsync = async (value: boolean) => {
      await new Promise((res) => {
        res(setInitialized(value));
      });
    };

    setInitializedAsync(false);
    setIsLoadingSavingsAsync(true);
    setParametersAsync(params);

    setResetFilters(false);
    setInitializedAsync(true);
    contentRef.current && contentRef.current.scrollToTop();
  }, [resetFilters]);

  useEffect(() => {
    if (response && response.data) {
      if (appendData.current) {
        setOffers(offers.concat(response.data));
      } else {
        setOffers(response.data);
      }
    }
    setLoadingMore(false);
    appendData.current = false;
    if (infiniteEvent.current) {
      infiniteEvent.current.complete();
    }
    infiniteEvent.current = null;
  }, [response]);

  useEffect(() => {
    if (status === 'fulfilled') {
      appendData.current = false;
      if (infiniteEvent.current) {
        infiniteEvent.current.complete();
      }
      infiniteEvent.current = null;
    }
  }, [status]);

  useEffect(() => {
    if (errorSavings) {
      const params = { value: true, msg: 'Κάτι πήγε στραβά με τις προσφορές.', severity: 'error' };
      dispatch(setSnackBar(params));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [errorSavings]);

  const triggerFetch = async (
    promo: string | null,
    filters: { sort: string | null; category: string | null; discount: string | null },
    currentCursor: string | null,
    noPromo: boolean | null,
    featured?: boolean | null,
    fillFeatured?: boolean | null,
  ) => {
    const cacheKey = `cursor=${currentCursor ?? '1'};limit=${48}|sort=${filters.sort ?? 'p'};discount=${
      filters.discount ?? ''
    };category=${filters.category ?? ''};noPromo=${noPromo}`;
    if (
      cacheKey === Object.keys(JSON.parse(localStorage.getItem('offers') ?? '{}'))[0] &&
      (history.location.state as any)?.fetchPrevious
    ) {
      const cacheData = await JSON.parse(JSON.parse(localStorage.getItem('offers') ?? '{}')[cacheKey]);
      await new Promise((res) => {
        res(
          cacheData?.data.map((saving: Savings) => {
            const shopImage = new Image();
            shopImage.src = saving.shop.image as string;
            return shopImage;
          }),
        );
      });
      setResponse(cacheData);

      setFetchedFromCache(true);
    } else {
      const data = await trigger({
        limit: 48,
        promo: promo ?? undefined,
        sort: filters.sort ?? undefined,
        nextCursor: currentCursor ?? undefined,
        discount: filters.discount ?? undefined,
        category: filters.category ?? undefined,
        no_promo: noPromo ?? undefined,
      });

      setResponse(data?.data);

      if (
        Object.keys(JSON.parse(localStorage.getItem('offers') ?? '{}'))[0]?.split('|')[1] ===
        decodeURIComponent(cacheKey.split('|')[1])
      ) {
        const shopsWithFav = [
          ...(offers ?? []),
          ...((offers ?? []).findIndex((e: any) => e?.id === (data?.data?.data ?? [])[0]?.id) === -1
            ? data?.data?.data ?? []
            : []),
        ];

        const final = { ...data?.data, data: shopsWithFav };

        if (history.location.pathname === '/offers') {
          if (final.data.length) {
            localStorage.setItem('offers', JSON.stringify({ [cacheKey]: JSON.stringify(final) }));
          }
        }
      } else {
        const shopsWithFav = data?.data?.data ?? [];

        const final = { ...data?.data, data: shopsWithFav };

        if (history.location.pathname === '/offers') {
          if (final.data.length) {
            localStorage.setItem('offers', JSON.stringify({ [cacheKey]: JSON.stringify(final) }));
          }
        }
      }
      history.replace(`${router.routeInfo.pathname}?${params}`, {
        scrollY: (history.location.state as any)?.scrollY,
        fetchPrevious: true,
      });
    }
  };

  const loadData = async (ev?: any) => {
    setLoadingMore(true);
    appendData.current = true;
    const newCursor = response?.nextCursor;
    if (newCursor) {
      setPage(Number(newCursor));
      params.set('page', newCursor);
      history.replace(`${router.routeInfo.pathname}?${params}`);
    }
    if (ev) {
      infiniteEvent.current = ev.target;
    }
  };

  useEffect(() => {
    const setIsLoadingSavingsAsync = async (value: boolean) => {
      await new Promise((res) => {
        res(setIsLoadingSavings(value));
      });
    };
    setIsLoadingSavingsAsync(false);
  }, [fetchedFromCache]);

  const renderDesktop = () => {
    return (
      <Box paddingBottom={16}>
        <OffersCards
          savings={offers ?? undefined}
          loading={isLoadingSavings}
          setLoading={setIsLoadingSavings}
          loadingMore={loadingMore}
          setLoadingMore={setLoadingMore}
          message={setMessage}
          setLogin={setLoginModal}
        />
        {!isFetching && offers.length > 0 && (
          <Pagination
            currentCount={offers ? offers.length : 0}
            total={response ? response.totalCount : 0}
            hasNextPage={!!response?.nextCursor}
            nextPage={response?.nextCursor}
            loadMore={loadData}
          />
        )}
      </Box>
    );
  };

  const renderMobile = () => {
    return (
      <Box>
        <OffersCards
          savings={offers ?? undefined}
          loading={isLoadingSavings}
          setLoading={setIsLoadingSavings}
          loadingMore={loadingMore}
          setLoadingMore={setLoadingMore}
          message={setMessage}
          setLogin={setLoginModal}
        />
        <Box mb={8}>
          {!isLoadingSavings && offers.length > 0 && (
            <Pagination
              currentCount={offers ? offers.length : 0}
              total={response ? response.totalCount : 0}
              hasNextPage={!!response?.nextCursor}
              nextPage={response?.nextCursor}
              loadMore={loadData}
            />
          )}
        </Box>
      </Box>
    );
  };

  return (
    <IonPage>
      <IonContent scrollEvents={true} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        <Layout>
          <>
            <Box>
              <CustomHeader />
              {mdDown && (
                <IonHeader class="ion-no-border">
                  <IonToolbar
                    style={{
                      '--background': '#FFFFFF',
                      '--border-width': 0,
                      paddingTop: '0 + --ion-safe-area-top',
                    }}
                  >
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
                      <IonButtons slot="start">
                        <IonBackButton
                          className="backBtn"
                          text=""
                          style={{
                            '--color': '#313D53',
                            '--padding-start': 0,
                            '--padding-end': 0,
                            '--icon-font-size': '24px',
                            '--border-radius': 0,
                            '--min-height': '24px',
                            '--min-width': '24px',
                            width: '24px',
                            height: '24px',
                            minWidth: '24px',
                            maxHeight: '24px',
                            display: 'inline-flex',
                          }}
                          icon={backArrow}
                        />
                      </IonButtons>
                      <Typography component="h2" variant="body2" color={'#313D53'}>
                        {title}
                      </Typography>
                      <Box minWidth={'24px'}></Box>
                    </Box>
                  </IonToolbar>
                </IonHeader>
              )}
              {location.pathname === '/offers' && (
                <Helmet>
                  <title>Hot Προσφορές | Pigogo - Επιστροφή χρημάτων & κουπόνια</title>
                  <meta
                    name="description"
                    content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
                  />
                </Helmet>
              )}
              {location.pathname.includes('/promo') && (
                <Helmet>
                  <title>
                    {promos?.data.find((obj) => obj.label === path.split('/').slice(-1)[0])?.title} | Pigogo - Επιστροφή
                    χρημάτων & κουπόνια
                  </title>
                  <meta
                    name="description"
                    content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
                  />
                </Helmet>
              )}
            </Box>
            <CustomContainer>
              <Box>
                <PromoBanner />

                {mdUp && (
                  <Box paddingTop={mdDown ? 3 : 8} mb={4}>
                    <Typography variant={'h4'} component="h2" color={'#313D53'}>
                      {title}
                    </Typography>
                  </Box>
                )}

                <FiltersSection
                  offers={true}
                  totalResults={response?.totalCount ?? 0}
                  selectedCategories={selectedCategories}
                  selectedDiscounts={selectedDiscounts}
                  sort={sort}
                  setSelectedCategories={setSelectedCategories}
                  setSelectedDiscounts={setSelectedDiscounts}
                  page={page}
                  setPage={setPage}
                  setSort={setSort}
                  setLoading={setIsLoadingSavings}
                />

                {!(response?.totalCount === 0 && !isLoading) && (
                  <Box>
                    {!mdDown && renderDesktop()}
                    {mdDown && renderMobile()}
                  </Box>
                )}
                {response?.totalCount === 0 && !isLoading && <NoResultsPage />}
              </Box>
            </CustomContainer>
            {!mdDown && <PigogoFooter />}
          </>
        </Layout>
      </IonContent>
      <LoginModal
        message={message}
        isOpen={loginModal}
        setLoginModal={(value: boolean) => {
          setLoginModal(value);
        }}
        dismiss={() => setLoginModal(false)}
        openForgotModal={() => setForgotModal(true)}
        openRegisterModal={() => setRegisterModal(true)}
      />
      <RegisterModal isOpen={registerModal} setOpen={setRegisterModal} setLoginModal={setLoginModal} />
      <ForgotModal isOpen={forgotModal} setOpen={setForgotModal} success={setSuccessForgotModal} />
      <SuccessPasswordModal
        open={successForgotModal}
        title={'Ευχαριστούμε πολύ!'}
        subTitle={'Το link δημιουργίας νέου κωδικού έχει σταλεί στο email σου.'}
        handleClose={setSuccessForgotModal}
      />
    </IonPage>
  );
};

export default Offers;
