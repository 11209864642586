export const typography = {
  //display xLarge
  h1: {
    fontSize: '64px',
    fontWeight: 600,
    lineHeight: '72px',
    letterSpacing: 0,
  },
  xLargeR: {
    fontSize: '64px',
    fontWeight: 400,
    lineHeight: '72px',
  },

  //display large
  h2: {
    fontSize: '40px',
    fontWeight: 600,
    lineHeight: '48px',
    letterSpacing: 0,
  },
  largeR: {
    fontSize: '40px',
    fontWeight: 400,
    lineHeight: '48px',
  },

  //display medium
  h3: {
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '40px',
    letterSpacing: 0,
  },
  mediumR: {
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: '40px',
  },
  mediumRAll: {
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: '40px',
  },
  mediumSB: {
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '1.25',
  },
  mediumTitle: {
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '1.28',
    letterSpacing: 0,
  },
  //display small
  h4: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
    letterSpacing: 0,
  },
  h4All: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
    letterSpacing: 0,
  },
  smallR: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '32px',
  },
  smallB: {
    fontSize: '24px',
    fontWeight: 900,
    lineHeight: '32px',
  },

  //heading
  body1: {
    fontSize: '20px',
    fontWeight: 700,
    letterSpacing: '-0.02em',
    lineHeight: '24px',
  },
  largeXL: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 300,
  },
  largeL: {
    fontSize: '20px',
    lineHeight: '1.4',
    fontWeight: 300,
    letterSpacing: '-0.02em',
  },
  bodyLargeR: {
    fontSize: '20px',
    lineHeight: '1.4',
    fontWeight: 300,
    letterSpacing: '-0.02em',
  },
  bodyLargeRMediumL: {
    fontSize: '20px',
    lineHeight: '1.4',
    fontWeight: 300,
    letterSpacing: '-0.02em',
  },
  largeRegular: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  largeRegularAll: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  largeMedium: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  largeB: {
    fontSize: '20px',
    lineHeight: '1.2',
    fontWeight: 700,
  },
  large900: {
    fontSize: '20px',
    lineHeight: '1.2',
    fontWeight: 900,
  },
  h5: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: 0,
  },

  //body large below

  mediumM: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '26px',
    letterSpacing: 0,
  },
  mediumH6R: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '26px',
    letterSpacing: 0,
  },
  mediumL: {
    fontWeight: 300,
    fontSize: '18px',
    lineHeight: '26px',
    letterSpacing: 0,
  },
  h6: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '26px',
    letterSpacing: 0,
  },

  //body medium below
  body2: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '700',
    letterSpacing: 0,
  },
  body2MediumR: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    letterSpacing: 0,
  },
  body2MediumM: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    letterSpacing: 0,
  },
  body2MediumL: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 300,
    letterSpacing: 0,
  },

  //body small below
  subtitle1: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
  },
  subtitle900: {
    fontWeight: 900,
    fontSize: '14px',
    lineHeight: '20px',
  },
  subtitle1SmallM: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  subtitle1SmallR: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
  subtitle1SmallL: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: 0,
  },
  inputLabel: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '1.71',
  },

  //caption
  captionM: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
  },
  captionSB: {
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: 0,
  },
  caption: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: 0,
  },

  //button
  button: {
    textTransform: undefined,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-2%',
  },
  buttonSmallM: {
    textTransform: undefined,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-2%',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: 0,
  },
};
